import styled, { css } from 'styled-components'
import { FieldWrapper } from '@partials/Field'
import { Heading, H4Sizes, MetaStyles } from '@styles/Typography'
import styledMap from 'styled-map'
import { ButtonStyles } from '@styles/components/Button'
import { tokens, spacing, media } from '@tokens'

const customOption = (type = 'checkbox') => {
  return css`
    fieldset {
      margin: 0 -${spacing(1.5)};
      width: calc(100% + ${spacing(3)});

      &:focus {
        outline: none;
      }

      label {
        ${ButtonStyles};
        background: transparent;
        color: ${tokens.color('link')};
        cursor: pointer;
        margin: 0 ${spacing(1.5)} !important;
      }

      input {
        position: absolute;
        visibility: hidden;
      }

      input:checked + label {
        background-color: ${tokens.color('link')};
        color: ${tokens.brand('light')};
      }
    }
  `
}

export const Form = styled.form`
  display: flex;
  flex-flow: row wrap;
  text-align: left;
  max-width: ${tokens.get('maxWidth.mid')};
  margin: 0 auto;

  button[type='submit'] {
    position: relative;

    svg {
      fill: ${tokens.brand('lightAlt')};
      position: absolute;
      opacity: 0;
      visibility: hidden;
      height: 2rem;
      width:  2rem;
    }
  }
`

export const Field = styled(FieldWrapper)`
  ${styledMap('type', {
    radio: css`
      ${customOption('radio')}
    `,
    checkbox: css`
      ${customOption('checkbox')}
    `,
    default: '',
  })};
  flex-basis: 100%;
  margin-bottom: ${styledMap('type', {
    section: '0',
    default: spacing(6),
  })};
  position: relative;
  will-change: height;

  &.is-closed {
    height: 0;
    margin: 0;
  }

  ${media.greaterThan('m')`
    margin-bottom: ${styledMap('type', {
      section: '0',
      default: spacing(8),
    })};
  `};

  &.half-col {
    flex-basis: calc(50% - ${spacing(1.5)});
    margin-right: ${spacing(3)};

    + .field.half-col {
      margin-right: 0;
    }
  }

  > label {
    ${Heading}
    ${H4Sizes}
    display: inline-block;
    letter-spacing: ${tokens.get('type.letterSpacing.medium')};
    margin-bottom: ${styledMap('type', {
      section: spacing(5),
      default: spacing(3),
    })};
  }
`

export const Error = styled.div`
  ${MetaStyles}
  color: ${tokens.color('error')};
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0.25rem 0 0;
`