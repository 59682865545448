import styled from 'styled-components'
import { tokens, media, spacing } from '@tokens'
import { ListBase, ListItemBase } from '@styles/objects/List'
import { Heading, H3Sizes, LargeTypeSizes } from '@styles/Typography'
export { Component } from '@styles/components/Component'

export const LogoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${spacing(7)};
  grid-row-gap: ${spacing(7)};
  overflow: hidden;

  ${media.greaterThan('s')`
    grid-template-columns: repeat(4, 1fr);
  `}

  .logo-link {
    display: flex;
    flex-flow: fow;
    justify-content: center;
    align-items: center;
  }

  .logo-image {
    display: block;
    margin: auto;
    width: 100%;

    &.image--portrait {
      max-width: 4.5rem;
    }

    &.image--landscape {
      max-width: 7.5rem;
    }
  }
`

