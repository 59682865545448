import React, { Component } from 'react'
import ScrollMagic from 'ScrollMagic'
import 'scrollmagic.gsap'
import { TweenMax } from 'gsap'

class TrophySvg extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.timelines = {}
    this.props = props
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller()
    this.targets = {
      trophy: this.ref.current.querySelector('.trophy'),
      star: this.ref.current.querySelector('.star'),
      shineWrap: this.ref.current.querySelector('.shine-wrap'),
      shine: this.ref.current.querySelector('.shine'),
    }

    this.timelines.scroll = new TimelineMax().staggerFromTo(
      [this.targets.trophy, this.targets.star],
      0.6,
      {
        scale: 0,
        transformOrigin: '50% 50%',
      },
      {
        scale: 1,
        ease: Back.easeOut.config(1.7),
      },
      0.3
    )

    new TweenMax.to(this.targets.shine, 0, {
      transformOrigin: '50% 50%',
      scale: 0,
    })

    this.timelines.loop = new TimelineMax({
      paused: true,
      repeat: -1,
    })
      .to(this.targets.shine, 0, {
        transformOrigin: '50% 50%',
        scale: 0,
        autoAlpha: 1,
        delay: 2,
      })
      .to(this.targets.shine, 0.25, {
        scale: 1,
        autoAlpha: 1,
      })
      .to(this.targets.shine, 0.125, {
        scale: 1.2,
        autoAlpha: 0,
      })
      .to(this.targets.shine, 0, {
        scale: 0,
        autoAlpha: 0,
      })

    this.scenes = [
      new ScrollMagic.Scene({
        triggerElement: this.ref.current,
        triggerHook: 0.85,
        duration: 0,
      })
        .setTween(this.timelines.scroll)
        .on('start end', e => {
          const direction = e.target.controller().info('scrollDirection')
          // TODO Add helper function
          if (direction === 'FORWARD' || direction === 'PAUSED') {
            this.timelines.loop.play()
          } else {
            this.timelines.loop.pause()
          }
        })
        .addTo(this.controller),
    ]
  }

  componentWillUnmount() {
    Object.keys(this.timelines).forEach(key => {
      this.timelines[key].kill()
    })
    this.scenes.forEach(scene => {
      scene.destroy(true)
    })
    this.scenes = null
  }

  render() {
    return (
      <svg
        className={this.className}
        ref={this.ref}
        {...this.props}
        viewBox="0 0 101 114"
        style={{ overflow: 'visible' }}
      >
        <path
          className="star"
          d="M63.3 20.9c-.2-.6-.8-1.1-1.4-1.1h-7.5l-2.3-7.3c-.2-.6-.8-1-1.4-1s-1.2.4-1.4 1L47 19.8h-7.5c-.7 0-1.2.4-1.4 1.1-.2.6 0 1.3.5 1.7l6.1 4.5-2.3 7.3c-.2.6 0 1.3.5 1.7.5.4 1.3.4 1.8 0l6.1-4.5 6.1 4.5c.3.2.6.3.9.3.3 0 .6-.1.9-.3.5-.4.7-1.1.5-1.7l-2.3-7.3 6.1-4.5c.2-.4.5-1.1.3-1.7z"
        />
        <g className="trophy">
          <path d="M90.1 108.4H9.9c-1.1.3-1.9 1.3-1.9 2.4 0 1.1.8 2.1 1.9 2.4h80.2c1.1-.3 1.9-1.3 1.9-2.4 0-1.1-.8-2.1-1.9-2.4z" />
          <path d="M100.9 9.2c-.3-.7-1-1.2-1.7-1.2H85.3V1.9c0-1-.8-1.9-1.9-1.9H17.5c-1 0-1.9.8-1.9 1.9V8H1.9C1.1 8 .4 8.5.1 9.2c-.1.5-.1 1-.1 1.6 0 3.4.7 10.9 1 12.8C2.8 35.2 10.6 43 23 45.3c1.9.3 6.7 1 9.1 1.2l3 4c1.6 2.1 3.1 4.1 4.7 6.2.3.4.4.8.5 1.2.1 2.2 0 4.4-.1 6.7v1.6H23.5c-.5 0-1 .2-1.3.6-.4.4-.5.8-.5 1.3v36c0 1 .9 1.8 1.9 1.8h54c1 0 1.9-.8 1.9-1.9V68c0-1-.8-1.9-1.9-1.9H60.9c0-.2 0-.5-.1-.7v-.9c-.1-2.2-.1-4.4-.1-6.7.1-.4.2-.9.5-1.2 1.5-2.1 3.1-4.2 4.7-6.3l3-3.9c2.5-.3 7.3-.9 9.1-1.2 12.4-2.2 20.2-10 22-21.7.3-1.9 1-9.4 1-12.8 0-.5 0-1-.1-1.5zM23.7 41.6C13 39.6 6.2 33 4.7 22.9c-.5-2.9-.8-8.6-1-11.2h12.2c1.7 13.2 8.9 24.7 13.1 30.5l.1.1c-2.2-.2-4.4-.5-5.4-.7zm20.2 24.6c0-.2.1-.4.1-.6v-.9c0-1.7.1-3.4.1-5 2 1.1 4.2 1.7 6.5 1.8 2.3-.1 4.5-.7 6.5-1.8 0 1.7 0 3.4.1 5v.9c0 .2 0 .4.1.6H43.9zM69 77.9v16.2c0 .5-.2 1-.5 1.3-.4.4-.8.6-1.3.6H32.9c-.5 0-1-.2-1.3-.6-.4-.4-.6-.8-.5-1.3V77.9c0-1 .8-1.9 1.9-1.9h34.2c.5 0 1 .2 1.3.6.3.3.5.8.5 1.3zm-2.6-34.3l-.1.1-3.4 4.5c-1.5 2-3.1 4.1-4.6 6.1-.5.4-4 3.4-7.8 3.4s-7.2-2.9-7.8-3.4c-1.5-2.1-3-4.1-4.6-6.1l-3.4-4.5-.1-.1c-.9-1.2-1.8-2.3-2.6-3.6-4.2-5.7-11.4-17.3-12.6-30.2v-6h62.2v6C80.4 22.7 73.2 34.3 69 40c-.8 1.2-1.7 2.4-2.6 3.6zm29.9-20.7C94.8 33 88 39.6 77.3 41.6c-1 .2-3.2.5-5.4.8l.1-.1c4.2-5.8 11.4-17.4 13.1-30.5h12.1c-.1 2.8-.4 8.3-.9 11.1z" />
        </g>
        <g className="shine-wrap">
          <g className="shine">
            <path d="M72.2 11.3c-.7 0-1.2-.6-1.2-1.3V6.1c0-.7.5-1.3 1.2-1.3s1.2.6 1.2 1.3V10c0 .4-.1.7-.3.9-.2.2-.5.4-.9.4z" />
            <path d="M72.2 20.8c-.7 0-1.2-.6-1.2-1.3v-3.9c0-.7.5-1.3 1.2-1.3s1.2.6 1.2 1.3v3.9c0 .7-.5 1.3-1.2 1.3zM78.6 13.6h-3.9c-.7 0-1.3-.5-1.3-1.2s.6-1.2 1.3-1.2h3.9c.7 0 1.3.5 1.3 1.2s-.6 1.2-1.3 1.2zM69.1 13.6h-3.9c-.7 0-1.3-.5-1.3-1.2s.6-1.2 1.3-1.2h3.9c.7 0 1.3.5 1.3 1.2.1.7-.5 1.2-1.3 1.2z" />
          </g>
        </g>
      </svg>
    )
  }
}

export default TrophySvg
