import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { ListBase, ListItemBase } from '@styles/objects/List'
import { Heading, H3Sizes, LargeTypeSizes } from '@styles/Typography'
import { Component } from '@styles/components/Component'

export const ListBlockComponent = styled(Component)`
  ${styledMap('layout', {
    'fancy': css`
      overflow: visible;
    `,
    'default': css`
      overflow: hidden;
    `
  })}

  ${media.greaterThan('l')`
    .home & {
      ${styledMap('layout', {

        'columns': css`
        ${box({
          spacing: 'vertical',
          multiplier: 1.25,
          type: 'padding',
        })}

        .section__label {
          ${box({
              spacing: 'top',
              multiplier: 1.25,
              type: 'padding',
            })}
            display: flex;
        }
        `,
        'default': '',
      })};
    }
  `}

  > .inner {
    > .content {
      margin: 0;
    }
  }

  .bolt-container {
    flex: 0 0 ${spacing(5)};
    margin-right: ${spacing(2)};

    .bolt {
      width: 100%;
      height: auto;
      margin: 0;
    }
  }
`

export const ListBasic = styled.ul`
  ${ListBase}

  .icon {
    height: auto;
    line-height: 0;
    margin-right: ${spacing(4)};
    width: 2rem;
  }
`

export const ListFancy = styled.ul`
  align-items: flex-start;

  .list__item {
    align-items: flex-start;
    margin: 0;
    border: none;
    margin-top: ${spacing(6)};

    &:first-child {
      margin-top: 0;
    }

    ${media.lessThan('s')`
      display: block;
    `}

    ${media.greaterThan('s')`
      margin-top: ${spacing(8)};
    `}

    > .inner {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0;
    }

    .icon {
      height: auto;
      line-height: 0;
      margin-bottom: ${spacing(3)};

      ${media.greaterThan('s')`
        flex: 0 0 5rem;
        margin-right: ${spacing(4)};
      `}

      ${media.greaterThan('l')`
        flex: 0 0 7rem;
        margin-right: ${spacing(6)};
      `}

      ${media.lessThan('s')`
        width: 5rem;
      `}
    }
  }

  .icon + .inner .list__item__label {
    margin-top: -0.3em;
  }

  .list__item__label {
    ${Heading}
    ${H3Sizes}
    margin-bottom: ${spacing(2)};
  }

  /* initial gsap animation state */
  .list__item__label,
  .list__item__content {
    opacity: 0;
    transform: translate3d(0, 50, 0);
  }
`

export const ListInline = styled.ul`
  flex: 0 1 35.625rem;
  margin: 0;
  margin-top: ${spacing(3)};

  ${media.greaterThan('l')`
    padding-top: ${spacing(12)};
    margin-top: ${spacing(-5)};
  `}

  ${media.greaterThan('xl')`
    margin-top: ${spacing(-3)};
  `}
`

export const ListColumns = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${spacing(2)};
  margin-top: ${spacing(4)};
  margin-left: ${spacing(4)};

  ${media.greaterThan('s')`
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: ${spacing(5)};
    grid-row-gap: ${spacing(3)};
    margin-left: 0;
  `}

  ${media.greaterThan('m')`
    grid-template-columns: repeat(3,1fr);
  `}

  ${media.greaterThan('l')`
    margin-top: ${spacing(5)};
  `}

  ${media.greaterThan('1330px')`
    grid-template-columns: repeat(4,1fr);
  `}

  .list__item {
    ${LargeTypeSizes}
    border: none;

    .inner {
      padding: 0;
    }
  }
`

export const ListInner = styled.div`
  margin-right: auto;
  margin-left: auto;

  .list-block--basic > & {
    max-width: ${tokens.get('maxWidth.mid')};
  }

  .list-block--columns > & {
    max-width: ${tokens.get('maxWidth.vastSmall')};

    ${media.greaterThan('xl')`
      max-width: ${tokens.get('maxWidth.vast')};
    `}

    .content {
      margin-left: 0;
    }

    .intro {
      margin-bottom: 0;
    }
  }

  .list-block--inline > & {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: ${tokens.get('maxWidth.narrow')};

    ${media.greaterThan('l')`
      flex-flow: row;
      max-width: ${tokens.get('maxWidth.wide')};
    `}

    .content {
      ${media.greaterThan('l')`
        flex: 0 1 30rem;
        margin-right: ${spacing(8)};
      `}

      p {
        ${LargeTypeSizes}
      }
    }

    .intro {
      margin-bottom: 0;
    }

    .links {
      ${media.lessThan('l')`
        display: none;
      `}
    }

    .mobile-links {
      display: block;

      ${media.greaterThan('l')`
        display: none;
      `}
    }
  }

  .list-block--fancy > & {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    ${media.between('s', '1024px')`
      justify-content: center;
    `}

    .intro {
      margin-bottom: 0;
    }

    .content {
      flex: 0 1 22.5rem;
      margin-left: 0;
      margin-bottom: ${spacing(5)};

      ${media.between('s', '1024px')`
        flex: 1 1 auto;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: ${spacing(8)};
        max-width: ${tokens.get('maxWidth.narrow')};
      `}

      ${media.greaterThan('1024px')`
        position: sticky;
        top: 12.5rem;
        margin-bottom: 0;
      `};

      ${media.greaterThan('l')`
        margin-right: ${spacing(10)};
      `}
    }

    .list {
      width: 100%;
      margin-right: 0;
      margin-left: auto;
      padding-left: ${spacing(5)};

      ${media.between('s', '1024px')`
        max-width: ${tokens.get('maxWidth.narrow')};
        margin-right: auto
      `}

      ${media.greaterThan('1024px')`
        flex: 1 1 10%;
        min-width: 29.5rem;
        max-width: 35rem;
        padding-top: ${spacing(13.5)};
      `}
    }
  }

  .bolt {
    height: ${spacing(5)};
    margin-bottom: ${spacing(3)};

    ${media.greaterThan('s')`
      margin-bottom: ${spacing(5)};
    `}

    ${media.greaterThan('1024px')`
      margin-bottom: ${spacing(8)};
    `}

    ${media.lessThan('s')`
      width: 10.5rem;
    `}
  }
`

export const ListItem = styled.li`
  ${ListItemBase}
  align-items: center;
  justify-content: flex-start;

  ${ListColumns} & {
    padding: 0;
  }
`