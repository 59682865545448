import { useStaticQuery, graphql } from 'gatsby'

// Fetch site navigation and export for use
export const useTeam = () => {
  const { allWordpressWpTeamMember } = useStaticQuery(
    graphql`
      query TeamMembers {
        allWordpressWpTeamMember(sort: { fields: menu_order }) {
          edges {
            node {
              wordpress_id
              title
              acf {
                title
                headshot {
                  alt_text
                  localFile {
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 331) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allWordpressWpTeamMember.edges
}

export default useTeam
