import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Link from '@partials/Link'
import useSiteNav from '@hooks/use-site-nav'
import * as S from '@styles/components/NavBar'
import hoverintent from 'hoverintent'

class NavBarItem extends Component {
  static propTypes = {
    title: PropTypes.string,
    object_id: PropTypes.number,
    url: PropTypes.string,
    wordpress_children: PropTypes.array,
  }

  constructor(props) {
    super(props)

    this.ref = React.createRef()
    const expanded = false

    this.state = { expanded }
  }

  componentDidMount() {
    const item = this.ref.current

    // Use hoverintent for to set subnav state
    if (this.props.wordpress_children) {
      this.hover = hoverintent(
        item,
        () => {
          this.setState({ expanded: true })
        },
        () => {
          this.setState({ expanded: false })
        }
      ).options({
        timeout: 100,
      })
    }
  }

  componentWillUnmount() {
    if (this.props.wordpress_children) {
      this.hover.remove()
    }
  }

  // Accessibility - Open menu on focus
  onFocus = () => {
    this.setState({ expanded: true })
  }

  // Accessibility - close menu on blur
  onBlur = () => {
    this.setState({ expanded: false })
  }

  // Accessibility - close menu on 'esc' if menu is open
  onKeyDown = () => {
    if (event.keyCode === 27 && this.state.expanded) {
      this.setState({ expanded: false })
    }
  }

  render() {
    // Object destructuring is fucking beautiful
    const {
      props: {
        title,
        object_id,
        url,
        isButton,
        wordpress_children,
        className,
        invertColors,
      },
      state: { expanded },
    } = this
    const linkClassName = `${
      isButton ? 'navbar-link--button' : 'navbar-link'
    } ${className || ''}`

    return (
      <S.NavBarItem
        className={`navbar-item navbar-item-${object_id} ${
          wordpress_children ? 'has-children' : ''
        }`}
        ref={this.ref}
        expanded={expanded}
        invertColors={invertColors}
        aria-expanded={expanded}
        aria-haspopup={wordpress_children ? 'true' : 'false'}
        onKeyDown={this.onKeyDown}
        onFocus={wordpress_children ? this.onFocus : null}
        onBlur={wordpress_children ? this.onBlur : null}
      >
        <Link
          className={linkClassName}
          activeClassName="is-active"
          partiallyActive={url !== '/'}
          to={url}
          style={isButton ? 'button' : ''}
        >
          {title}
        </Link>

        {wordpress_children && (
          <SubNav children={wordpress_children} expanded={expanded} />
        )}
      </S.NavBarItem>
    )
  }
}

class SubNav extends Component {
  static propTypes = {
    children: PropTypes.array.isRequired,
    expanded: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.ref = React.createRef()
    this.timeline = new TimelineLite({
      paused: true,
    })

    const open = false
    this.state = { open }
  }

  componentDidMount() {
    this.timeline.staggerFromTo(
      this.ref.current.children,
      0.5,
      {
        y: 40,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
      },
      0.1
    )
  }

  componentDidUpdate() {
    const { expanded } = this.props

    if (expanded && !this.state.open) {
      this.timeline.play()
      this.setState({ open: true })
    } else if (!expanded && this.state.open) {
      this.timeline.reverse()
      this.setState({ open: false })
    }
  }

  render() {
    const { children, expanded } = this.props
    return (
      <S.SubNav expanded={expanded} aria-expanded={expanded} ref={this.ref}>
        {children.map(link => (
          <NavBarItem {...link} key={link.object_id} />
        ))}
      </S.SubNav>
    )
  }
}

const NavBar = ({ className, invertColors }) => {
  const links = useSiteNav()

  return (
    <S.NavBar className={className}>
      {links.map(link => (
        <NavBarItem
          {...link}
          key={link.object_id}
          invertColors={invertColors}
          // Turn contact link into a button
          isButton={link.wordpress_id === 719}
        />
      ))}
    </S.NavBar>
  )
}

export default NavBar
