import styled from 'styled-components'
import { tokens, media, spacing, box } from '@tokens'
import { getBoxSpacingForProperty } from '@tokens/spacing'
import { ITCBold } from '@styles/Typography'
import Component from '@styles/components/Component'

export const Banner = styled(Component)`
  position: relative;
  overflow: hidden;

  .container:not(.home) & {
    ${box({
      spacing: 'vertical',
      multiplier: 0.5,
      type: 'padding',
    })}
  }

  ${media.lessThan('s')`
    padding-left: ${spacing(6)};
    padding-right: ${spacing(6)};
  `}

  .background-text {
    ${ITCBold};
    display: block;
    color: ${tokens.brand('lightAlt')};
    font-size: 8.75rem;
    line-height: 0.75;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: -0.4em;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    width: fit-content;
    z-index: ${tokens.z('base')};

    ${media.greaterThan('s')`
      font-size: 15rem;
    `}

    ${media.greaterThan('m')`
      font-size: 18.75rem;
    `}
  }

  .bolt-container {
    opacity: 0.03;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    ${getBoxSpacingForProperty('x', {
      property: 'right',
      multiplier: -1,
    })};
    ${getBoxSpacingForProperty('x', {
      property: 'left',
      multiplier: -1,
    })};

    .background-color-standard &,
    .background-color-light & {
      fill: ${tokens.brand('dark')};
    }
  }

  &.has-parallax .gatsby-image-wrapper {
    /* Override scrollmagic default */
    position: absolute !important;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  picture,
  img {
    width: 100%;
  }

  .inner {
    position: relative;
    z-index: ${tokens.z('content')};

    > .content {
      display: block;
      text-align: center;
      max-width: ${tokens.get('maxWidth.tiny')};

      ${media.greaterThan('m')`
        max-width: 52rem;
      `}
    }
  }

  .heading {
    margin-bottom: ${spacing(3)};

    ${media.greaterThan('s')`
      margin-bottom: ${spacing(5)};
    `}
  }

  &.has-bolt {
    .inner {
      padding-top: 3.5%;
      padding-bottom: 3.5%;
      max-width: none;

      ${media.greaterThan('xl')`
        padding-top: ${spacing(9)};
        padding-bottom: ${spacing(9)};
      `}
    }

    .heading {
      margin-bottom: ${spacing(3)};
    }

    .links {
      margin-top: ${spacing(3)};
    }
  }
`
