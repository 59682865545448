import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import Component from '@styles/components/Component'
import { LargeTypeSizes } from '@styles/Typography'
import { tokens, media, spacing } from '@tokens'

const cardMobileBreak = '675px';

export const CardsComponent = styled(Component)`
  overflow: hidden;

  > .inner > .content {
    ${LargeTypeSizes}
  }

  .intro {
    text-align: center;
  }

  .bolt-container {
    max-width: 14.25rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${spacing(5)};
  }

  &.background-color-dark .bolt {
    fill: ${tokens.brand('light')};
  }
`

export const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${spacing(5)};
  grid-row-gap: ${spacing(5)};
  max-width: ${tokens.get('maxWidth.vastSmall')};
  margin-left: auto;
  margin-right: auto;

  ${media.between(cardMobileBreak, 'l')`
    grid-template-columns: 1fr;
  `}

  ${media.greaterThan('l')`
    grid-template-columns: repeat(3,1fr);
  `}

  ${media.greaterThan('xl')`
    grid-column-gap: ${spacing(10)};
    grid-row-gap: ${spacing(10)};
    max-width: ${tokens.get('maxWidth.vast')};
  `}

  .inner {
    text-align: center;
  }
`

export const Card = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: ${spacing(4)};
  background-color: ${tokens.brand('lightAlt')};
  color: ${tokens.color('text')};
  text-align: center;

  .background-color-dark & {
    background-color: ${tokens.brand('light')};
  }

  ${media.lessThan('l')`
    margin-left: auto;
    margin-right: auto;
  `}

  ${media.between(cardMobileBreak, 'l')`
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding: ${spacing(6)};

    max-width: ${styledMap('hasIcon', {
      true: '37.5rem',
      default: '30rem',
    })};
  `}

  ${media.greaterThan('1400px')`
    padding: ${spacing(6)};
  `}

  ${media.greaterThan('xl')`
    padding: ${spacing(8)};
  `}

  > .content {
    ${media.between(cardMobileBreak, 'l')`
      flex: 0 1 22rem;
      text-align: left;
    `}

    ${styledMap('hasIcon', {
      true: css`
        ${media.between(cardMobileBreak, 'l')`
            margin-left: ${spacing(6)};
            padding-right: ${spacing()};
        `}
      `,
      default: css`
        margin-left: auto;
        margin-right: auto;
      `,
    })};
  }

  .icon {
    display: block;
    height: 9.375rem;
    margin-bottom: ${spacing(3)};
    line-height: 0;

    ${media.between(cardMobileBreak, 'l')`
      flex: 0 0 11rem;
      width: 11rem;
      margin-bottom: 0;
    `}

    ${media.greaterThan('l')`
      margin-bottom: ${spacing(6)};
    `}

    img,
    svg {
      height: 100%;
    }

    .car-computer-svg {
      padding-right: 11.35%;

      ${media.between(cardMobileBreak, 'l')`
        flex: 0 0 11rem;
        width: 11rem;
        margin-bottom: 0;
        margin-left: -11.35%;
        padding-right: 0;
      `}
    }
  }

  .card__heading {
    margin-bottom: ${spacing(2)};
  }

  .links {
    ${media.between(cardMobileBreak, 'l')`
      margin-top: 1.5rem;
    `}
  }
`
