import React, { Component } from 'react'
import Range from 'rc-slider/lib/Range'
import 'rc-slider/assets/index.css'
import * as S from '@styles/components/SliderField'
import { affixString, concatRange } from '@utils/strings'

// TODO: Store min and max value on change for rule match reference
// Maybe store as value array and format on submit?
class SliderField extends Component {
  constructor(props) {
    super(props)

    this.ref = React.createRef()

    this.state = {
      min: props.min,
      max: props.max,
      changed: false,
    }
  }

  componentDidMount() {
    const { name } = this.props

    this.handles = this.ref.current.querySelectorAll('.rc-slider-handle')
    this.field = this.ref.current.querySelector(`input[name='${name}']`)

    this.handles.forEach((handle, index) => {
      this.createLabel(handle, index)
    })
  }

  componentDidUpdate() {
    const { min, max } = this.state

    if (this.field && this.state.changed) {
      const changed = false
      this.setState({ changed })

      this.props.form.setFieldValue(
        this.props.name,
        this.returnValues(min, max)
      )

      const e = new Event('input', { bubbles: true })
      this.field.dispatchEvent(e)
      this.props.onChange(e)
    }
  }

  createLabel(handle, index) {
    const { min, max } = this.props
    const initialValue = index === 0 ? min : max
    const formattedValue = this.formatValue(initialValue)
    const innerEl = document.createElement('span')

    innerEl.setAttribute('class', 'value')
    innerEl.appendChild(document.createTextNode(formattedValue))
    handle.appendChild(innerEl)
  }

  formatValue(value, affixes = 'all') {
    const { valuePrefix, valueSuffix, valueSuffixSingular } = this.props

    const suffix =
      value === 1 && valueSuffixSingular ? valueSuffixSingular : valueSuffix

    return affixString(value, {
      prefix: affixes !== 'suffix' ? valuePrefix : '',
      suffix: affixes !== 'prefix' ? suffix : '',
    })
  }

  updateValues([min, max]) {
    const changed = true
    this.setState({ min, max, changed })

    this.handles.forEach((handle, index) => {
      const value = index === 0 ? min : max
      const formattedValue = this.formatValue(value)
      handle.querySelector('span').textContent = formattedValue
    })
  }

  returnValues() {
    const { min, max } = this.state
    return concatRange(
      this.formatValue(min, 'prefix'),
      this.formatValue(max, 'suffix')
    )
  }

  render() {
    const { name, onBlur, onChange, id, min, max, step } = this.props
    return (
      <S.SliderField ref={this.ref}>
        <Range
          onChange={this.updateValues.bind(this)}
          value={[this.state.min, this.state.max]}
          min={min}
          max={max}
          step={step}
        />
        <input
          type="hidden"
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={this.returnValues()}
        />
      </S.SliderField>
    )
  }
}

export default SliderField
