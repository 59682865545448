import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { buildAnchor } from '@utils/strings'
import * as S from '@styles/components/Section'

const SectionLabel = ({ label, ...props }) => {
  const anchor = buildAnchor(label)
  return (
    <S.Section id={`${anchor}`} {...props}>
      <S.Label className="section__label">{label}</S.Label>
    </S.Section>
  )
}

SectionLabel.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  bg: PropTypes.string,
  bgFill: PropTypes.string,
}

export default SectionLabel
