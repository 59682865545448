import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing } from '@tokens'
import Component from '@styles/components/Component'

// Styles are purposefully very specific to the amount of images.
// There is a min of 1 and a max of 3 in the CMS.
// TODO Revisit to make more flexible.
const ImageGrid1 = css`
  ${styledMap('contentAlignment', {
    'center': css `

    `,
    'default': css`
      ${media.greaterThan('m')`
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: stretch;
      `}
    `,
  })};

  > .inner {
    max-width: none;
    margin: 0;
    flex: 0 1 50%;

    ${styledMap('contentAlignment', {
      'right': css`
        margin-bottom: ${spacing(4)};

        ${media.greaterThan('m')`
          order: 2;
          margin-left: ${spacing(4)};
          margin-bottom: 0;
        `}
      `,
    })};

    > .content {
      ${styledMap('contentAlignment', {
        'center': css`
          max-width: ${tokens.get('maxWidth.narrow')};
          margin-bottom: ${spacing(8)};
        `,
        'default': css`

          ${media.lessThan('m')`
            order: 2;
            margin-left: auto;
            margin-right: auto;
          `}

          ${media.greaterThan('m')`
            max-width: ${tokens.get('maxWidth.tiny')};
            order: 2;
            margin-left: ${spacing(4)};
            margin-bottom: 0;
          `}
        `
      })};
    }
  }

  .images {
    flex: 0 1 50%;
    max-width: ${tokens.get('maxWidth.base')};

    ${styledMap('contentAlignment', {
      'right': css`
        ${media.greaterThan('m')`
          margin-right: 0;
        `}
      `,
      'left': css`
        ${media.greaterThan('m')`
          margin-left: 0;
        `}
      `,
      'center': css`
        max-width: ${tokens.get('maxWidth.vast')};
      `,
    })};
  }
`

const ImageGrid2 = css`
  .images {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 4rem 6rem auto;

    ${media.greaterThan('l')`
      grid-template-rows: 12rem 6rem auto;
    `}

    .image:first-child {
      grid-column: 7 / 13;
      grid-row: 1 / 4;

      ${media.greaterThan('l')`
        grid-column: 8 / 13;
      `}
    }

    .image:nth-child(2) {
      grid-column: 3 / 8;
      grid-row: 2 / 4;

      ${media.greaterThan('l')`
        grid-column: 4 / 9;
      `}
    }
  }
`

const ImageGrid3 = css`
  .images {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 4rem 2rem auto;

    ${media.greaterThan('l')`
      grid-template-rows: 12rem 6rem auto;
    `}

    .image:first-child {
      grid-column: 1 / 13;
      grid-row: 2 / 3;
    }

    .image:nth-child(2) {
      grid-column: 5 / 9;
      grid-row: 3 / 4;
    }

    .image:nth-child(3) {
      grid-column: 8 / 12;
      grid-row: 1 / 4;
    }
  }
`

export const TextWithImages = styled(Component)`
  position: relative;
  overflow: hidden;

  .images {
    max-width: ${tokens.get('maxWidth.vast')}

    ${media.greaterThan('l')`
      margin-left: auto;
      margin-right: auto;
    `}
  }

  ${styledMap('imageCount', {
    1: ImageGrid1,
    2: ImageGrid2,
    3: ImageGrid3,
  })};

  > .inner > .content {
    ${styledMap('imageCount', {
      1: css`
      `,
      'default': css`
        margin-bottom: ${spacing(3)};

        ${media.greaterThan('m')`
          max-width: ${tokens.get('maxWidth.slim')};
        `}

        ${media.greaterThan('l')`
          margin-bottom: -10.5%;
        `}
      `,
    })};
  }

  .gatsby-image-wrapper {
    box-shadow: 0 1.875rem 5rem rgba(0, 0, 0, 0.1);
  }
`
