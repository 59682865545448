import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Image from '@partials/Image'
import ComponentContent from '@partials/ComponentContent'
import { getComponentClasses } from '@utils/components'
import * as S from '@styles/components/ImageBlocks'
import ScrollMagic from 'ScrollMagic'
import 'scrollmagic.gsap'
import SectionLabel from '@partials/SectionLabel'

class ImageBlocksComponent extends Component {
  constructor(props) {
    super(props)
    const { component, className } = props
    this.props = props
    this.timelines = {}
    this.classes = getComponentClasses(component, ['image-blocks', className])
    this.ref = React.createRef()
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller()
    this.targets = {
      imageTall: this.ref.current.querySelector('.image-tall'),
      mediaWide: this.ref.current.querySelector('.media-container'),
    }

    this.timelines = {
      scrollImageTall: new TimelineMax().fromTo(
        this.targets.imageTall.querySelector('.gatsby-image-wrapper'),
        1,
        {
          y: 50,
          autoAlpha: 0,
        },
        {
          y: 0,
          autoAlpha: 1,
          ease: Power0.easeNone,
        }
      ),
      scrollMediaWide: new TimelineMax().fromTo(
        this.targets.mediaWide.querySelector('.media-container__inner'),
        1,
        {
          y: 50,
          autoAlpha: 0,
        },
        {
          y: 0,
          autoAlpha: 1,
          ease: Power0.easeNone,
        }
      ),
    }

    this.scenes = [
      new ScrollMagic.Scene({
        triggerElement: this.targets.imageTall,
        triggerHook: 0.85,
        duration: '25%',
      })
        .setTween(this.timelines.scrollImageTall)
        .addTo(this.controller),
      new ScrollMagic.Scene({
        triggerElement: this.targets.mediaWide,
        triggerHook: 0.85,
        duration: '25%',
      })
        .setTween(this.timelines.scrollMediaWide)
        .addTo(this.controller),
    ]

    if (this.props.component.has_video) {
      this.targets.video = this.ref.current.querySelector('video')
      this.scenes.push(
        new ScrollMagic.Scene({
          triggerElement: this.ref.current,
          triggerHook: 1.5,
          duration: 0,
          reverse: false,
        })
          .on('enter', event => {
            this.targets.video.poster = this.targets.video.dataset.poster
            this.targets.video.querySelectorAll('source').forEach(source => {
              source.src = source.dataset.src
            })

            this.targets.video.load()
            this.targets.video.addEventListener('loadeddata', () => {
              this.targets.video.play()
            })
          })
          .addTo(this.controller)
      )
    }
  }

  componentWillUnmount() {
    Object.keys(this.timelines).forEach(key => {
      this.timelines[key].kill()
    })
    this.scenes.forEach(scene => {
      scene.destroy(true)
    })
    this.scenes = null
  }

  render() {
    const {
      image_tall,
      image_wide,
      text_content,
      links,
      background_color,
      background_color_fill,
      label,
      videos,
      has_video,
    } = this.props.component
    const poster = image_wide ? image_wide.localFile.publicURL : ''

    return (
      <S.ImageBlocksComponent
        className={this.classes}
        bg={background_color}
        bgFill={background_color_fill}
        ref={this.ref}
      >
        {label && (
          <SectionLabel
            label={label}
            bg={background_color}
            bgFill={background_color_fill}
          />
        )}
        <div className="inner">
          <div className="column">
            <Image
              source={image_tall}
              aspectRatio={5 / 6}
              className="image-tall"
            />
          </div>
          <div className="column">
            <div className="content">
              <ComponentContent {...text_content} links={links} />
            </div>
            <div className="media-container">
              <div className="media-container__inner">
                {has_video && videos ? (
                  <video muted playsInline autoPlay loop data-poster={poster}>
                    {videos.map(video => {
                      const {
                        localFile: { publicURL: source },
                        media_details: { fileformat: fileFormat },
                      } = video
                      return (
                        <source
                          key={source}
                          data-src={source}
                          type={`video/${fileFormat}`}
                        />
                      )
                    })}
                  </video>
                ) : (
                  <Image
                    source={image_wide}
                    aspectRatio={5 / 3}
                    className="image-wide"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </S.ImageBlocksComponent>
    )
  }
}

ImageBlocksComponent.propTypes = {
  component: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment ImageBlocksComponentFragment on WordPressAcf_image_blocks {
    text_content {
      ...ComponentContentFragment
    }
    has_video
    label
    image_tall {
      alt_text
      localFile {
        publicURL
        childImageSharp {
          # TODO: Alter sizing
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    image_wide {
      alt_text
      localFile {
        publicURL
        childImageSharp {
          # TODO: Alter sizing
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    videos {
      localFile {
        publicURL
      }
      media_details {
        filesize
        fileformat
      }
    }
    links {
      ...LinkFragment
    }
    background_color
    background_color_fill
  }
`

export default ImageBlocksComponent
