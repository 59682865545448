import { css } from 'styled-components'
import { tokens, spacing } from '@tokens'

export const ListBase = css`
  list-style: none;
  margin-left: 0;

  > li {
    margin-left: 0;
  }
`

export const ListInlineFlex = css`
  display: inline-flex;
  margin-left: 0;
  list-style: none;
`

export const ListItemBase = css`
  border-bottom: ${tokens.color('border')} 2px solid;
  display: flex;
  justify-content: space-between;
  padding: 0 ${spacing(1.5)};

  &:last-child {
    border-bottom: 0;
  }

  .icon {
    height: ${spacing(14)};
    width: ${spacing(14)};
    margin-right: ${spacing(2)};
  }

  .inner {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    padding: ${spacing(3)} 0;
  }
`
