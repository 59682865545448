import React from 'react'
import PropTypes from 'prop-types'
import { Link, formatLinkField } from '@partials/Link'
import * as S from '@styles/components/Links'

const Links = ({ links, isVisible = true, className = '' }) => {
  const classes = `links ${className}`
  return (
    <S.Links className={classes}>
      {links.map((link, index) => {
        return (
          <Link key={index} isVisible={isVisible} {...formatLinkField(link)}>
            {link.link_text}
          </Link>
        )
      })}
    </S.Links>
  )
}

Links.propTypes = {
  list: PropTypes.array,
}

export default Links
