import styled from 'styled-components'
import Component from '@styles/components/Component'
import { tokens, spacing, media } from '@tokens'

export const ImageBlocksComponent = styled(Component)`
  > .inner {
    max-width: ${tokens.get('maxWidth.vastSmall')};
    margin-left: auto;
    margin-right: auto;

    .content {
      max-width: ${tokens.get('maxWidth.tiny')};
      margin-left: 0;
    }

    ${media.greaterThan('700px')`
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: flex-start;
    `}

    ${media.greaterThan('xl')`
      max-width: ${tokens.get('maxWidth.vast')};
    `}
  }

  .column {
    flex: 1 1 auto;

    &:first-child {
      flex: 0 0 40%;
      margin-right: ${spacing(5)};

      ${media.greaterThan('1400px')`
        flex: 0 0 31.75rem;
        margin-right: ${spacing(8)};
      `}
    }
  }

  .image {
    img {
      border-radius: ${spacing(0.5)};
      overflow: none;
    }
  }

  .image-tall {
    ${media.lessThan('700px')`
      display: none;
    `}
  }

  .media-container {
    margin-top: ${spacing(5)};

    ${media.greaterThan('1400px')`
      margin-top: ${spacing(8)};
    `}

    video {
      max-width: 100%;
    }
  }
`
