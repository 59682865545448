import styled, { css } from 'styled-components'
import { tokens, media, spacing } from '@tokens'
import styledMap from 'styled-map'
import Link from '@partials/Link'
export { Meta } from '@styles/Typography'
export { Filter } from '@styles/components/Filter'
import { H4Sizes, BaseType, BaseTypeSizes } from '@styles/Typography'

export const TeaserBase = css`
  display: flex;
  flex-direction: column;
  color: ${tokens.color('text')};
  font-weight: ${tokens.get('fontWeight.normal')};
  text-align: left;
  position: relative;

  .image {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .image-placeholder {
    background-color: ${tokens.color('grey', 'dark')};
    position: relative;

    .inner {
      padding-bottom: 62.5%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: ${tokens.get('maxWidth.tiny')};
    position: relative;
  }
`

export const TeaserStyles = css`
  ${TeaserBase}
  border-bottom: #eae2cc 1px solid;
  padding-bottom: ${spacing(3)};
  margin-bottom: ${spacing(3)};

  &::before {
    content: '';
    background: ${tokens.brand('lightAlt')};
    position: absolute;
    left: 50%;
    top: -${spacing(3)};
    transform: translate(-50%, 0);
    height: calc(100% + ${spacing(3)} + 2px);
    margin: -1px 0 0;
    width: ${tokens.get('maxWidth.vastSmall')};
    opacity: 0;
    transition: ${tokens.get('transition.fancy')};
    z-index: 0;

    ${media.greaterThan('m')`
      max-width: calc(100vw - ${spacing(8)});
    `}

    ${media.greaterThan('l')`
      max-width: calc(100vw - ${spacing(12)});
    `}

    ${media.greaterThan('xl')`
      max-width: ${tokens.get('maxWidth.vast')};
    `}
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  ${media.greaterThan('s')`
    flex-direction: row;
    padding-bottom: ${spacing(4)};
    margin-bottom: ${spacing(4)};

    &::before {
      top: -${spacing(4)};
      height: calc(100% + ${spacing(4)} + 2px);
    }
  `}

  ${media.greaterThan('l')`
    padding-bottom: ${spacing(5)};
    margin-bottom: ${spacing(5)};

    &::before {
      top: -${spacing(5)};
      height: calc(100% + ${spacing(5)} + 2px);
    }
  `}

  .image,
  .image-placeholder {
    flex-shrink: 0;

    ${media.greaterThan('s')`
      margin-right: ${spacing(4)};
      width: 12rem;
    `}

    ${media.greaterThan('m')`
      margin-right: ${spacing(8)};
      width: 24rem;
    `}

    ${media.greaterThan('l')`
      margin-right: ${spacing(12)};
      width: 30rem;
    `}
  }

  .content {
    ${media.lessThan('s')`
      padding-top: ${spacing(3)};
    `}

    p {
      max-width: 21rem;
    }
  }
`

export const SmallTeaserStyles = css`
  ${TeaserBase}

  .content {
    margin-top: ${spacing(3)};
  }

  .heading {
    ${H4Sizes};
    margin: 0;
  }

  .subheading {
    ${BaseType};
    ${BaseTypeSizes};
    margin-bottom: 0;
  }

  .image {
    overflow: hidden;

    .gatsby-image-wrapper {
      transform: scale(1);
      transition: filter ${tokens.get('transition.snappy')}, transform ${tokens.get('transition.snappy')};
    }
  }

  &:hover {
    .image {
      .gatsby-image-wrapper {
        filter: grayscale(0.5);
        transform: scale(1.05);
      }
    }
  }
`

export const Teaser = styled(Link)`
  ${TeaserStyles}
`

export const SmallTeaser = styled(Link)`
  ${SmallTeaserStyles}
`

export const PostList = styled.div`
  margin-top: ${spacing(8)};

  ${styledMap('teaserStyle', {
    SmallTeaser: css`
      display: grid;
      ${'' /* grid-template-columns: repeat(auto-fill, minmax(13.5rem, 1fr)); */}
      grid-template-columns: repeat(2, [col] 1fr);
      grid-gap: ${spacing(4)};
      align-items: start;

      ${media.greaterThan('s')`
        grid-gap: ${spacing(6)};
      `}

      ${media.greaterThan('m')`
        grid-template-columns: repeat(3, [col] 1fr);
        grid-gap: ${spacing(8)};
      `}

      ${media.greaterThan('l')`
        grid-gap: ${spacing(10)};
      `}
    `,
    default: css`
      margin-bottom: -${spacing(4)};

      ${media.greaterThan('s')`
        margin-bottom: -${spacing(4)};
      `}

      ${media.greaterThan('l')`
        margin-bottom: -${spacing(6)};
      `}
    `,
  })};

  ${media.greaterThan('s')`
    margin-top: ${spacing(12)};
  `}

  ${media.greaterThan('l')`
    margin-top: ${spacing(16)};
  `}
`
