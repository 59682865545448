import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import * as S from '@styles/components/Component'
import ComponentContent from '@partials/ComponentContent'
import WordpressForm from '@partials/Form'
import { getComponentClasses } from '@utils/components'
import SectionLabel from '@partials/SectionLabel'

const FormComponent = ({ component, className = '' }) => {
  const classes = getComponentClasses(component, ['form-block', className])
  const {
    background_color,
    background_color_fill,
    text_content,
    links,
    label,
    form,
  } = component
  return (
    <S.Component
      className={classes}
      bg={background_color}
      bgFill={background_color_fill}
    >
      {label && <SectionLabel label={label} bg={background_color} bgFill={background_color_fill} />}
      <div className="component__inner">
        <div className="component__content">
          <div className="component__intro">
            <ComponentContent {...text_content} links={links} />
          </div>

          <div className="forms__wrap">
            <WordpressForm {...form} />
          </div>
        </div>
      </div>
    </S.Component>
  )
}

FormComponent.propTypes = {
  component: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment FormComponentFragment on WordPressAcf_form_block {
    text_content {
      ...ComponentContentFragment
    }
    label
    form {
      wordpress_id
      title
      description
      wordpress_fields {
        type
        wordpress_id
        label
        isRequired
        errorMessage
        description
        placeholder
        cssClass
        defaultValue
        conditionalLogic {
          actionType
          logicType
          rules {
            fieldId
            operator
            value
          }
        }
        inputs {
          label
          name
        }
        choices {
          text
          value
          isSelected
        }
        rangeMin
        rangeMax
        range_slider_step
        range_slider_value_prefix
        range_slider_value_suffix
        range_slider_value_suffix_singular
      }
      button {
        type
        text
        imageUrl
      }
    }
    background_color
    background_color_fill
  }
`

export default FormComponent
