import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import * as S from '@styles/components/Slider'
// import Image from 'gatsby-image'
import Swiper from 'react-id-swiper'
import { Pagination, Navigation } from 'swiper/js/swiper.esm'
import 'swiper/css/swiper.min.css'
import { getComponentClasses } from '@utils/components'
import { ReactComponent as Chevron } from '@icons/chevron-right.svg'
import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.min.css'
import SectionLabel from '@partials/SectionLabel'

const Slide = ({ img, alt = null, maxVisible }) => (
  <S.Slide maxVisible={maxVisible}>
    {/* TODO: Update to use gatsby images */}
    {/* <Image
      {...image.localFile.childImageSharp}
      className="slide-image"
    /> */}
    <picture>
      {/* NOTE: Looks like Swiper lazyloading doesn't like the source element */}
      {/* <source
        type="image/webp"
        data-srcset={img.srcSetWebp}
        data-sizes={img.sizes}
      /> */}
      <img
        data-src={img.src}
        data-srcset={img.srcSet}
        sizes={img.sizes}
        className="swiper-lazy"
        alt={alt}
      />
      <img src={img.base64} />
    </picture>
  </S.Slide>
)

class SliderComponent extends Component {
  static propTypes = {
    component: PropTypes.object.isRequired,
    index: PropTypes.number,
    className: PropTypes.string,
  }

  constructor(props) {
    super(props)
    const swiper = null
    const { component, className, index } = this.props

    this.classes = getComponentClasses(component, ['slider', className])
    this.state = { swiper }

    this.params = {
      modules: [Pagination, Navigation],
      loop: component.loop,
      slidesPerView: component.visible_slides || 'auto',
      freeMode: component.visible_slides ? false : true,
      speed: 600,
      spaceBetween: component.visible_slides !== 1 ? 64 : 0,
      // Lazy
      preloadImages: false,
      lazy: {
        loadOnTransitionStart: true,
      },
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      // End Lazy
      getSwiper: swiperInstance => {
        // Accessibility - Set slides aria-hidden and tabindex on instantiation
        if (swiperInstance) {
          Array.from(swiperInstance.slides).forEach(slide => {
            this.updateSlideAccessibility(slide)
          })
        }

        this.setState({
          swiper: swiperInstance,
        })
      },
      shouldSwiperUpdate: false,
      on: {
        slideChange: () => {
          if (this.state.swiper) {
            // Accessibility - Set slides aria-hidden and tabindex on slide update
            const swiper = this.state.swiper
            Array.from(swiper.slides).forEach(slide => {
              this.updateSlideAccessibility(slide)
            })

            this.setState({
              swiper,
            })
          }
        },
      },
      renderPrevButton: () => (
        <div className="navigation prev">
          <Chevron className="icon" />
        </div>
      ),
      renderNextButton: () => (
        <div className="navigation next">
          <Chevron className="icon" />
        </div>
      ),
    }

    if (component.pagination) {
      this.params.pagination = {
        el: `.component-${index} .pagination`,
        type: 'bullets',
        bulletClass: 'bullet',
        bulletActiveClass: 'is-active',
        clickable: true,
      }
    }

    if (component.navigation) {
      this.params.navigation = {
        nextEl: '.next',
        prevEl: '.prev',
      }
    }

    if (component.autoplay) {
      this.params.autoplay = {
        delay: component.autoplay,
      }
    }

    if (component.visible_slides !== 1) {
      this.params.breakpoints = {
        599: {},
        899: {
          spaceBetween: 16,
        },
        1199: {
          spaceBetween: 32,
        },
        1799: {
          spaceBetween: 48,
        },
      }
    }

    if (component.visible_slides > 1) {
      this.params.breakpoints['599'].slidesPerView = 1
    }

    if (component.visible_slides > 2) {
      this.params.breakpoints['899'].slidesPerView = 2
    }

    if (component.visible_slides > 3) {
      this.params.breakpoints['1199'].slidesPerView = 3
    }
  }

  componentDidMount() {
    // react-id-swiper seems to initialize before visible which breaks
    // the swiping part of swiper. This fixes it but we should try to
    // find a better way (or better library)
    setTimeout(() => {
      if (this.state.swiper) {
        this.state.swiper.update()
      }
    }, 800)
  }

  componentDidUpdate() {
    // TODO: Build a better lightbox. One that works nicely with react.
    // TODO: Make lightbox not break with looping ><
    if (!this.lightbox) {
      this.lightbox = GLightbox({
        selector: `slide-link-c${this.props.index}`,
      })
    }
  }

  componentWillUnmount() {
    if (this.lightbox) {
      this.lightbox.baseEvents.destroy()
    }
  }

  updateSlideAccessibility(slide) {
    // Accessibility - Set slide aria and tabindex, only set tabindex
    // if slide is an a tag.
    const isVisible = slide.classList.contains('swiper-slide-visible')
    const tabIndex = isVisible ? '0' : '-1'
    const ariaHidden = isVisible ? 'false' : 'true'

    if (slide.hasAttribute('href')) {
      slide.setAttribute('tabIndex', tabIndex)
    }

    slide.setAttribute('aria-hidden', ariaHidden)
  }

  // TODO: Add lightbox

  render() {
    const { component, index } = this.props
    const {
      background_color,
      background_color_fill,
      label,
      lightbox,
      small_images,
      large_images,
      visible_slides,
    } = component
    const maxVisible = visible_slides || 'auto'
    const images = maxVisible === 'auto' ? small_images : large_images
    return (
      <S.Slider
        className={this.classes}
        maxVisible={maxVisible}
        bg={background_color}
        bgFill={background_color_fill}
      >
        {label && (
          <SectionLabel
            label={label}
            bg={background_color}
            bgFill={background_color_fill}
          />
        )}
        <Swiper {...this.params} ref={this.ref}>
          {images.map((image, key) => {
            const img = image.localFile.childImageSharp.fluid
            const alt = image.alt_text

            if (lightbox) {
              return (
                <a
                  href={image.localFile.publicURL}
                  className={`slide slide-link-c${index}`}
                  key={key}
                >
                  <Slide img={img} alt={alt} maxVisible={maxVisible}></Slide>
                </a>
              )
            }

            return (
              <div className="slide" key={key}>
                <Slide img={img} maxVisible={maxVisible}></Slide>
              </div>
            )
          })}
        </Swiper>
      </S.Slider>
    )
  }
}

export const query = graphql`
  fragment SliderComponentFragment on WordPressAcf_slider {
    pagination
    navigation
    lightbox
    loop
    visible_slides
    autoplay
    background_color
    background_color_fill
    label
    large_images: slider_images {
      id
      alt_text
      caption
      localFile {
        publicURL
        childImageSharp {
          # TODO: Add fixed query for multiple slide display
          # Use aliasing to run multiple queries?
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    small_images: slider_images {
      id
      alt_text
      caption
      localFile {
        publicURL
        childImageSharp {
          # TODO: Add fixed query for multiple slide display
          # Use aliasing to run multiple queries?
          fluid(maxHeight: 630) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export default SliderComponent
