import React, { Component } from 'react'
import PropTypes from 'prop-types'
//import { Link } from 'gatsby'
import NavBar from '@components/NavBar'
import Social from '@components/Social'
import { DesktopLogo, MobileLogo } from '@partials/Logos'
import Link from '@partials/Link'
import NavDrawerTrigger from '@partials/NavDrawerTrigger'
import SnipcartCartButton from '@partials/SnipcartCartButton'
import TweenMax from 'TweenMax'
import ScrollMagic from 'ScrollMagic'
import * as S from '@styles/components/Header'
import 'scrollmagic.gsap'
import { tokens } from '@tokens'

class Header extends Component {
  static propTypes = {
    fixed: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    // NOTE: Temporary reference for scroll handling
    // this.handleScroll = this.handleScroll.bind(this)

    if (this.props.fixed) {
      this.breakpoint
      this.targets
      this.triggerPoint = 0
      this.ref = React.createRef()
    }
    this.isProject =
      this.props.designations && this.props.designations.includes('project')
    this.invertColors = this.isProject || this.props.heroType === 'banner'
  }

  componentDidMount() {
    if (this.props.fixed) {
      this.breakpoint = window
        .getComputedStyle(this.ref.current)
        .getPropertyValue('--breakpoint')
      this.controller = new ScrollMagic.Controller()
      this.targets = {
        shrink: this.ref.current.querySelector('.inner'),
        logo: this.ref.current.querySelectorAll('.logo'),
        svgFills: this.ref.current.querySelectorAll(
          '.logo .text, .logo .border, .header__social svg'
        ),
        svgStroke: this.ref.current.querySelectorAll('.header .shopping-bag'),
        navLinks: this.ref.current.querySelectorAll('.navbar-link'),
        navBar: this.ref.current.querySelector('.navbar'),
      }
      this.buildScenes()

      document.body.classList.remove('sticky-header')
      window.addEventListener('resize', this.onResize.bind(this))
    }
  }

  componentDidUpdate() {
    if (this.props.fixed) {
      if (this.context.locked && this.controller.enabled()) {
        this.controller.enabled(false)
      } else if (!this.context.locked && !this.controller.enabled()) {
        this.controller.enabled(true)
      }
    }
  }

  // NOTE: Destroy any handlers created upon component unmount
  componentWillUnmount() {
    if (this.props.fixed) {
      window.removeEventListener('resize', this.onResize.bind(this), false)

      this.scenes.forEach(scene => {
        scene.destroy()
      })
    }
  }

  buildScenes() {
    const height = this.targets.shrink.offsetHeight
    const headerTween = new TweenMax.to(this.targets.shrink, 0.6, {
      height: height * 0.7,
      ease: Power2.easeInOut,
    })
    const headerBackgroundTween = new TweenMax.to(this.targets.shrink, 0.2, {
      backgroundColor: 'rgb(248, 242, 226, 1)', //Animating the alpha channel of the brand color. Should revisit.
    })
    const logoTween = new TweenMax.to(this.targets.logo, 0.6, {
      height: this.breakpoint !== 'xs' ? '70%' : '60%',
      ease: Power2.easeInOut,
      fill: tokens.brand('dark'),
    })

    this.scenes = [
      new ScrollMagic.Scene({
        offset: this.triggerPoint,
        duration: 600,
      })
        .setTween(headerTween)
        .addTo(this.controller),
      new ScrollMagic.Scene({
        offset: this.triggerPoint,
        duration: 200,
      })
        .setTween(headerBackgroundTween)
        .addTo(this.controller),
      new ScrollMagic.Scene({
        offset: this.triggerPoint,
        duration: 600,
      })
        .setTween(logoTween)
        .addTo(this.controller),
    ]

    if (this.invertColors) {
      const logoFillTween = new TweenMax.to(this.targets.svgFills, 0.6, {
        ease: Power2.easeInOut,
        fill: tokens.brand('dark'),
      })
      const strokeTween = new TweenMax.to(this.targets.svgStroke, 0.6, {
        ease: Power2.easeInOut,
        stroke: tokens.brand('dark'),
      })
      const colorTween = new TweenMax.to(this.targets.navLinks, 0.6, {
        ease: Power2.easeInOut,
        color: tokens.brand('dark'),
      })
      this.scenes.push(
        new ScrollMagic.Scene({
          offset: this.triggerPoint,
          duration: 600,
        })
          .setTween(logoFillTween)
          .addTo(this.controller),
        new ScrollMagic.Scene({
          offset: this.triggerPoint,
          duration: 600,
        })
          .setTween(colorTween)
          .addTo(this.controller),
        new ScrollMagic.Scene({
          offset: this.triggerPoint,
          duration: 600,
        })
          .setTween(strokeTween)
          .addTo(this.controller)
      )
    }
  }

  destroyScenes() {
    this.scenes.forEach(scene => {
      scene.destroy(true)
    })
    this.scenes = null

    this.targets.shrink.removeAttribute('style')
    this.targets.logo.forEach(logo => {
      logo.removeAttribute('style')
    })
  }

  onResize() {
    const oldBreakpoint = this.breakpoint

    if (this.ref.current) {
      this.breakpoint = window
        .getComputedStyle(this.ref.current)
        .getPropertyValue('--breakpoint')

      if (this.scenes && this.breakpoint !== oldBreakpoint) {
        this.destroyScenes()
        this.buildScenes()
      }
    }
  }

  // Needs refactoring
  render() {
    return (
      <S.Header
        className="header"
        fixed={this.props.fixed}
        ref={this.ref}
        invertColors={this.invertColors}
      >
        <div className="inner">
          <Link className="brand" to="/" aria-label="Gearbox Logo">
            <DesktopLogo className="desktop-logo logo" />
            <MobileLogo className="mobile-logo logo" />
          </Link>
          <NavBar className="navbar" invertColors={this.invertColors} />
          <SnipcartCartButton invertColors={this.invertColors} />
          <Social className="header__social" invertColors={this.invertColors} />
          <NavDrawerTrigger invertColors={this.invertColors} />
        </div>
      </S.Header>
    )
  }
}

export default Header
