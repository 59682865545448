import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import * as S from '@styles/components/Flyout'
import { H2 } from '@styles/Typography'
import Image from '@partials/Image'

export const FlyoutContent = props => {
  return (
    <StaticQuery
      query={graphql`
        query allWordpressWpFlyouts {
          allWordpressWpFlyout {
            edges {
              node {
                id
                title
                slug
                wordpress_id
                acf {
                  heading
                  content
                  image {
                    alt_text
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 640) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { node: post } = data.allWordpressWpFlyout.edges.find(
          ({ node }) => {
            return node.wordpress_id === props.sid
          }
        )

        const {
          acf: { image, content, heading },
        } = post

        return (
          <div className="flyout-content">
            <Image source={image} aspectRatio={4 / 3} />
            <div className="content">
              <H2>{heading}</H2>
              <div
                className="summary"
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            </div>
          </div>
        )
      }}
    />
  )
}

FlyoutContent.propTypes = {
  sid: PropTypes.number,
}

export default FlyoutContent
