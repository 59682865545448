import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { ITCBold } from '@styles/Typography'

export const Header = styled.header`
  --breakpoint: xs;
  position: ${styledMap`
    fixed: fixed;
    default: relative;
  `};
  top: 0;
  z-index: ${tokens.z('header')};
  width: 100%;
  height: ${tokens.get('component.header.height.s')};
  overflow: hidden;
  box-sizing: border-box;

  ${media.greaterThan('s')`
    --breakpoint: s;
    height: ${tokens.get('component.header.height.m')};
  `}

  ${media.greaterThan('m')`
    --breakpoint: m;
    height: ${tokens.get('component.header.height.l')};
  `}

  .inner {
    ${box({
      spacing: 'horizontal',
    })}
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: rgba(0, 0, 0, 0) 0 0 ${spacing(3)};
    background-color: rgb(
      248,
      242,
      226,
      0
    ); /* Set manually to animate the alpha channel on scroll. Should revisit */
    will-change: height, background-color; /* Height is expensive to animate. Should revisit during animation performance audit */

    ${media.greaterThan('m')`
      ${box({
        spacing: 'horizontal',
        multiplier: 0.5,
      })}
      display: flex;
    `}

    ${media.greaterThan('l')`
      ${box({
        spacing: 'horizontal',
        multiplier: 0.75,
      })}
      display: flex;
    `}
  }

  .brand {
    margin-left: 0;
    margin-right: auto;
    height: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    position: relative;

    ${media.greaterThan('m')`
      align-self: flex-end;
    `}
  }

  .logo {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);

    ${styledMap('invertColors', {
    true: css`
      .border,
      .text {
        fill: ${tokens.brand('light')};

        .flyout-open & {
          fill: ${tokens.brand('dark')} !important;
        }
      }
    `,
    'default': '',
    })}
  }

  .header__social {
    ${media.lessThan('m')`
      display: none;
    `}
  }

  .back-button {
    ${ITCBold}
    display: none;
    color: ${tokens.brand('light')};
    font-size: 0.875rem;
    letter-spacing: ${tokens.get('type.letterSpacing.large')};;
    text-transform: uppercase;
    text-align: center;

    ${media.greaterThan('l')`
      font-size: 1rem;
    `}

    svg {
      fill: ${tokens.brand('light')};
      width: ${spacing(7)};
      margin-bottom: ${spacing(0.5)};

      ${media.greaterThan('l')`
        width: ${spacing(9)};
      `}
    }
  }
`
