import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, box, media } from '@tokens'
import { MediumTypeSizes, Heading, H6Sizes } from '@styles/Typography'
import { ListInlineFlex } from '@styles/objects/List'
import { Link } from '@styles/objects/Link'

export const PageContentRTE = css`
  h1, h2, h3, h4, h5, h6 {
    margin-top: ${spacing(6)};

    &:first-child {
      margin-top: 0;
    }

    ${media.greaterThan('m')`
      margin-top: ${spacing(8)};
    `}
  }

  .alignnone {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    height: auto;
  }

  .aligncenter {
    display: block;
    margin: ${spacing(2)} auto;
    height: auto;
    max-width: 100%;
  }

  .alignleft,
  .alignright {
    margin-bottom: ${spacing(2)};
    height: auto;
  }

  @media (min-width: 30rem) {
    .alignleft {
      float: left;
      margin-right: ${spacing(2)};
    }

    .alignright {
      float: right;
      margin-left: ${spacing(2)};
    }
  }
`

export const Content = styled.div`
  position: relative;

  p {
    a {
      ${Link};
      overflow: hidden;
      vertical-align: top;

      &::after {
        height: ${spacing(0.25)};
      }
    }
  }
`

export const PageContent = styled.article`
  ${box};
  ${MediumTypeSizes};
  ${PageContentRTE}

  .inner {
    margin: 0 auto;
    max-width: ${tokens.get('maxWidth.narrow')};
  }

  .tags {
    display: flex;
    margin-top: ${spacing(8)};

    h6 {
      margin: 0 ${spacing()} 0 0;
    }

    .taglist {
      ${ListInlineFlex};

      .tag {
        ${Heading};
        ${H6Sizes};
        color: ${tokens.brand('darkAlt2')};
        margin-bottom: 0;

        &:not(:last-child) {
          &::after {
            content: ',';
            margin-right: ${spacing()};
          }
        }

        a {
          color: ${tokens.brand('darkAlt2')};
        }
      }
    }
  }
`
