import React, { Component } from 'react'
import ScrollMagic from 'ScrollMagic'
import 'scrollmagic.gsap'
import ArrowLight from '@images/down-arrow-light.svg'
import ArrowDark from '@images/down-arrow-light.svg'
import * as S from '@styles/components/ArrowDown'

class ArrowDown extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.timelines = {}
    this.classNames = `arrow-down ${props.className || ''}`
    this.arrowSrc = props.color === 'light' ? ArrowLight : ArrowDark
    this.props = props
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller()
    this.targets = {
      arrow: this.ref.current,
    }

    this.timelines.loop = new TimelineMax({
      paused: true,
      repeat: -1,
      yoyo: true,
    }).fromTo(
      this.targets.arrow,
      0.5,
      {
        y: -5,
      },
      {
        y: 5,
        ease: Power0.easeNone,
      }
    )

    this.scenes = [
      new ScrollMagic.Scene({
        triggerElement: this.ref.current.parentElement,
        triggerHook: 1,
        duration: 0,
        reverse: false,
      })
        .on('enter', e => {
          this.targets.arrow.src = this.targets.arrow.dataset.src
        })
        .addTo(this.controller),
      new ScrollMagic.Scene({
        triggerElement: this.ref.current.parentElement,
        triggerHook: 0.85,
        duration: 0,
      })
        .on('start end', e => {
          const direction = e.target.controller().info('scrollDirection')
          // TODO Add helper function
          if (direction === 'FORWARD' || direction === 'PAUSED') {
            this.timelines.loop.play()
          } else {
            this.timelines.loop.pause()
          }
        })
        .addTo(this.controller),
    ]
  }

  componentWillUnmount() {
    Object.keys(this.timelines).forEach(key => {
      this.timelines[key].kill()
    })
    this.scenes.forEach(scene => {
      scene.destroy(true)
    })
    this.scenes = null
  }

  render() {
    return (
      <S.ArrowDown
        ref={this.ref}
        className={this.classNames}
        data-src={this.arrowSrc}
        alt="stylized arrow pointing down"
        {...this.props}
      />
    )
  }
}

export default ArrowDown
