import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media } from '@tokens'
import { ITCBold } from '@styles/Typography'

export const NavDrawerTrigger = styled.div`
  ${ITCBold};
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  /*
    Pull the word MENU tight to the header padding.
    The word CLOSE will over lap the padding
  */
  margin-right: -0.5rem;
  letter-spacing: ${tokens.get('type.letterSpacing.medium')};
  text-transform: uppercase;
  ${styledMap('invertColors', {
    true: css`
      ${styledMap('isOpen', {
        'true': css`
          color: ${tokens.brand('dark')};
        `,
        'default': css`
          color: ${tokens.brand('light')};

          .sticky-header & {
            color: ${tokens.brand('dark')};
          }
        `,
      })}
    `,
    default: css`
      color: ${tokens.brand('dark')}
    `
  })};

  ${media.greaterThan('m')`
    display: none;
  `}
`

export const Hamburger = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  .hamburger__box {
    display: block;
    position: relative;
    width: ${spacing(4)};
    height: ${spacing(3)};
    margin-right: ${spacing(1)};

    &::before,
    &::after,
    .hamburger__box-inner {
      content: '';
      position: absolute;
      right: 0;
      z-index: ${tokens.z('base')};
      display: block;
      height: ${spacing(0.5)};
      border-radius: ${spacing(0.5)};
      ${styledMap('invertColors', {
        true: css`
          ${styledMap('isOpen', {
            'true': css`
              background-color: ${tokens.brand('dark')};
            `,
            'default': css`
              background-color: ${tokens.brand('light')};

              .sticky-header & {
                background-color: ${tokens.brand('dark')};
              }
            `,
          })}
        `,
        default: css`
          background-color: ${tokens.brand('dark')}
        `
      })};
      will-change: transform, width;
      transition: transform 200ms ${tokens.get('transition.snappy')},
        width 200ms ${tokens.get('transition.snappy')},
        background-color 200ms linear;
    }

    &::before,
    &::after {
      transform-origin: 100% 50%;
      width: ${styledMap('isOpen', {
        true: '90%',
        default: '75%',
      })};
    }

    &::before {
      top: 0;
      transform: ${styledMap('isOpen', {
        true: 'rotate(-45deg)',
        default: 'rotate(0deg)',
      })};
    }

    &::after {
      bottom: 0;
      transform: ${styledMap('isOpen', {
        true: 'rotate(45deg)',
        default: 'rotate(0deg)',
      })};
    }
  }

  .hamburger__box-inner {
    width: 100%;
    top: 50%;
    margin-top: ${spacing(-0.25)};
    width: ${styledMap('isOpen', {
      true: '0%',
      default: '100%',
    })};
  }

  .hamburger__text {
    height: 1.5em;
    overflow: hidden;
    transition: color 200ms linear;
  }

  .hamburger__text-viewport {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 200%;
    transition: transform 200ms ${tokens.get('transition.snappy')};
    transform: ${styledMap('isOpen', {
      true: 'translate3d(0%, -50%, 0)',
      default: 'translate3d(0%, 0%, 0)',
    })};

    span {
      display: block;
      line-height: 1.5;
    }
  }
`
