import React, { Component } from 'react'
import * as S from '@styles/components/HeroSvg'
import ScrollMagic from 'ScrollMagic'
import { TimelineMax, Power2, TweenMax } from 'gsap'
import SpeedLines from '@svgs/SpeedLinesSvg'
import Cloud from '@svgs/CloudSvg'
import Car from '@svgs/CarSvg'
import { tokens } from '@tokens'
import 'scrollmagic.gsap'
import { hasNoLoadInParam } from '@utils/components'

class HeroSvg extends Component {
  constructor(props) {
    super(props)
    this.ref_scene = React.createRef()
    this.ref_lines = React.createRef()
    this.ref_cloud = React.createRef()
    this.ref_car = React.createRef()
    this.speedLineSettings = tokens.get('speedLinesSettings')
    this.timelines = {}
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller()
    this.scenes = []
    this.hasScrollAnimation = window.matchMedia('(min-width: 600px)').matches
    this.speedLines = this.ref_lines.current.querySelectorAll('.speed-line')
    this.cloud = this.ref_cloud.current
    this.sceneWrap = {
      scene: this.ref_scene.current,
      frame: this.ref_scene.current.querySelector('.scene-frame'),
      anchor: this.ref_scene.current.querySelector('.scene-anchor'),
    }
    this.car = {
      all: this.ref_car.current,
      wheels: this.ref_car.current.querySelectorAll('.car-wheel'),
      wheelsInner: this.ref_car.current.querySelectorAll('.car-wheel__inner'),
      body: this.ref_car.current.querySelectorAll(
        '.car-body, .car-windshield, .car-headlight, .car-vent, .car-door'
      ),
      lines: this.ref_car.current.querySelectorAll('.car-line'),
    }
    this.noLoadInParam = hasNoLoadInParam()

    if (!this.noLoadInParam) {
      this.setScrollMagicScenes()
    }
    this.setSceneTimeline()
    this.setSpeedLinesTimeline()
    this.setCarTimeline()
    this.setCloudTimeline()

    // Load in Animation
    TweenMax.to(this.ref_lines.current, 0.2, {
      opacity: 1,
    })

    if (this.hasScrollAnimation && !this.noLoadInParam) {
      this.timelines.frameIn.play()
    }

    // Reset Speedlines & Scene timeline on resize end.
    this.onResize = this.onResize.bind(this)
    window.addEventListener('resize', this.onResize, false)
  }

  componentWillUnmount() {
    Object.keys(this.timelines).forEach(timeline => {
      this.timelines[timeline].kill()
    })
    this.scenes.forEach(scene => {
      scene.destroy(true)
    })
    this.scenes = null

    window.removeEventListener('resize', this.onResize, false)
  }

  setSpeedLinesTimeline() {
    this.timelines.speedlines = this.timelines.speedlines
      ? this.timelines.speedlines.kill()
      : undefined

    this.timelines.speedlines = new TimelineMax({
      paused: true,
      repeat: -1,
    }).seek(20)

    const bodyWidth = parseInt(
      window.getComputedStyle(document.body).getPropertyValue('width'),
      10
    )

    this.speedLines.forEach(line => {
      const maxWidth = this.speedLineSettings.max + this.speedLineSettings.min
      const lineWidth = parseInt(
        window.getComputedStyle(line).getPropertyValue('width'),
        10
      )
      const tweenlength = bodyWidth + maxWidth
      const tweenTo = tweenlength > 1920 ? tweenlength * -1 : -1920
      const duration = 1.1 * ((maxWidth - lineWidth) / maxWidth) + 1
      const staggerPlayback =
        Math.floor(Math.random() * Math.floor(duration)) * -1

      this.timelines.speedlines.fromTo(
        line,
        duration,
        {
          x: 0,
        },
        {
          x: tweenTo,
          ease: Power0.easeNone,
          repeat: -1,
          repeatDelay: 1,
        },
        `-=${staggerPlayback}`
      )
    })

    this.timelines.speedlines.play()
  }

  setCarTimeline() {
    this.timelines.car = this.timelines.car
      ? this.timelines.car.kill()
      : undefined

    this.timelines.car = new TimelineMax({
      paused: true,
    })

    this.timelines.car
      .fromTo(
        this.car.body,
        0.5,
        {
          y: -1,
        },
        {
          y: 1,
          ease: Power0.easeNone,
          repeat: -1,
          yoyo: true,
        },
        'car'
      )
      .fromTo(
        this.car.wheelsInner,
        0.0125,
        {
          rotation: 0,
          transformOrigin: '50% 50%',
        },
        {
          rotation: -360,
          ease: Power0.easeNone,
          repeat: -1,
        },
        'car'
      )
      .staggerFromTo(
        this.car.wheels,
        0.25,
        {
          y: 1,
          transformOrigin: '50% 50%',
        },
        {
          y: -1,
          ease: Power0.easeNone,
          repeat: -1,
          yoyo: true,
        },
        0.125,
        'car'
      )

    this.car.lines.forEach((line, index) => {
      let lineDuration = index % 2 ? 15 : 20
      this.timelines.car.fromTo(
        line,
        lineDuration,
        {
          strokeDashoffset: 0,
        },
        {
          strokeDashoffset: 4000,
          ease: Power0.easeNone,
          repeat: -1,
        },
        0.125,
        'car'
      )
    })
    this.timelines.car.play()
  }

  setCloudTimeline() {
    this.timelines.cloud = this.timelines.cloud
      ? this.timelines.cloud.kill()
      : undefined

    this.timelines.cloud = new TimelineMax({
      paused: true,
      repeat: -1,
    })

    this.timelines.cloud.fromTo(
      this.cloud,
      3,
      {
        rotation: 0,
        skewY: '0deg',
        skewX: '0deg',
      },
      {
        rotation: 1,
        skewY: '10deg',
        skewX: '5deg',
        repeat: -1,
        ease: Sine.easeInOut,
        yoyo: true,
      },
      'cloud'
    )

    this.timelines.cloud.play()
  }

  setSceneTimeline() {
    this.timelines.scene = this.timelines.scene
      ? this.timelines.scene.kill()
      : undefined

    this.timelines.scene = new TimelineMax({
      paused: true,
      repeat: -1,
    })

    this.timelines.scene.fromTo(
      this.sceneWrap.anchor,
      1.5,
      {
        x: 0,
      },
      {
        x: 20,
        ease: Sine.easeInOut,
        repeat: -1,
        yoyo: true,
      }
    )
    this.timelines.scene.play()
  }

  setScrollMagicScenes() {
    if (this.scenes.length) {
      this.scenes.forEach(scene => {
        scene.destroy(true)
      })
    }

    this.hasScrollAnimation = window.matchMedia('(min-width: 600px)').matches

    if (this.hasScrollAnimation) {
      this.setScrollMagicTimelines()
    }

    this.scenes = [
      new ScrollMagic.Scene({
        triggerElement: this.sceneWrap.frame,
        triggerHook: 0,
        offset: window.outerHeight,
        duration: 0,
      })
        .on('start end', e => {
          const direction = e.target.controller().info('scrollDirection')
          Object.keys(this.timelines).forEach(key => {
            if (['frameIn', 'frameOut'].includes(key)) {
              return
            }

            // TODO Add helper function
            if (direction === 'FORWARD' || direction === 'PAUSED') {
              this.timelines[key].pause()
            } else {
              this.timelines[key].play()
            }
          })
        })
        .addTo(this.controller),
    ]

    if (this.hasScrollAnimation) {
      this.scenes.push(
        new ScrollMagic.Scene({
          triggerElement: this.sceneWrap.frame,
          triggerHook: 0,
          offset: 150,
          duration: 0,
        })
          .on('start end', e => {
            const direction = e.target.controller().info('scrollDirection')
            // TODO Add helper function
            if (direction === 'FORWARD' || direction === 'PAUSED') {
              const animationCheckOut = setInterval(() => {
                if (!this.timelines.frameIn.isActive()) {
                  clearInterval(animationCheckOut)
                  this.timelines.frameOut
                    .play()
                    .eventCallback('onComplete', () => {
                      this.timelines.frameIn.seek(0).pause()
                    })
                }
              }, 50)
            } else {
              const animationCheckIn = setInterval(() => {
                if (!this.timelines.frameOut.isActive()) {
                  clearInterval(animationCheckIn)
                  this.timelines.frameIn
                    .play()
                    .eventCallback('onComplete', () => {
                      this.timelines.frameOut.seek(0).pause()
                    })
                }
              }, 50)
            }
          })
          .addTo(this.controller)
      )
    }
  }

  setScrollMagicTimelines() {
    this.timelines.frameOut = this.timelines.frameOut
      ? this.timelines.frameOut.kill()
      : undefined

    this.timelines.frameIn = this.timelines.frameIn
      ? this.timelines.frameIn.kill()
      : undefined

    this.timelines.frameOut = new TimelineMax({
      paused: true,
    }).to(this.sceneWrap.frame, 1, {
      x: window.outerWidth > 1920 ? window.outerWidth * 1.5 : 3250,
      ease: Power2.easeIn,
    })

    this.timelines.frameIn = new TimelineMax({
      paused: true,
    })
      .to(this.sceneWrap.frame, 0, {
        x: -1920,
      })
      .to(this.sceneWrap.frame, 3, {
        x: 0,
        ease: Expo.easeOut,
      })
  }

  onResize() {
    clearTimeout(debounced)
    const debounced = setTimeout(() => {
      this.setScrollMagicScenes()
      this.setSpeedLinesTimeline()
    }, 400)
  }

  render() {
    return (
      <S.HeroSvg ref={this.ref_scene} noLoadInParam={this.noLoadInParam}>
        <SpeedLines ref={this.ref_lines} className="speed-lines" />
        <div className="scene-frame">
          <div className="scene-anchor">
            <Car ref={this.ref_car} />
            <Cloud ref={this.ref_cloud} />
          </div>
        </div>
      </S.HeroSvg>
    )
  }
}

export default HeroSvg
