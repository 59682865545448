import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Icon from '@partials/Icon'
import SectionLabel from '@partials/SectionLabel'
import ComponentContent from '@partials/ComponentContent'
import { getComponentClasses, hasIcon } from '@utils/components'
import * as S from '@styles/components/Tiles'

const TilesComponent = ({ component, className = '' }) => {
  const classes = getComponentClasses(component, ['tiles', className])
  const {
    content_alignment,
    background_color,
    background_color_fill,
    text_content,
    links,
    label,
  } = component

  return (
    <S.Tiles
      className={classes}
      alignment={content_alignment}
      bg={background_color}
      bgFill={background_color_fill}
    >
      {label && <SectionLabel label={label} bg={background_color} bgFill={background_color_fill} />}
      <div className="inner">
        {hasIcon(component) && (
          <div className="image">
            <Icon {...component} className="tiles-image" aspectRatio={1} />
          </div>
        )}
        <div className="content">
          <ComponentContent {...text_content} links={links} />
        </div>
      </div>
    </S.Tiles>
  )
}

TilesComponent.propTypes = {
  component: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export const query = graphql`
  fragment TilesComponentFragment on WordPressAcf_tiles {
    text_content {
      ...ComponentContentFragment
    }
    label
    media_type
    dynamic_svg
    svg
    image {
      alt_text
      localFile {
        extension
        publicURL
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    background_color
    background_color_fill
    content_alignment
    links {
      ...LinkFragment
    }
  }
`

export default TilesComponent
