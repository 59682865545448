import React from 'react'
import GatsbyImage from 'gatsby-image/withIEPolyfill'

export const Image = ({
  source,
  aspectRatio,
  alt_text,
  className = '',
  objectFit = 'cover',
  objectPosition = '50% 50%',
  ...other
}) => {
  if (!source || !source.localFile) return false
  let image = source.localFile.childImageSharp

  if (aspectRatio) {
    image[Object.keys(image)[0]].aspectRatio = aspectRatio
  }

  const classes = `image ${className} ${
    image[Object.keys(image)[0]].aspectRatio <= 1
      ? 'image--portrait'
      : 'image--landscape'
  }`

  return (
    <figure className={classes}>
      <GatsbyImage
        {...image}
        objectFit={objectFit}
        objectPosition={objectPosition}
        alt={alt_text || source.alt_text}
        {...other}
      />
    </figure>
  )
}

export default Image
