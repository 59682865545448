import styled from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media, box } from '@tokens'
import { ITCBold } from '@styles/Typography'

export const Flyout = styled.div`
  background-color: ${tokens.brand('light')};
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  will-change: transform;
  z-index: ${tokens.z('flyout')};
  opacity: 0;

  .inner {
    ${box({
      spacing: 'horizontal',
    })}
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    width: 100%;
    padding-bottom: ${spacing(5)};
    padding-top: ${spacing(parseInt(tokens.get('component.header.height.s')) * 2)};

    .sticky-header & {
      padding-top: ${spacing(parseInt(tokens.get('component.header.height.s')) * 2 * 0.7)};
    }

    ${media.greaterThan('s')`
      padding-top: ${spacing(parseInt(tokens.get('component.header.height.m')) * 2)};

      .sticky-header & {
        padding-top: ${spacing(parseInt(tokens.get('component.header.height.m')) * 2 * 0.7)};
      }
    `}
  }

  .flyout-header {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-top: ${tokens.spacing('s')};
    padding-right: ${tokens.spacing('s')};
    padding-bottom: ${tokens.spacing('s')};
  }

  .close {
    ${ITCBold};
    height: ${tokens.spacing('s')};
    width: ${tokens.spacing('s')};
    fill: ${tokens.color('text')};
    font-size: 0.875rem;
    line-height: ${tokens.get('type.lineHeight.body')};
    text-transform: uppercase
  }

  .flyout-content {
    width: 100%;
  }

  .gatsby-image-wrapper {
    margin-bottom: ${spacing(4)};
  }
`
