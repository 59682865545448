import React, { Component } from 'react'
import { FlyoutContext } from '@context/flyout-context'
import { ReactComponent as Close } from '@icons/close.svg'
import * as S from '@styles/components/Flyout'
import { Overlay } from '@styles/components/Overlay'
import Flyout from '@partials/Flyout'
import disableScroll from 'disable-scroll'

class FlyoutContainer extends Component {
  constructor(props) {
    super(props)

    const open = false
    const active = null
    const toggleFlyout = this.toggleFlyout
    this.state = { open, active, toggleFlyout }
    this.ref = React.createRef()
  }

  componentDidMount() {
    // Bind esc to close open flyouts
    document.addEventListener('keydown', this.escClose, false)
    const navItems = this.ref.current.querySelectorAll(
      '.is-active .navdrawer-link'
    )

    // Build Timeline
    this.timeline = new TimelineLite({
      paused: true,
    })
      .fromTo(
        this.ref.current,
        0.4,
        {
          autoAlpha: 0,
          scale: 1.05,
          transformOrigin: '50% 50%',
        },
        {
          autoAlpha: 1,
          scale: 1,
        }
      )
      .staggerFromTo(
        navItems,
        0.2,
        {
          autoAlpha: 0,
          x: 20,
          scale: 1.05,
          transformOrigin: '50% 50%',
        },
        {
          x: 0,
          autoAlpha: 1,
          scale: 1,
        },
        0.1,
        '-=0.2'
      )
  }

  componentWillUnmount() {
    // Remove listeners
    document.removeEventListener('keydown', this.escClose, false)
    // Kill GSAP timeline
    this.timeline.kill()
  }

  componentDidUpdate() {
    // Play timeline
    if (this.state.open) {
      this.timeline.play()
      document.body.classList.add('flyout-open')
      disableScroll.on()
    } else if (!this.state.open) {
      this.timeline.reverse()
      document.body.classList.remove('flyout-open')
      disableScroll.off()
    }
  }

  // Update state
  toggleFlyout = () => {
    this.setState(state => ({
      open: state.open ? false : true,
    }))
  }

  close = () => {
    if (this.state.open) {
      this.toggleFlyout()
    }
  }

  escClose = event => {
    if (event.keyCode === 27) {
      this.close()
    }
  }

  render() {
    const { open, postType, sid } = this.state
    const { children } = this.props
    return (
      <FlyoutContext.Provider value={this.state}>
        {children}
        <S.Flyout open={open} ref={this.ref}>
          {sid !== 'menu' && (
            <div className="flyout-header">
              <Close className="close" onClick={this.close} />
            </div>
          )}
          <div className="inner">
            <Flyout
              sid={sid}
              postType={postType}
              toggleFlyout={this.toggleFlyout}
            />
          </div>
        </S.Flyout>
        <Overlay open={open} onClick={this.close} />
      </FlyoutContext.Provider>
    )
  }
}

export default FlyoutContainer
