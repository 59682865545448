import React, { useState, useEffect, useRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ChevronRight } from '@icons/chevron-right.svg'
import { ReactComponent as ChevronLeft } from '@icons/chevron-left.svg'
import Link from '@partials/Link'
import useSiteNav from '@hooks/use-site-nav'
import * as S from '@styles/components/NavDrawer'
import { TweenMax } from 'gsap'
import BoltSvg from '@svgs/BoltSvg'

// Parse site nav links and create an object for components
const parseLevels = (links, levels = {}, parentId = null) => {
  links.forEach(link => {
    // If has children can function recursivly
    if (link.wordpress_children) {
      levels = parseLevels(link.wordpress_children, levels, link.object_id)
      // If no children but had a parentID set menu to links.
    } else if (parentId) {
      levels[`sub-menu-${parentId}`] = links
    }
  })
  return levels
}

// Take the site nav and build an object to fit ML Menu
const buildLevels = () => {
  // Get sitenav via hook
  const links = useSiteNav()
  // Iterate through links and built levels
  const levels = parseLevels(links)
  levels.main = links
  return levels
}

const NavDrawer = ({ toggleFlyout }) => {
  // Set reordered menus levels in state
  const [levels, setLevels] = useState(buildLevels())
  // Create Refs for each Menu level & the back button
  const levelRefs = {}
  Object.keys(levels).forEach(menuname => {
    levelRefs[menuname] = useRef()
  })
  levelRefs.back = useRef()
  // Set the Refs in state
  const [refs, setRefs] = useState(levelRefs)
  // This will track our path and active state.
  const [menuActive, setMenuActive] = useState({
    nextPath: '',
    prevPath: [],
    active: 'main',
    back: false,
  })

  // Hide all menu levels with GSAP. Aside from the main menu.
  useEffect(() => {
    Object.keys(refs).forEach(menuname => {
      if (menuname !== 'main') {
        TweenMax.set(refs[menuname].current, {
          opacity: 0,
          x: '100%',
        })
      }
    })
  }, []) // Will only fire on mount due to the empty

  useEffect(() => {
    // Get the goods
    const { active, back, prevPath, nextPath } = menuActive
    // NextPath is only set if navigating backward
    const outMenu = back ? nextPath : prevPath.slice(-1)[0]

    // Make sure we have all we need to animate
    if (!active || !outMenu || outMenu === active || !refs[outMenu]) {
      return
    }

    // If back reverse the direction
    const inDirection = back ? '-100%' : '100%'
    const outDirection = back ? '100%' : '-100%'

    // Get refs for animation
    const inElements = [refs[active].current]
    const outElements = [refs[outMenu].current]

    // Include the back button if nessesary
    if (outMenu === 'main') {
      inElements.push(refs.back.current)
    } else if (active === 'main') {
      outElements.push(refs.back.current)
    }

    // Out with the old and in with the new.
    TweenMax.fromTo(
      outElements,
      0.2,
      {
        x: '0%',
        autoAlpha: 1,
      },
      {
        x: outDirection,
        autoAlpha: 0,
        onComplete: () => {
          TweenMax.fromTo(
            inElements,
            0.2,
            {
              x: inDirection,
              autoAlpha: 0,
            },
            {
              x: '0%',
              autoAlpha: 1,
            }
          )
        },
      }
    )
    // Will only fire if menuActive is updated
  }, [menuActive])

  // Navigate backward
  const backClickHandler = e => {
    e.preventDefault()
    const { active, prevPath } = menuActive
    // Clone, get the last item in the array, update array
    const copyPrevPath = [...prevPath]
    const prevMenu = copyPrevPath.pop()
    // Update menuActive State
    setMenuActive({
      nextPath: active,
      prevPath: copyPrevPath,
      active: prevMenu,
      back: true,
    })
  }

  return (
    <S.NavDrawer>
      <S.Back
        ref={refs.back}
        onClick={e => backClickHandler(e)}
        className="navdrawer-back"
      >
        <ChevronLeft className="icon" />
        Back
      </S.Back>
      {Object.keys(levels).map(menuname => (
        <NavDrawerLevel
          key={menuname}
          menuitems={levels[menuname]}
          menuname={menuname}
          menuActive={menuActive}
          setMenuActive={setMenuActive}
          ref={refs[menuname]}
          refs={refs}
          toggleFlyout={toggleFlyout}
        />
      ))}
      <div className="bolt-container">
        <BoltSvg />
      </div>
    </S.NavDrawer>
  )
}

const NavDrawerLevel = forwardRef((props, ref) => {
  const { menuitems, menuname, menuActive, setMenuActive } = props

  const clickHandler = (e, link) => {
    e.preventDefault()
    // Set Key with the menuobject
    const menukey = `sub-menu-${link.object_id}`
    // Clone and add to previous path
    const prevPath = [...menuActive.prevPath]
    prevPath.push(menuname)
    setMenuActive({
      nextPath: '',
      prevPath,
      active: menukey,
      back: false,
    })
  }

  return (
    <S.NavDrawerLevel
      ref={ref}
      className={`
        navdrawer-level
        navdrawer-level-${menuname}
        ${menuname === 'main' ? 'is-active' : ''}
      `}
    >
      {menuitems.map((link, index) => {
        const iconOnClick = e => {
          clickHandler(e, link)
        }
        const linkProps = {
          className: 'navdrawer-link',
          activeClassName: 'is-active',
        }

        if (!link.wordpress_children) {
          linkProps.to = link.url
        } else if (link.url === '#') {
          linkProps.onClick = e => {
            clickHandler(e, link)
          }
        } else {
          linkProps.to = link.url
        }

        return (
          <S.NavDrawerItem key={index}>
            <Link
              {...linkProps}
              onClick={() => {
                props.toggleFlyout()
              }}
            >
              {link.title}
            </Link>
            {link.wordpress_children && (
              <ChevronRight onClick={iconOnClick} className="icon" />
            )}
          </S.NavDrawerItem>
        )
      })}
    </S.NavDrawerLevel>
  )
})

NavDrawerLevel.propTypes = {
  menuitems: PropTypes.arrayOf(PropTypes.object),
  menuname: PropTypes.string,
  menuActive: PropTypes.shape({
    nextPath: PropTypes.string,
    prevPath: PropTypes.arrayOf(PropTypes.string),
    active: PropTypes.string,
    back: PropTypes.bool,
  }),
  setMenuActive: PropTypes.func,
}

export default NavDrawer
