import React, { Component } from 'react'
import ScrollMagic from 'ScrollMagic'
import 'scrollmagic.gsap'

class LightbulbSvg extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.timelines = {}
    this.props = props
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller()
    this.targets = {
      bulb: this.ref.current.querySelector('.bulb'),
      shineWrap: this.ref.current.querySelector('.shine-wrap'),
      shine: this.ref.current.querySelector('.shine'),
    }

    this.timelines.scroll = new TimelineMax()
      .fromTo(
        this.targets.bulb,
        0.6,
        {
          scale: 0,
          transformOrigin: '50% 50%',
        },
        {
          scale: 1,
          ease: Back.easeOut.config(1.7),
        }
      )
      .fromTo(
        this.targets.shineWrap,
        0.6,
        {
          scale: 0.8,
          autoAlpha: 0,
          transformOrigin: '50% 50%',
        },
        {
          scale: 1,
          autoAlpha: 1,
          ease: Back.easeOut.config(1.7),
        },
        '-=0.3'
      )

    this.timelines.loop = new TimelineMax({
      paused: true,
      repeat: -1,
      yoyo: true,
    }).fromTo(
      this.targets.shine,
      1,
      {
        transformOrigin: '50% 50%',
        scale: 0.9,
      },
      {
        transformOrigin: '50% 50%',
        scale: 1.1,
      }
    )

    this.scenes = [
      new ScrollMagic.Scene({
        triggerElement: this.ref.current,
        triggerHook: 0.85,
        duration: 0,
      })
        .setTween(this.timelines.scroll)
        .on('start end', e => {
          const direction = e.target.controller().info('scrollDirection')
          // TODO Add helper function
          if (direction === 'FORWARD' || direction === 'PAUSED') {
            this.timelines.loop.play()
          } else {
            this.timelines.loop.pause()
          }
        })
        .addTo(this.controller),
    ]
  }

  componentWillUnmount() {
    Object.keys(this.timelines).forEach(key => {
      this.timelines[key].kill()
    })
    this.scenes.forEach(scene => {
      scene.destroy(true)
    })
    this.scenes = null
  }

  render() {
    return (
      <svg
        className={this.className}
        ref={this.ref}
        {...this.props}
        viewBox="0 0 159 163"
        style={{ overflow: 'visible' }}
      >
        <g className="bulb">
          <path d="M64.6 160.2l.2-1.1c.1-.9.9-1.6 1.8-1.7l24.8-2.6c.5 0 1.1.1 1.5.5.4.4.6.9.7 1.4v3.4c0 .7-.2 1.3-.7 1.8s-1.1.7-1.8.7H67c-.7 0-1.3-.2-1.8-.7-.5-.4-.7-1-.6-1.7zM66.7 149.8h25.5c.9 0 1.7-.7 1.7-1.7v-1.3c0-1.3-.9-2.4-2.2-2.6-5.3-.9-18.6-2.9-24.3-3.5-.7-.1-1.4.2-1.9.7-.5.5-.7 1.2-.7 1.9 0 .5.1.9.1 1.4v3.3c-.1 1 .7 1.8 1.8 1.8zM85.9 82.8c-1.1.6-2.4.3-3.1-.7l-4.2-6.3-4.3 6.8c-.3.5-.9.9-1.5 1-.6.1-1.3 0-1.8-.4L62.6 77l12.5 57.8 8.1-.1L96.4 77l-10.5 5.8z" />
          <path d="M102.2 42.4c-11.3-8.3-31.9-7.9-44-1.4-15.8 8.5-22.4 22.2-20.5 39.2C40.3 103 63 112.5 63 136.3c0 .4.5.6.9.6l6.2-1.5L56.8 72c-.1-.6 0-1.3.4-1.8s.9-.9 1.5-1c.7-.1 1.3.1 1.8.5L71.7 78l4.8-7.8c.4-.7 1.2-1.1 2-1.1s1.5.4 1.9 1l5 7.5 13.2-7.3c.8-.4 1.8-.4 2.5.2.7.5 1.1 1.5.9 2.4l-13.8 61.9 6.7 1.9c.4 0 .9-.2.9-.5 0-25.7 21.3-32.6 23.3-55.4 1.7-17.2-3.1-28.3-16.9-38.4z" />
        </g>
        <g className="shine-wrap">
          <path
            className="shine"
            d="M79.7 26.1c.6 0 1.2-.3 1.7-.7.4-.5.7-1.1.7-1.7V3.4c.1-.9-.4-1.7-1.1-2.1s-1.7-.5-2.4 0-1.2 1.3-1.1 2.1v20.3c-.2 1.3.8 2.4 2.2 2.4zm-45.9 17c.5.5 1.1.7 1.8.7s1.3-.3 1.8-.7c1-1 1-2.5 0-3.5L25.7 27.8c-.5-.5-1.1-.8-1.8-.8s-1.3.3-1.8.8c-1 1-1 2.5 0 3.5l11.7 11.8zm18.5-15.5c.3.6.8 1 1.4 1.2.6.2 1.3.1 1.8-.1 1.1-.6 1.6-2 1-3.2L53.9 20c-.3-.6-.8-1-1.4-1.2-.6-.2-1.3-.1-1.8.2-1.1.6-1.6 2-1 3.2l2.6 5.4zm-27 32.8L15.4 57c-.6-.2-1.3-.2-1.9.1s-1 .8-1.3 1.5c-.4 1.3.3 2.7 1.6 3.1l9.9 3.4c.6.2 1.3.2 1.9-.1.6-.3 1-.8 1.2-1.5.5-1.3-.2-2.7-1.5-3.1zm-22.9 30h25c1.3 0 2.4-1 2.4-2.3V88c0-1.3-1.1-2.3-2.4-2.3h-25c-1.3 0-2.4 1-2.4 2.3v.1c0 .6.3 1.2.7 1.7.5.4 1.1.6 1.7.6zm20.4 30l6.2-6.3c.6-.6.9-1.5.6-2.3s-.9-1.5-1.7-1.7-1.7 0-2.3.6l-6.2 6.3c-.7.6-.9 1.5-.7 2.4s.9 1.5 1.7 1.8 1.8-.1 2.4-.8zm101.5-74.5L138 32.2c.9-1 .9-2.5 0-3.4-.5-.5-1.1-.7-1.8-.7s-1.3.3-1.8.7l-13.6 13.7c-.9 1-.9 2.5 0 3.4.5.5 1.1.7 1.8.7s1.3-.2 1.7-.7zM109.5 19c-.6-.3-1.2-.4-1.8-.2-.6.2-1.1.6-1.4 1.2l-2.7 5.5c-.3.6-.3 1.2-.1 1.8.2.6.6 1.1 1.2 1.3 1.2.6 2.7.1 3.2-1.1l2.6-5.4c.6-1.2.1-2.5-1-3.1zm23.8 44.6c.2.6.6 1.2 1.2 1.5s1.3.3 1.9.1l10-3.4c.6-.2 1.1-.7 1.4-1.2.3-.6.3-1.3.1-1.9-.2-.7-.7-1.2-1.3-1.5-.6-.3-1.3-.4-2-.1l-9.9 3.4c-1.1.3-1.8 1.7-1.4 3.1zM130 90.4h25.9c1.3 0 2.4-1 2.4-2.3V88c0-1.3-1.1-2.3-2.4-2.3H130c-1.3 0-2.4 1-2.4 2.3v.1c0 .6.3 1.2.7 1.7.5.4 1.1.6 1.7.6zm7.4 30c.9.9 2.5.9 3.4 0 .9-.9.9-2.5 0-3.4l-6.1-6.2c-.8-.8-2.1-1-3.1-.5-.6.4-1.1 1-1.2 1.8-.1.7.1 1.5.7 2l6.3 6.3z"
          />
        </g>
      </svg>
    )
  }
}

export default LightbulbSvg
