import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import ComponentContent from '@partials/ComponentContent'
import ArrowDown from '@partials/ArrowDown'
// TODO: Move to extend model with a base "PageComponent" class containing shared methods
import { getComponentClasses } from '@utils/components'
import SectionLabel from '@partials/SectionLabel'
import * as S from '@styles/components/Text'
import BoltSvg from '@svgs/BoltSvg'

const TextComponent = ({ component, arrow = false, className = '' }) => {
  const classes = getComponentClasses(component, ['text', className])
  const {
    background_color,
    background_color_fill,
    text_content_alignment,
    text_alignment,
    text_content,
    label,
    links,
    has_bolt,
  } = component

  return (
    <S.Text
      className={classes}
      bg={background_color}
      bgFill={background_color_fill}
      textAlignment={text_alignment}
      contentAlignment={text_content_alignment}
    >
      {label && (
        <SectionLabel
          label={label}
          bg={background_color}
          bgFill={background_color_fill}
        />
      )}
      {arrow && (
        <ArrowDown color={background_color !== 'dark' ? 'dark' : 'light'} />
      )}
      <div className="inner">
        <div className="content">
          {has_bolt && (
            <div className="bolt-container">
              <BoltSvg />
            </div>
          )}
          <ComponentContent {...text_content} links={links} />
        </div>
      </div>
    </S.Text>
  )
}

TextComponent.propTypes = {
  component: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment TextComponentFragment on WordPressAcf_text {
    text_content {
      ...ComponentContentFragment
    }
    links {
      ...LinkFragment
    }
    label
    background_color
    background_color_fill
    text_alignment
    text_content_alignment
    has_bolt
  }
`

export default TextComponent
