import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as S from '@styles/components/Form'
import { Formik, Field, ErrorMessage } from 'formik'
import SliderField from '@partials/SliderField'
import * as Yup from 'yup'
import axios from 'axios'
import { affixString, concatRange } from '@utils/strings'
import { isEmptyObject } from '@utils/values'
import ScrollToPlugin from 'ScrollToPlugin'
import TextPlugin from 'TextPlugin'
import ScrambleTextPlugin from '@greensock/ScrambleTextPlugin'

class WordpressForm extends Component {
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    wordpress_fields: PropTypes.array,
  }

  constructor(props) {
    super(props)

    this.noLabel = ['text', 'tel', 'email']
    this.defaults = this.getDefaults()
    this.validationSchema = this.buildValidationSchema()
    this.gsapPlugins = [ScrollToPlugin, TextPlugin, ScrambleTextPlugin]
    this.submissionTimeline = new TimelineMax({
      id: 'submissionTL',
      repeat: -1,
      paused: true,
    })
    this.buttonTimeline = new TimelineMax({
      id: 'buttonTL',
      paused: true,
    })
    this.confirmationTimeline = new TimelineMax({
      id: 'confirmationTL',
      paused: true,
    })

    // TODO: Source via static query
    this.apiUrl = `https://source.gearboxbuilt.com/wp-json/gf/v2/forms/${props.wordpress_id}`
    this.lambdaUrl =
      'https://us-central1-gearbox-253501.cloudfunctions.net/gearbox-submit-gf'

    // TODO: Source via hidden form fields
    this.confirmation = {
      heading: 'We Got You',
      content:
        'You may not know what you need—but we know we can help. Whether it’s a few tweaks or an overhaul, we’re in this together, and we’ll help you get there.',
    }

    this.state = {
      fieldValues: this.defaults,
      submitting: false,
      submitted: false,
      confirmed: false,
    }
  }

  componentDidMount() {
    this.buildSubmissionTimelines()
    this.buildConfirmationTimelines()
  }

  buildSubmissionTimelines() {
    const button = document.querySelector('.form-block button[type=submit]')
    const cog = button.querySelector('.cog')
    this.buttonTimeline
      .to(button, 0.3, {
        color: 'transparent',
        ease: Power1.easeOut,
      })
      .call(() => {
        if (!this.submissionTimeline.isActive()) {
          this.submissionTimeline.play()
        } else {
          this.submissionTimeline.pause()
        }
      })
      .to(
        cog,
        0.3,
        {
          autoAlpha: 1,
          ease: Power1.easeIn,
        },
        0.7
      )

    this.submissionTimeline.to(cog, 1.5, {
      rotation: 360,
      ease: Power0.easeNone,
    })
  }

  buildConfirmationTimelines() {
    this.confirmationTimeline
      .to(
        window,
        0.75,
        {
          scrollTo: { y: '.hero', autoKill: false },
          ease: Power2.easeOut,
          id: 'scroll',
        },
        0
      )
      .to(
        '.form-block',
        0.5,
        {
          autoAlpha: 0,
          id: 'fade',
        },
        0
      )
      .to(
        '.form-block',
        1,
        {
          height: 0,
          padding: 0,
          id: 'collapse',
          ease: Power2.easeOut,
        },
        0
      )
      .to('.hero .heading', 0.5, {
        scrambleText: {
          text: this.confirmation.heading,
          chars: 'GEARBOX',
        },
      })
      .to(
        '.hero .body',
        1,
        {
          text: {
            value: this.confirmation.content,
            padSpace: true,
          },
        },
        '-=0.25'
      )
  }

  componentDidUpdate() {
    const { submitting, submitted, confirmed } = this.state

    if (submitting) {
      this.buttonTimeline.play()
    } else if (this.submissionTimeline.isActive()) {
      this.buttonTimeline.reverse()
    }

    if (submitted && !confirmed) {
      this.confirmationTimeline.play()
      TweenMax.set(document.querySelector('.container'), {
        overflow: 'hidden',
      })
    }
  }

  getFieldName(id) {
    return `input_${String(id).replace('.', '_')}`
  }

  getDefaults() {
    const defaults = {}

    // TODO: Add support for checkboxes
    this.props.wordpress_fields.forEach(field => {
      const name = this.getFieldName(field.wordpress_id)
      switch (field.type) {
        case 'range_slider':
          defaults[name] = this.getSliderDefault(field)
          break
        case 'radio':
          defaults[name] = this.getRadioDefault(field)
          break
        default:
          defaults[name] = field.defaultValue
          break
      }
    })

    return defaults
  }

  getRadioDefault(field) {
    let defaultValue = ''

    field.choices.forEach(choice => {
      if (choice.isSelected) {
        defaultValue = choice.value
      }
    })

    return defaultValue
  }

  getSliderDefault(field) {
    return concatRange(
      affixString(field.rangeMin, {
        prefix: field.range_slider_value_prefix,
      }),
      affixString(field.rangeMax, {
        suffix: field.range_slider_value_suffix,
      })
    )
  }

  buildValidationSchema() {
    const schema = {}

    this.props.wordpress_fields.forEach(field => {
      let rule = ''
      const required = field.isRequired

      switch (field.type) {
        case 'email':
          // NOTE: This can be done way better using context once we update to Formik 2.0
          if (field.isRequired) {
            rule = Yup.string()
              .email('Email address is not valid')
              .required(field.errorMessage || 'Please enter your email address')
          } else {
            rule = Yup.string().email('Email address is not valid')
          }
          break
        case 'phone':
          // NOTE: This can be done way better using context once we update to Formik 2.0
          if (field.isRequired) {
            rule = Yup.string()
              .matches(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/, {
                message: 'Phone number is not valid',
                excludeEmptyString: true,
              })
              .required(field.errorMessage || 'Please enter your phone number')
          } else {
            rule = Yup.string().matches(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/, {
              message: 'Phone number is not valid',
              excludeEmptyString: true,
            })
          }
          break
        default:
          if (field.isRequired) {
            rule = Yup.string().required(
              field.errorMessage || 'This field is required'
            )
          }
          break
      }

      if (!rule) {
        return
      }

      const name = this.getFieldName(field.wordpress_id)
      schema[name] = rule
    })

    return schema
  }

  checkRuleMatch(rule, value) {
    switch (rule.operator) {
      case 'is':
        return value == rule.value
      case 'isnot':
        return value != rule.value
      case '>':
        // TODO: Add slider range support
        return parseInt(value) > parseInt(rule.value)
      case '<':
        // TODO: Add slider range support
        return parseInt(value) < parseInt(rule.value)
      case 'contains':
        return String(value).includes(String(rule.value))
      case 'starts_with':
        return String(value).startsWith(String(rule.value))
      case 'ends_with':
        return String(value).endsWith(String(rule.value))
      default:
        return false
    }
  }

  checkFieldVisibility(conditionalLogic) {
    if (isEmptyObject(conditionalLogic)) return true

    const { actionType: action, logicType: type, rules } = conditionalLogic

    let results = []
    let pass = false

    rules.forEach(rule => {
      Object.entries(this.state.fieldValues).forEach(([key, value]) => {
        if (key === `input_${rule.fieldId}`) {
          results.push(this.checkRuleMatch(rule, value))
        }
      })
    })

    switch (type) {
      case 'all':
        pass = results.every(result => result)
        break
      case 'any':
        pass = results.some(result => result)
        break
    }

    return action === 'show' ? pass : !pass
  }

  // TODO: Refactor
  mapFieldData({ fieldData, index, values, errors, touched, handleBlur }) {
    const name = this.getFieldName(fieldData.wordpress_id)
    const label = fieldData.label + (fieldData.isRequired ? '*' : '')
    const placeholderLabels = ['text', 'tel', 'email']

    if (fieldData.type === 'phone') {
      fieldData.type = 'tel'
    }

    if (placeholderLabels.includes(fieldData.type)) {
      fieldData.placeholder = label
    }

    let fieldProps = {
      name,
      label,
      key: name,
      id: name,
      type: fieldData.type,
    }

    if (fieldData.type === 'range_slider') {
      Object.assign(fieldProps, {
        min: Number(fieldData.rangeMin),
        max: Number(fieldData.rangeMax),
        step: Number(fieldData.range_slider_step),
        valuePrefix: fieldData.range_slider_value_prefix,
        valueSuffix: fieldData.range_slider_value_suffix,
        valueSuffixSingular: fieldData.range_slider_value_suffix_singular,
        value: this.getSliderDefault(fieldProps),
      })
    } else {
      Object.assign(fieldProps, {
        placeholder: fieldData.placeholder,
        value: values[name] || fieldData.defaultValue,
        error: errors[name],
        touched: touched[name] ? touched[name].toString() : 'false',
        onBlur: handleBlur,
        choices: fieldData.choices,
      })
    }

    return fieldProps
  }

  // TODO: Create field templates
  getField(inputProps, index) {
    const ariaRequired = inputProps.name in this.validationSchema ? true : false

    switch (inputProps.type) {
      case 'select':
        if (inputProps.choices) {
          return (
            <Field
              {...inputProps}
              component={inputProps.type}
              aria-required={ariaRequired}
            >
              {inputProps.choices.map((input, count) => {
                const props = {
                  key: `${inputProps.name}_${index}_${count}`,
                  name: inputProps.name,
                  type: inputProps.type,
                  id: `${inputProps.name}_${count}`,
                  label: input.text,
                  value: input.value,
                  onChange: inputProps.onChange,
                  onBlur: inputProps.onBlur,
                }

                return (
                  <option key={props.key} value={props.value}>
                    {props.label}
                  </option>
                )
              })}
            </Field>
          )
        }
        break
      case 'textarea':
        return (
          <Field
            {...inputProps}
            component={inputProps.type}
            aria-required={ariaRequired}
          />
        )
      case 'radio':
        if (inputProps.choices) {
          return (
            <fieldset aria-required={ariaRequired} tabIndex={0}>
              {inputProps.choices.map((input, count) => {
                const props = {
                  key: `${inputProps.name}_${index}_${count}`,
                  name: inputProps.name,
                  type: inputProps.type,
                  id: `${inputProps.name}_${count}`,
                  label: input.text,
                  value: input.value,
                  onChange: inputProps.onChange,
                  onBlur: inputProps.onBlur,
                }

                return (
                  <Field key={props.key} name={props.name}>
                    {({ field, form }) => {
                      const isChecked =
                        this.defaults[inputProps.name] === props.value
                          ? true
                          : false

                      return (
                        <span key={`${props.key}_wrap`}>
                          <input {...props} checked={isChecked} />
                          <label key={`${props.key}_label`} htmlFor={props.id}>
                            {props.label}
                          </label>
                        </span>
                      )
                    }}
                  </Field>
                )
              })}
            </fieldset>
          )
        }
        break
      case 'checkbox':
        if (inputProps.inputs) {
          return (
            <fieldset aria-required={ariaRequired} tabIndex={0}>
              {inputProps.inputs.map((input, count) => {
                const name = this.getFieldName(input.wordpress_id)
                const props = {
                  key: `${name}_${index}`,
                  name: name,
                  type: inputProps.type,
                  id: `${name}`,
                  label: input.label,
                  onChange: inputProps.onChange,
                  onBlur: inputProps.onBlur,
                }

                return (
                  <Field key={props.key} name={props.name}>
                    {({ field, form }) => (
                      <span key={`${props.key}_wrap`}>
                        <input {...props} />
                        <label key={`${props.key}_label`} htmlFor={props.id}>
                          {props.label}
                        </label>
                      </span>
                    )}
                  </Field>
                )
              })}
            </fieldset>
          )
        }
        break
      case 'range_slider':
        return (
          <Field {...inputProps} aria-required={ariaRequired}>
            {({ field, form }) => {
              field.onChange = inputProps.onChange
              return (
                <SliderField
                  defaultValue={[inputProps.min, inputProps.max]}
                  {...inputProps}
                  {...field}
                  form={form}
                />
              )
            }}
          </Field>
        )
      case 'section':
        return null
      default:
        return <Field {...inputProps} aria-required={ariaRequired} />
    }
  }

  // TODO: Make sure this works for checkbox fields
  onChange(e, handleChange) {
    let fieldValues = this.state.fieldValues
    fieldValues[e.target.name] = e.target.value

    this.setState({
      fieldValues,
    })

    return handleChange(e)
  }

  static async submitForm(apiUrl, values, lambdaUrl) {
    const data = {
      baseUrl: apiUrl,
      payload: values,
    }

    let result

    try {
      result = await axios.post(lambdaUrl, {
        responseType: 'json',
        withCredentials: true,
        crossdomain: true,
        data: data,
      })
    } catch (err) {
      // Pass back error
      return {
        status: 'error',
        data: err.response.data,
      }
    }

    return {
      status: 'success',
      data: result,
    }
  }

  render() {
    const { description, wordpress_fields } = this.props
    const changeCallback = this.onChange.bind(this)

    return (
      <Formik
        initialValues={this.defaults}
        validationSchema={Yup.object().shape(this.validationSchema)}
        onSubmit={(values, { setSubmitting }) => {
          let submitting = true
          let submitted = false
          this.setState({ submitting })

          // Write to Gravity forms entries.
          const submit = (async () => {
            const response = await this.constructor.submitForm(
              this.apiUrl,
              values,
              this.lambdaUrl
            )

            switch (response.status) {
              case 'error':
                submitting = false
                this.setState({ submitting })

                if (typeof response.data !== 'undefined') {
                  if (response.data.status === 'gravityFormsError') {
                    // TODO: Handle GF errors
                    console.error(
                      'Gravity forms is angry',
                      response.data.validation_messages
                    )
                  }
                } else {
                  // TODO: Add error indicator?
                  console.error('Unknown Error', response.data)
                }
                break
              case 'success':
                console.log('Submission Successful', response.data)
                submitting = false
                submitted = true
                this.setState({ submitting, submitted })
                break
            }

            setSubmitting(false)
          })()
        }}
      >
        {props => {
          const {
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            isSubmitting,
          } = props

          const onChange = e => {
            changeCallback(e, handleChange)
          }

          return (
            <>
              <div id="confirmation"></div>
              <S.Form onSubmit={handleSubmit}>
                {description && <p>description</p>}
                {wordpress_fields.map((fieldData, index) => {
                  const isVisible = this.checkFieldVisibility(
                    fieldData.conditionalLogic
                  )
                  const inputProps = this.mapFieldData({
                    fieldData,
                    index,
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                  })
                  inputProps.onChange = e => {
                    onChange(e)
                  }

                  return (
                    <S.Field
                      key={index}
                      name={inputProps.name}
                      type={inputProps.type}
                      isVisible={isVisible}
                    >
                      {!this.noLabel.includes(inputProps.type) && (
                        <label htmlFor={inputProps.name}>
                          {inputProps.label}
                        </label>
                      )}
                      {/* TODO: Refactor */}
                      {this.getField(inputProps, index)}
                      {inputProps.name in this.validationSchema && (
                        <ErrorMessage name={inputProps.name}>
                          {err => (
                            <S.Error className={`error--${inputProps.type}`}>
                              {err}
                            </S.Error>
                          )}
                        </ErrorMessage>
                      )}
                    </S.Field>
                  )
                })}
                <button type="submit" disabled={isSubmitting}>
                  <span>Submit</span>
                  <svg
                    className="cog"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50.26 51.03"
                  >
                    <path d="M50.2 22.2a2.07 2.07 0 0 0-1.4-2l-4.6-1.33a20.12 20.12 0 0 0-1-2.46l2.34-4.25a2.14 2.14 0 0 0-.34-2.45l-4.56-4.59a2.09 2.09 0 0 0-2.44-.34L34 7.14a21.24 21.24 0 0 0-2.42-1L30.3 1.49a2.06 2.06 0 0 0-2-1.49h-6.41A2.05 2.05 0 0 0 20 1.49l-1.36 4.62a21.24 21.24 0 0 0-2.42 1l-4.17-2.33a2.09 2.09 0 0 0-2.44.34L5.05 9.75a2.12 2.12 0 0 0-.33 2.45l2.33 4.25a21.84 21.84 0 0 0-1 2.46l-4.57 1.33a2.09 2.09 0 0 0-1.48 2l.06 6.59a2.06 2.06 0 0 0 1.4 2l4.6 1.33a18.9 18.9 0 0 0 1 2.46l-2.34 4.21a2.14 2.14 0 0 0 .34 2.45l4.56 4.63a2.06 2.06 0 0 0 2.44.34l4.18-2.36a20.22 20.22 0 0 0 2.42 1L20 49.54A2.07 2.07 0 0 0 22 51h6.41a2 2 0 0 0 1.94-1.49l1.31-4.62a19 19 0 0 0 2.42-1l4.17 2.33a2.07 2.07 0 0 0 2.44-.34l4.56-4.63a2.1 2.1 0 0 0 .33-2.45l-2.33-4.25a21.11 21.11 0 0 0 1-2.46l4.57-1.33a2.08 2.08 0 0 0 1.48-2zm-8.14 6.28a2.16 2.16 0 0 0-1.41 1.44 16.08 16.08 0 0 1-1.52 3.72 2.09 2.09 0 0 0 0 2l2.1 3.83-2.41 2.44-3.76-2.13a2 2 0 0 0-2 0 15.25 15.25 0 0 1-3.66 1.53 3 3 0 0 0-.41.18 2.15 2.15 0 0 0-1 1.25l-1.17 4.2h-3.43l-1.18-4.19a2.1 2.1 0 0 0-1-1.26 3 3 0 0 0-.41-.18 15.25 15.25 0 0 1-3.66-1.53 2.09 2.09 0 0 0-2 0l-3.75 2.13L9 39.47l2.08-3.83a2.09 2.09 0 0 0 0-2 16.87 16.87 0 0 1-1.53-3.76 2.12 2.12 0 0 0-1.39-1.4l-4.1-1.19v-3.55l4.14-1.19a2.12 2.12 0 0 0 1.41-1.44 16.08 16.08 0 0 1 1.52-3.72 2.09 2.09 0 0 0 0-2L9 11.56l2.41-2.44 3.76 2.13a2 2 0 0 0 2 0 15.67 15.67 0 0 1 3.66-1.53 1.65 1.65 0 0 0 .41-.18 2.08 2.08 0 0 0 1-1.25l1.17-4.2h3.43l1.18 4.19a2.07 2.07 0 0 0 1 1.26 3 3 0 0 0 .41.18 15.67 15.67 0 0 1 3.66 1.53 2 2 0 0 0 2 0l3.75-2.13 2.41 2.44-2.05 3.83a2.14 2.14 0 0 0 0 2 17.06 17.06 0 0 1 1.53 3.76 2.1 2.1 0 0 0 1.39 1.4l4.08 1.19v3.55zm-17 9.09a12.05 12.05 0 1 1 12-12 12.06 12.06 0 0 1-11.96 12zm0-20.1a8.05 8.05 0 1 0 8 8.05 8.06 8.06 0 0 0-7.96-8.05z" />
                  </svg>
                </button>
              </S.Form>
            </>
          )
        }}
      </Formik>
    )
  }
}

export default WordpressForm
