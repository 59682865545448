import { css } from 'styled-components'
import { tokens, spacing } from '@tokens'

export const Link = css`
  position: relative;
  overflow: hidden;

  &::after {
    display: block;
    content: '';
    width: 100%;
    height: ${spacing(0.5)};
    margin-top: ${spacing(0.25)};
    transform: translate3d(0, 0, 0);
    transform-origin: center center;
    border-radius: ${spacing(4)};
    background: ${tokens.brand('dark')};
    transition: transform 0.25s ${tokens.get('easing.outCubic')};
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &:hover,
  &:focus,
  &:active,
  &.is-active {
    &::after {
      transform: translate3d(101%, 0, 0);
    }
  }


  .navbar &,
  &.option,
  &.footer-nav-link {

    &::after {
      transform: translate3d(101%, 0, 0);
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active {
      &::after {
        transform: translate3d(0, 0, 0);
      }
    }
  }
`