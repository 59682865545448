import { useStaticQuery, graphql } from 'gatsby'

// Fetch site navigation and export for use
export const useSiteNav = () => {
  const { wordpressWpApiMenusMenusItems } = useStaticQuery(
    graphql`
      query NavItems {
        wordpressWpApiMenusMenusItems(slug: { eq: "primary-navigation" }) {
          items {
            title
            object_id
            order
            url
            wordpress_id
            wordpress_children {
              object_id
              order
              title
              url
              wordpress_id
              wordpress_children {
                object_id
                order
                title
                url
                wordpress_id
              }
            }
          }
        }
      }
    `
  )
  return wordpressWpApiMenusMenusItems.items
}

export default useSiteNav
