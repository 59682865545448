import React from 'react'
import PropTypes from 'prop-types'
import ListItem from '@partials/ListItem'
import * as L from '@styles/components/List'

const listLayouts = {
  basic: L.ListBasic,
  fancy: L.ListFancy,
  inline: L.ListInline,
  columns: L.ListColumns,
}

const List = ({ list, layout, className }) => {
  const S = {}
  const hasBolt = layout === 'basic' || layout === 'inline'
  S.List = listLayouts[layout]

  if (!S.List) {
    return null
  }

  return (
    <S.List className={className}>
      {list.map((listItem, index) => {
        return (
          <ListItem
            className="list__item"
            label={listItem.label}
            content={listItem.content}
            link={listItem.is_link ? listItem.link : null}
            key={index}
            listItem={listItem}
            hasBolt={hasBolt}
          />
        )
      })}
    </S.List>
  )
}

List.propTypes = {
  list: PropTypes.array,
}

export default List
