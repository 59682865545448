import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing } from '@tokens'

export const DesktopLogo = styled.svg`
  display: none;
  height: 80%;
  max-height: 5.625rem;
  width: auto;

  ${media.greaterThan('s')`
    display: block;
  `}

  ${media.greaterThan('m')`
    height: 100%;
  `}

  .text,
  .border {
    fill: ${tokens.brand('dark')};
  }

  .background {
    fill: transparent;
  }
`
export const MobileLogo = styled.svg`
  display: block;
  height: 60%;
  max-height: 5.625rem;
  width: auto;

  ${media.greaterThan('s')`
    display: none;
  `}

  .text,
  .border {
    fill: ${tokens.brand('dark')};
  }

  .background {
    fill: transparent;
  }
`
