export const getComponentClasses = (component, classes = []) => {
  const { background_color, background_color_fill } = component
  let cssClasses = classes.join(' ')
  cssClasses +=
    component.component_attrs && component.component_attrs.css_class
      ? ` ${component.component_attrs.css_class}`
      : ''
  const componentClasses = [
    `background-color-${background_color}`,
    `background-fill-${background_color_fill}`,
    cssClasses,
  ]

  return componentClasses.join(' ')
}

export const hasIcon = icon => {
  if (
    !icon.media_type ||
    icon.media_type === 'none' ||
    icon[icon.media_type] === null
  ) {
    return false
  }

  return true
}

export const hasNoLoadInParam = () => {
  if ('URLSearchParams' in window) {
    const url = new URL(window.location)
    return new URLSearchParams(url.search).has('no-load-in')
  }

  return false
}
