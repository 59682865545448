import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { H3 } from '@styles/Typography'
import * as S from '@styles/components/Banner'
import { LockContext } from '@context/lock-context'
import ScrollMagic from 'scrollmagic'
import 'scrollmagic.gsap'
import Links from '@partials/Links'
import BoltSvg from '@svgs/BoltSvg'
import { getComponentClasses } from '@utils/components'
import SectionLabel from '@partials/SectionLabel'

class BannerComponent extends Component {
  static contextType = LockContext
  static propTypes = {
    component: PropTypes.object.isRequired,
    className: PropTypes.string,
  }

  constructor(props) {
    super(props)

    const { component, className } = this.props

    this.classes = getComponentClasses(component, [
      'banner',
      component.parallax ? 'has-parallax' : '',
      component.has_bolt ? 'has-bolt' : '',
      className,
    ])
    this.component = component

    if (component.parallax) {
      this.ref = React.createRef()
    }
  }

  componentDidMount() {
    if (this.props.component.parallax) {
      this.controller = new ScrollMagic.Controller({
        globalSceneOptions: {
          triggerHook: 'onEnter',
          duration: '200%',
        },
      })

      const target = this.ref.current.querySelector('.gatsby-image-wrapper')

      if (target) {
        this.scene = new ScrollMagic.Scene({ triggerElement: this.ref.current })
          .setTween(target, { yPercent: 50, ease: Linear.easeNone })
          .addTo(this.controller)
      }
    }
  }

  componentDidUpdate() {
    if (this.props.component.parallax) {
      if (this.context.locked && this.controller.enabled()) {
        this.controller.enabled(false)
      } else if (!this.context.locked && !this.controller.enabled()) {
        this.controller.enabled(true)
      }
    }
  }

  componentWillUnmount() {
    if (this.scene) {
      this.scene.destroy()
    }
  }

  render() {
    const {
      background_color,
      background_color_fill,
      background_text,
      text_content: { heading, content },
      links,
      has_bolt,
      label,
    } = this.component
    return (
      <S.Banner
        className={this.classes}
        bg={background_color}
        bgFill={background_color_fill}
        ref={this.ref}
      >
        {label && <SectionLabel label={label} bg={background_color} bgFill={background_color_fill} />}
        {!has_bolt && background_text && (
          <div className="background-text">{background_text}</div>
        )}
        <div className="inner">
          {has_bolt && (
            <div className="bolt-container">
              <BoltSvg />
            </div>
          )}
          <div className="content">
            {heading && (
              <H3
                className="heading"
                dangerouslySetInnerHTML={{
                  __html: heading,
                }}
              />
            )}
            {content && (
              <div
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            )}
            {links && <Links links={links} />}
          </div>
        </div>
      </S.Banner>
    )
  }
}

export const query = graphql`
  fragment BannerComponentFragment on WordPressAcf_banner {
    text_content {
      ...ComponentContentFragment
    }
    label
    has_bolt
    background_text
    parallax
    background_color
    background_color_fill
    links {
      ...LinkFragment
    }
  }
`

export default BannerComponent
