import styled from 'styled-components'
import { spacing, media } from '@tokens'

export const Links = styled.div`
  margin: ${spacing(3)} 0 -${spacing()};

  ${media.greaterThan('s')`
    margin-top: ${spacing(4)};
  `}

  .hero & {
    margin-top: ${spacing(5)};
  }
`
