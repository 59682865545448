import styled from 'styled-components'
import { MetaStyles } from '@styles/Typography'
import { tokens, spacing, media } from '@tokens'

export const SliderField = styled.div`
  margin-bottom: 2rem;

  ${media.lessThan('m')`
    padding-left: ${spacing(5)};
    padding-right: ${spacing(5)};
  `}

  ${media.lessThan('s')`
    padding-left: ${spacing(3.5)};
    padding-right: ${spacing(3.5)};
  `}

  .rc-slider-rail {
    background-color: ${tokens.brand('lightDarken')};
  }

  .rc-slider-track {
    background-color: ${tokens.brand('dark')};
  }

  .rc-slider-handle {
    background-color: ${tokens.brand('light')};
    border-color: ${tokens.brand('dark')};
    border-width: 4px;
    height: 1.75rem;
    width: 1.75rem;
    margin-top: calc(-0.875rem + 2px);
    transition: border-color 0.2s ease-out, background-color 0.2s ease-out, box-shadow 0.2s ease-out;

    &:hover {
      background-color: ${tokens.brand('dark')};
      border-color: ${tokens.brand('dark')};
    }

    &:focus {
      background-color: ${tokens.brand('dark')};
      border-color: ${tokens.brand('dark')};
      box-shadow: none;
    }

    &:active {
      /* background-color: ${tokens.brand('light')}; */
      border-color: ${tokens.brand('dark')};
      box-shadow: 0 0 5px 1px ${tokens.brand('darkAlt2')};
    }

    .value {
      ${MetaStyles}
      background: transparent;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      margin: 0.5rem 0 0;
      padding: 0 0.5rem;
      text-align: center;
      white-space: nowrap;
    }
  }
`
