import React from 'react'
import PropTypes from 'prop-types'
import { Link, formatLinkField } from '@partials/Link'
import Icon from '@partials/Icon'
import { hasIcon } from '@utils/components'
import BoltSvg from '@svgs/BoltSvg'
import * as S from '@styles/components/List'

const ListItem = ({
  label,
  content,
  icon,
  link,
  className,
  listItem,
  hasBolt,
}) => {
  return (
    <S.ListItem className={className}>
      {hasBolt && (
        <div className="bolt-container">
          <BoltSvg />
        </div>
      )}

      {hasIcon(listItem) && (
        <div className="icon">
          <Icon {...listItem} />
        </div>
      )}
      <div className="inner">
        {link && label && (
          <Link {...formatLinkField(link)} className="list__item__label">
            {label}
          </Link>
        )}
        {!link && label && <span className="list__item__label">{label}</span>}
        {content && (
          <div
            className="list__item__content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </div>
    </S.ListItem>
  )
}

ListItem.propTypes = {
  label: PropTypes.string,
  content: PropTypes.string,
  link: PropTypes.object,
}

export default ListItem
