import { useStaticQuery, graphql } from 'gatsby'

// Fetch option pages
export const useFooter = () => {
  const { wordpressAcfOptions } = useStaticQuery(
    graphql`
      query FooterContent {
        wordpressAcfOptions(wordpress_id: {eq: "acf"}) {
          wordpress_id
          options {
            footer {
              text_content {
                heading
                content
              }
              links {
                link_type
                link_style
                link_text
                link_to
              }
            }
          }
        }
      }
    `
  )
  return wordpressAcfOptions.options.footer
}

export default useFooter
