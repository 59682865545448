import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Links from '@partials/Links'
import { H5 } from '@styles/Typography'
import * as S from '@styles/components/Accordion'

class AccordionItem extends Component {
  static propTypes = {
    heading: PropTypes.string,
    content: PropTypes.string,
    links: PropTypes.array,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    index: PropTypes.number,
  }

  constructor(props) {
    super(props)

    this.ref = React.createRef()
    this.timeline = new TimelineLite({
      reversed: true,
      paused: true,
    })
    this.toggleTimeline = new TimelineLite({
      paused: true,
    })

    const open = false
    this.state = { open }
  }

  componentDidMount() {
    this.timeline.from(this.ref.current.querySelector('.body'), 0.5, {
      className: '+=is-closed',
    })
    this.toggleTimeline
      .to(
        this.ref.current.querySelector('.path-top'),
        0.3,
        { yPercent: -92, scaleY: 0.3 },
        'start'
      )
      .to(
        this.ref.current.querySelector('.path-top-2'),
        0.3,
        { yPercent: -14, scaleY: 0.3 },
        'start'
      )
      .to(
        this.ref.current.querySelector('.path-bottom'),
        0.3,
        { yPercent: 160, scaleY: 0.3 },
        'start'
      )
      .to(
        this.ref.current.querySelector('.path-bottom-2'),
        0.3,
        { yPercent: 73, scaleY: 0.3 },
        'start'
      )
      .to(
        this.ref.current.querySelectorAll('.path-hide'),
        0.2,
        { opacity: 0 },
        'start+0.2'
      )
      .to(
        this.ref.current.querySelector('.path-left'),
        0.5,
        { scaleX: 2, scaleY: 1.2, rotation: 1.5 },
        'start'
      )
      .to(
        this.ref.current.querySelector('.path-right'),
        0.5,
        {
          xPercent: -120,
          yPercent: -30,
          scaleX: 2,
          scaleY: 1.2,
          rotation: 1.5,
        },
        'start'
      )
  }

  // Activate GSAP timeline on update
  componentDidUpdate() {
    const { isActive } = this.props

    if (isActive && !this.state.open) {
      this.timeline.play()
      this.toggleTimeline.play()
      this.setState({ open: true })
    } else if (!isActive && this.state.open) {
      this.timeline.reverse()
      this.toggleTimeline.reverse()
      this.setState({ open: false })
    }
  }

  // Pass click event to parent (Accordion) for state & props distribution
  onClick = () => {
    this.props.onClick(this.props.index)
  }

  // Accessibllity - Trigger accordion open if user presses enter while focused
  onKeyDown = () => {
    if (event.keyCode === 13) {
      this.props.onClick(this.props.index)
    }
  }

  // Destroy timeline on component unmount
  componentWillUnmount() {
    this.timeline.kill()
    this.toggleTimeline.kill()
  }

  render() {
    const {
      onClick,
      onKeyDown,
      props: { heading, content, links, isActive },
    } = this

    return (
      <S.AccordionItem
        active={isActive}
        ref={this.ref}
        aria-expanded={isActive}
      >
        <div>
          <div
            className="header"
            onClick={onClick}
            tabIndex={0}
            onKeyDown={onKeyDown}
          >
            <div className="toggle">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.6 27.4">
                <defs>
                  <mask
                    id="a"
                    x="0"
                    y="7.89"
                    width="15"
                    height="11.85"
                    maskUnits="userSpaceOnUse"
                  >
                    <path
                      d="M6.05 12.12a1.79 1.79 0 0 0-1.88 1.69 1.79 1.79 0 0 0 1.88 1.69h3.12a1.67 1.67 0 0 0 1.66-1.69 1.68 1.68 0 0 0-1.66-1.69"
                      fill="#fff"
                      fillRule="evenodd"
                    />
                  </mask>
                  <mask
                    id="b"
                    x="12.49"
                    y="8.07"
                    width="14.12"
                    height="11.85"
                    maskUnits="userSpaceOnUse"
                  >
                    <path
                      d="M18 12.31A1.63 1.63 0 0 0 16.41 14 1.63 1.63 0 0 0 18 15.69h2.93A1.73 1.73 0 0 0 22.68 14a1.73 1.73 0 0 0-1.77-1.69H18z"
                      fill="#fff"
                      fillRule="evenodd"
                    />
                  </mask>
                  <mask
                    id="c"
                    x="7.21"
                    y="13.29"
                    width="11.85"
                    height="14.12"
                    maskUnits="userSpaceOnUse"
                  >
                    <path
                      d="M11.44 18.78v2.93a1.73 1.73 0 0 0 1.7 1.77 1.73 1.73 0 0 0 1.69-1.77v-2.93a1.63 1.63 0 0 0-1.69-1.57 1.64 1.64 0 0 0-1.7 1.57z"
                      fill="#fff"
                      fillRule="evenodd"
                    />
                  </mask>
                  <mask
                    id="d"
                    x="7.21"
                    y="0"
                    width="11.85"
                    height="15"
                    maskUnits="userSpaceOnUse"
                  >
                    <path
                      d="M11.44 6.05v3.12a1.68 1.68 0 0 0 1.69 1.66 1.68 1.68 0 0 0 1.7-1.66V6.05a1.79 1.79 0 0 0-1.7-1.88 1.79 1.79 0 0 0-1.69 1.88z"
                      fill="#fff"
                      fillRule="evenodd"
                    />
                  </mask>
                </defs>
                <path
                  d="M5.71 11.87A1.61 1.61 0 0 0 4 13.37a1.61 1.61 0 0 0 1.7 1.5h2.8a1.5 1.5 0 0 0 0-3z"
                  fill="#1a1b1b"
                  fillRule="evenodd"
                  className="path-left"
                />
                <g mask="url(#a)" className="path-left-2">
                  <path
                    d="M15 13.81a5.88 5.88 0 0 1-5.83 5.93H5.83a5.93 5.93 0 0 1 0-11.85h3.34A5.88 5.88 0 0 1 15 13.81"
                    fill="#1a1b1b"
                    fillRule="evenodd"
                  />
                </g>
                <path
                  d="M21.31 15.87a1.61 1.61 0 0 0 1.7-1.5 1.61 1.61 0 0 0-1.7-1.5h-2.8a1.5 1.5 0 0 0 0 3z"
                  fill="#1a1b1b"
                  fillRule="evenodd"
                  className="path-right"
                />
                <g mask="url(#b)" className="path-right-2">
                  <path
                    fill="#1a1b1b"
                    fillRule="evenodd"
                    d="M12.49 19.93H26.6V8.07H12.49v11.86z"
                  />
                </g>
                <path
                  d="M15 22.17v-2.8a1.5 1.5 0 0 0-3 0v2.8a1.61 1.61 0 0 0 1.5 1.7 1.61 1.61 0 0 0 1.5-1.7"
                  fill="#1a1b1b"
                  fillRule="evenodd"
                  className="path-top path-hide"
                />
                <g mask="url(#c)" className="path-top-2 path-hide">
                  <path
                    fill="#1a1b1b"
                    fillRule="evenodd"
                    d="M7.21 27.4h11.85V13.29H7.21V27.4z"
                  />
                </g>
                <path
                  d="M15 5.56a1.6 1.6 0 0 0-1.5-1.69A1.6 1.6 0 0 0 12 5.56v2.81a1.5 1.5 0 0 0 3 0z"
                  fill="#1a1b1b"
                  fillRule="evenodd"
                  className="path-bottom path-hide"
                />
                <g mask="url(#d)" className="path-bottom-2 path-hide">
                  <path
                    fill="#1a1b1b"
                    fillRule="evenodd"
                    d="M7.21 15h11.85V0H7.21v15z"
                  />
                </g>
              </svg>
            </div>
            <span>{heading}</span>
          </div>
          <S.AccordionItemBody className="body">
            <S.AccordionItemInner>
              <div dangerouslySetInnerHTML={{ __html: content }} />
              {links && <Links isVisible={isActive} links={links} />}
            </S.AccordionItemInner>
          </S.AccordionItemBody>
        </div>
      </S.AccordionItem>
    )
  }
}

export default AccordionItem
