import styled from 'styled-components'
import styledMap from 'styled-map'
import { Slider } from '@styles/components/Slider'
import { getBoxSpacingForProperty } from '@tokens/spacing'
import { BaseType, BaseTypeSizes, Heading } from '@styles/Typography'
import { tokens, spacing, box, media } from '@tokens'

export const TestimonialsComponent = styled(Slider)`
  position: relative;
  padding: 0 0 ${spacing(5)} !important;
  overflow: hidden;

  ${media.lessThan('730px')`
    ${box({
      spacing: 'right',
      multiplier: 2,
    })}
  `}

  ${media.lessThan('s')`
    ${box({
      spacing: 'vertical',
      multiplier: 2,
    })}
  `}

  > .inner {
    ${box({
      spacing: 'vertical',
      multiplier: 0.5
    })}
    ${box({
      spacing: 'horizontal',
    })}
    padding-left: 0 !important;
    max-width: none;
  }

  /*
      Stretch the background with absolute positioning
      to match the padding of the component. If you set a max-width,
      margins left/right to auto and set a value to left/right.
      It will center align an absolutely positioned element.
   */
  .background-container {
    ${box({
      spacing: 'vertical',
    })}
    position: absolute;
    top: 0;
    bottom: ${spacing(5)};
    ${getBoxSpacingForProperty('x', {
      property: 'right',
      multiplier: 1,
    })};
    ${getBoxSpacingForProperty('x', {
      property: 'left',
      multiplier: 1,
    })};
    z-index: ${tokens.z('base')};
    max-width: ${tokens.get('maxWidth.vastSmall')};
    margin-right: auto;
    margin-left: auto;

    ${media.greaterThan('xl')`
      max-width: ${tokens.get('maxWidth.vast')};
    `}

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      background: ${tokens.brand('dark')};
    }

    /*
      Stretch the pseudo element background off canvas to the left.
      Minus 2 pixel to remove rounding gap between the elements.
    */
    &::before {
      right: calc(100% - 2px);
      left: -100vw;
    }

    &::after {
      left: 0;
      right: 0;
      border-top-right-radius: ${spacing(1)};
      border-bottom-right-radius: ${spacing(1)};
    }

    .bolt,
    .bolt-container {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .bolt-container {
      overflow: hidden;
      width: 51%;
      padding-bottom: 10%;
      z-index: ${tokens.z('aboveContent')};
      opacity: 0.12;
      transform: translate3d(20%, -15%, 0);
    }

    .bolt {
      width: 100%;
      fill: ${tokens.brand('light')};
    }
  }

  .texture {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    left: -100vw;
    z-index: ${tokens.z('under')};
    padding-bottom: ${spacing(3)};
    background-position: bottom right+10px;
    background-repeat: no-repeat;
  }

  .swiper-container {
    margin: 0;
    max-width: none;
    overflow: visible;
  }

  .swiper-wrapper {
    display: flex;
    flex-flow: row;
    align-items: stretch;
  }

  .swiper-slide {
    height: auto;
  }

  .pagination {
    position: absolute;
    top: 100%;
    right: 0;
  }
`

export const Testimonial = styled.div`
  ${box({
    spacing: 'left',
  })};
  height: 100%;
  color: ${tokens.brand('light')};

  > .inner {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    margin-left: auto;
    margin-right: auto;

    ${media.greaterThan('s')`
      max-width: ${tokens.get('maxWidth.narrow')};
    `}

    ${media.greaterThan('m')`
      max-width: ${tokens.get('maxWidth.base')};
    `}

    ${media.greaterThan('xl')`
      max-width: ${tokens.get('maxWidth.wide')};
    `}

    > * {
      max-width: ${tokens.get('maxWidth.narrow')};
    }
  }

  .content {
    font-style: italic;
    font-size: 1.125rem;
    margin: 0;

    ${media.greaterThan('s')`
      font-size:  1.25rem;
    `}

    ${media.greaterThan('m')`
      font-size:  1.375rem;
    `}

    ${media.lessThan('m')`
      ${box({
        spacing: 'right',
      })};
    `}
  }

  .author {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: ${spacing(5)};
    font-style: normal;
  }

  .author-image {
    height: ${spacing(10)};
    width: ${spacing(10)};
    margin-right: ${spacing(3)};
  }

  .name,
  .company {
    display: block;
  }

  .name {
    ${Heading}
    ${BaseTypeSizes}
    margin-bottom: 0;
    letter-spacing: ${tokens.get('type.letterSpacing.large')};
    text-transform: uppercase;
  }

  .company {
    ${BaseType};
    font-size: 0.875rem;

    ${media.greaterThan('m')`
      font-size: 1rem;
    `}
  }

  picture {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;

    img {
      border-radius: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      z-index: ${tokens.z('content')};
    }

    .swiper-lazy {
      opacity: 0;
      transition: opacity 500ms ease 0s;
      z-index: ${tokens.z('aboveContent')};

      &.swiper-lazy-loaded {
        opacity: 1;
      }
    }
  }
`
