import React, { Component } from 'react'
import ScrollMagic from 'ScrollMagic'
import 'scrollmagic.gsap'

class ToolsSvg extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.timelines = {}
    this.props = props
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller()
    this.targets = {
      shine: this.ref.current.querySelectorAll('.shine'),
      shineWrap: this.ref.current.querySelectorAll('.shine-wrap'),
      wrench: this.ref.current.querySelector('.wrench'),
      screwDriver: this.ref.current.querySelector('.screw-driver'),
    }

    this.timelines.scroll = new TimelineMax().staggerFromTo(
      [this.targets.wrench, this.targets.screwDriver, this.targets.shineWrap],
      0.6,
      {
        scale: 0,
        transformOrigin: '50% 50%',
      },
      {
        scale: 1,
        ease: Back.easeOut.config(1.7),
      },
      0.3
    )

    this.timelines.loop = new TimelineMax({
      paused: true,
      repeat: -1,
      yoyo: true,
    }).staggerFromTo(
      this.targets.shine,
      1,
      {
        scale: 0.8,
        transformOrigin: '50% 50%',
      },
      {
        scale: 1.1,
        ease: Power0.easeNone,
      },
      0.5
    )

    this.scenes = [
      new ScrollMagic.Scene({
        triggerElement: this.ref.current,
        triggerHook: 0.85,
        duration: 0,
      })
        .setTween(this.timelines.scroll)
        .on('start end', e => {
          const direction = e.target.controller().info('scrollDirection')
          // TODO Add helper function
          if (direction === 'FORWARD' || direction === 'PAUSED') {
            this.timelines.loop.play()
          } else {
            this.timelines.loop.pause()
          }
        })
        .addTo(this.controller),
    ]
  }

  componentWillUnmount() {
    Object.keys(this.timelines).forEach(key => {
      this.timelines[key].kill()
    })
    this.scenes.forEach(scene => {
      scene.destroy(true)
    })
    this.scenes = null
  }

  render() {
    return (
      <svg
        className={this.className}
        ref={this.ref}
        {...this.props}
        viewBox="0 0 156 142"
        style={{ overflow: 'visible' }}
      >
        <g className="shine-wrap">
          <g className="shine">
            <path d="M104 11c-1.1 0-2-1-2-2.2V2.2c0-1.2.9-2.2 2-2.2s2 1 2 2.2v6.5c0 .6-.2 1.2-.6 1.6-.4.5-.9.7-1.4.7z" />
            <path d="M104 27c-1.1 0-2-1-2-2.2v-6.5c0-1.2.9-2.2 2-2.2s2 1 2 2.2v6.5c0 1.2-.9 2.2-2 2.2zM114.8 15h-6.5c-1.2 0-2.2-.9-2.2-2s1-2 2.2-2h6.5c1.2 0 2.2.9 2.2 2s-1 2-2.2 2zM98.8 15h-6.5c-1.3 0-2.3-.9-2.3-2s1-2 2.2-2h6.5c1.2 0 2.2.9 2.2 2s-.9 2-2.1 2z" />
          </g>
        </g>
        <g className="shine-wrap">
          <g className="shine">
            <path d="M57 126c-1.1 0-2-1-2-2.2v-6.5c0-1.2.9-2.2 2-2.2s2 1 2 2.2v6.5c0 1.2-.9 2.2-2 2.2zM57 142c-1.1 0-2-1-2-2.2v-6.6c0-1.2.9-2.2 2-2.2s2 1 2 2.2v6.5c0 .6-.2 1.2-.6 1.6-.4.5-.9.7-1.4.7zM68.8 131h-6.5c-1.2 0-2.2-.9-2.2-2s1-2 2.2-2h6.5c1.2 0 2.2.9 2.2 2s-1 2-2.2 2zM52.8 131h-6.5c-1.2 0-2.2-.9-2.2-2s1-2 2.2-2h6.5c1.2 0 2.2.9 2.2 2s-1 2-2.2 2z" />
          </g>
        </g>
        <path
          className="screw-driver"
          d="M154.1 35.8l-19.5-5.6c-.9-.3-1.9 0-2.5.6l-4.5 4.3-50.2-.4c-.4-.9-.9-1.6-1.6-2.3-1.5-1.5-3.6-2.4-5.8-2.4H65.3c-1.2-4.7-5.4-8-10.3-8H10.6C4.7 22 0 26.8 0 32.6v16.3c0 5.9 4.7 10.6 10.6 10.6H55c3.5 0 6.8-1.8 8.8-4.7H70c3.2 0 6-1.8 7.4-4.7h50.3l3.6 3.9c.5.5 1.2.8 1.9.8.3 0 .5 0 .8-.1l20-6.3c1.1-.3 1.8-1.3 1.9-2.5l.1-7.5c0-1.2-.8-2.3-1.9-2.6zM55.8 49.2H9.1c-1.5 0-2.6-1.2-2.6-2.7s1.2-2.7 2.6-2.7h46.6c1.5 0 2.6 1.2 2.6 2.7s-1.1 2.7-2.5 2.7zm0-12.4H9.1c-1.5 0-2.6-1.2-2.6-2.7s1.2-2.7 2.6-2.7h46.6c1.5 0 2.6 1.2 2.6 2.7s-1.1 2.7-2.5 2.7zm17 9.9c0 1.6-1.3 2.9-2.9 2.9h-4.3V35.4h4.3c.8 0 1.5.3 2 .8s.8 1.3.8 2l.1 8.5zm77.8-2.8l-16.7 5.3-3.3-3.5c-.5-.5-1.2-.8-1.9-.8H78.1V40l50.5.4c.7 0 1.3-.3 1.8-.7l4.2-4 16.1 4.6-.1 3.6z"
        />
        <path
          className="wrench"
          d="M3.3 86.3h17.5c2.4 0 4.4 2 4.3 4.5v7.1c0 1.2-.4 2.3-1.2 3.2s-1.9 1.3-3.1 1.4H9.7l-6.3-.1c-.8 0-1.6.4-2.1 1 0 .2-.1.3-.1.5 0 .4.1.8.3 1.2 1.1 3.4 3.9 5.9 5.3 7.3 11.6 11 31.4 8.2 37.7-7.1h58.8l3.8-.2c6.8 15.6 29.6 18.4 41.5 7.2 1.5-1.4 2.8-2.9 3.9-4.6.5-.8 1-1.7 1.5-2.5.3-.5.3-1.1.2-1.6-.5-.7-1.2-1.1-2-1.1h-18.5c-2.4 0-4.4-2-4.3-4.5v-7.1c0-2.4 1.9-4.4 4.3-4.5h18.4c.7 0 1.4-.3 1.8-.8.5-.5.7-1.2.7-1.9s-.2-1.3-.7-1.8v-.1c-1-1.9-2.2-3.7-3.6-5.3-10.6-12.1-31.2-11-41 1.5-1.1 1.4-5.5 8.3-5.5 8.3H48c-.3 0-1.6-4.8-1.9-5.4-1-2.1-2.3-4-3.9-5.6-9.9-10.5-27.8-9.5-37.1 1.2-1.4 1.6-2.6 3.4-3.6 5.3v.1c-.5.4-.7 1.1-.7 1.7 0 .7.2 1.4.7 1.9.4.5 1.1.8 1.8.8z"
        />
      </svg>
    )
  }
}

export default ToolsSvg
