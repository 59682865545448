import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { H3 } from '@styles/Typography'
import * as S from '@styles/components/TextWithImages'
import ScrollMagic from 'scrollmagic'
import 'scrollmagic.gsap'
import Links from '@partials/Links'
import { getComponentClasses } from '@utils/components'
import ComponentContent from '@partials/ComponentContent'
import SectionLabel from '@partials/SectionLabel'
import Image from '@partials/Image'

class TextWithImagesComponent extends Component {
  static propTypes = {
    component: PropTypes.object.isRequired,
    className: PropTypes.string,
  }

  constructor(props) {
    super(props)

    const { component, className } = this.props
    this.classes = getComponentClasses(component, [
      'text-with-images',
      className,
    ])
    this.timelines = []
    this.scenes = []
    this.component = component
    this.ref = React.createRef()
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller()
    this.targets = {
      images: Array.from(this.ref.current.querySelectorAll('.image')).reverse(),
      imageContainer: this.ref.current.querySelector('.images'),
    }

    this.targets.images.forEach((image, index) => {
      let xTo = 50 * (index % 2 === 0 ? -1 : 1)
      let yTo = 0
      let duration = '30%'

      switch (this.targets.images.length) {
        case 3:
          xTo = index === 0 ? xTo * -1 : xTo
          break
        case 1:
          yTo = xTo * -1
          xTo = 0
          duration = '60%'
          break
      }

      this.timelines[index] = new TimelineMax().fromTo(
        image.children[0],
        1,
        {
          autoAlpha: 0,
          x: xTo,
          y: yTo,
          transformOrigin: '50% 0%',
        },
        {
          autoAlpha: 1,
          x: 0,
          y: 0,
          ease: Power1.easeOut,
        }
      )

      this.scenes[index] = new ScrollMagic.Scene({
        triggerElement: image,
        triggerHook: 0.75,
        duration: duration,
      })
        .setTween(this.timelines[index])
        .addTo(this.controller)
    })
  }

  componentWillUnmount() {
    this.timelines.forEach(timeline => {
      timeline.kill()
    })
    this.scenes.forEach(scene => {
      scene.destroy(true)
    })
    this.timelines = null
    this.scenes = null
  }

  render() {
    const {
      background_color,
      background_color_fill,
      text_content,
      links,
      text_alignment,
      text_content_alignment,
      images,
      label,
    } = this.component
    return (
      <S.TextWithImages
        ref={this.ref}
        className={this.classes}
        bg={background_color}
        bgFill={background_color_fill}
        textAlignment={text_alignment}
        contentAlignment={text_content_alignment}
        imageCount={images.length}
      >
        {label && (
          <SectionLabel
            label={label}
            bg={background_color}
            bgFill={background_color_fill}
          />
        )}
        <div className="inner">
          <div className="content">
            <ComponentContent {...text_content} links={links} />
          </div>
        </div>
        <div className="images">
          {images &&
            images.map(image => {
              return (
                <Image
                  key={image.localFile.publicURL}
                  source={image}
                  className="image"
                />
              )
            })}
        </div>
      </S.TextWithImages>
    )
  }
}

export const query = graphql`
  fragment TextWithImagesFragment on WordPressAcf_text_with_images {
    text_content {
      ...ComponentContentFragment
    }
    links {
      ...LinkFragment
    }
    label
    images {
      alt_text
      localFile {
        publicURL
        childImageSharp {
          # TODO: Alter sizing
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    text_content_alignment
    text_alignment
    background_color
    background_color_fill
  }
`

export default TextWithImagesComponent
