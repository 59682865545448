import styled from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media } from '@tokens'

export const HouseSvg = styled.svg`
  display: block;
  width: 100%;
  max-width: 24rem;
  position: relative;
`

export const FooterScene = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;

  .house-wrap {
    display: inline-block;
    position: relative;
    margin-left: auto;
    margin-right: ${spacing(1)};

    ${media.greaterThan('s')`
      margin-right: ${spacing(8)};
    `}


    &::before,
    &::after {
      position: absolute;
      bottom: 7.25%;
      z-index: ${tokens.z('aboveContent')};
      content: '';
      display: block;
      width: 9999px;
      height: 3px;
      background: ${tokens.brand('light')};
    }

    &::before {
      right: 80%;
    }

    &::after {
      left: 76.5%;
    }
  }
`

