import styled from 'styled-components'
import { ListBase, ListItemBase } from '../objects/List'
import { Component } from '@styles/components/Component'
import { tokens, spacing, media } from '@tokens'

export const AccordionBlockComponent = styled(Component)`
  > .inner {
    max-width: ${tokens.get('maxWidth.mid')};

    > .content {
      margin: 0;
    }
  }
`

export const Accordion = styled.div`
  ${ListBase};
  text-align: left;
`

export const AccordionItem = styled.div`
  ${ListItemBase};

  > div {
    width: 100%;
  }

  .header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: none;
    padding: ${spacing(3)} 0;
    width: 100%;

    .toggle {
      flex-shrink: 0;
      height: 1.5rem;
      width: 1.5rem;
      margin-left: 0.5rem;
      order: 2;
      transform: scaleY(-1);
      transition: transform ease-in-out 0.3s;
    }
  }
`

export const AccordionItemBody = styled.div`
  color: ${tokens.color('grey', 'lightish')};
  overflow: hidden;
  transform: scale(1);
  margin: 0 -${spacing(2)};
  will-change: transform;

  &.is-closed {
    height: 0;
  }
`

export const AccordionItemInner = styled.div`
  border-top: ${tokens.color('border')} 2px solid;
  padding: ${spacing(5)} ${spacing(5)} ${spacing(4)};

  ${media.greaterThan('m')`
    padding: ${spacing(5)} ${spacing(13)} ${spacing(4)} ${spacing(7)};
  `}
`

export default Accordion
