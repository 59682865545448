import React, { useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import Image from '@partials/Image'
import PropTypes from 'prop-types'
import * as S from '@styles/components/Team'
import ComponentContent from '@partials/ComponentContent'
import useTeam from '@hooks/use-team'
import { H3 } from '@styles/Typography'
import { getComponentClasses } from '@utils/components'
import SectionLabel from '@partials/SectionLabel'
import ScrollMagic from 'ScrollMagic'
import 'scrollmagic.gsap'

const TeamComponent = ({ component, className = '' }) => {
  const classes = getComponentClasses(component, ['team', className])
  const team = useTeam()
  const {
    background_color,
    background_color_fill,
    text_content,
    links,
    label,
  } = component

  const ref = useRef()

  useEffect(() => {
    const controller = new ScrollMagic.Controller()
    let scenes = []
    let timelines = []
    const targets = {
      images: ref.current.querySelectorAll('.image'),
    }

    targets.images.forEach((image, index) => {
      timelines[index] = new TimelineMax().fromTo(
        image,
        0.4,
        {
          autoAlpha: 0,
          x: 50,
        },
        {
          autoAlpha: 1,
          x: 0,
          ease: Power2.easeOut,
        }
      )
      scenes[index] = new ScrollMagic.Scene({
        triggerElement: targets.images[index],
        triggerHook: 0.75,
        duration: 0,
      })
        .setTween(timelines[index])
        .addTo(controller)
    })
    return () => {
      timelines.forEach(timeline => {
        timeline.kill()
      })
      scenes.forEach(scene => {
        scene.destroy(true)
      })
      scenes = null
      timelines = null
    }
  }, []) // Will only fire on mount due to the empty

  return (
    <S.Component
      className={classes}
      bg={background_color}
      bgFill={background_color_fill}
      ref={ref}
    >
      {label && (
        <SectionLabel
          label={label}
          bg={background_color}
          bgFill={background_color_fill}
        />
      )}
      <div className="inner">
        <div className="content">
          <div className="intro">
            <ComponentContent {...text_content} links={links} />
          </div>
        </div>

        <S.TeamList>
          {team.map(teamMember => (
            <TeamMember
              key={teamMember.node.wordpress_id}
              data={teamMember.node}
            />
          ))}
        </S.TeamList>
      </div>
    </S.Component>
  )
}

export const TeamMember = ({ data }) => {
  const {
    title: name,
    acf: { headshot, title },
  } = data

  return (
    <S.TeamMember>
      <Image source={headshot} aspectRatio={16 / 19} />
      <div className="content">
        <H3 className="heading">{name}</H3>
        {title && <h4 className="subheading">{title}</h4>}
      </div>
    </S.TeamMember>
  )
}

TeamComponent.propTypes = {
  component: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment TeamComponentFragment on WordPressAcf_team {
    text_content {
      ...ComponentContentFragment
    }
    label
    background_color
    background_color_fill
  }
`

export default TeamComponent
