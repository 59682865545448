import styled from 'styled-components'
import { tokens, media, box, spacing } from '@tokens'
import { Link } from '@styles/objects/Link'
import { ITCBold } from '@styles/Typography'
import { bgOverlap } from '@styles/components/Component'

export const Footer = styled.footer`
  ${bgOverlap}
  background-color: ${tokens.brand('dark')};

  .text {
    text-align: center;

    > .inner {
      > .content {
        margin: 0 auto;
        max-width: ${tokens.get('maxWidth.narrow')};
      }
    }
  }

  .footer-container {
    ${box({
      spacing: 'horizontal',
    })}
    color: ${tokens.brand('light')};

    ${media.greaterThan('m')`
      padding-left: ${spacing(5)};
      padding-right: ${spacing(5)};
    `}

    ${media.lessThan('m')`
      margin-top: ${spacing(4)};
    `}
  }

  .footer-container__inner {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: ${spacing(10)};
    padding-bottom: ${spacing(6)};

    ${media.greaterThan('s')`
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      padding-top: ${spacing(6)};
    `}

    ${media.greaterThan('l')`
      flex-flow: row;
    `}
  }

  .company,
  .footer__social {
    flex: 1 1 auto;

    ${media.greaterThan('m')`
      flex-basis: 33%;
    `}
  }

  .footer-nav {
    padding-bottom: ${spacing(3)};
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;

    ${media.greaterThan('s')`
      order: 0;
      flex: 1 1 100%;
      flex-flow: row;
      justify-content: center;
      align-items: center;
    `}

    ${media.greaterThan('l')`
      flex: 0 0 auto;
      order: 2;
      padding-bottom: 0;
    `}

    a {
      ${Link};
      display: block;
      color: ${tokens.brand('light')};
      padding: ${spacing(0.75)} 0;

      &::after {
        background-color: ${tokens.brand('light')};
      }

      &:first-child {
        margin-top: 0;
      }

      ${media.greaterThan('s')`
        margin-left: ${spacing(5)};

        &:first-child {
          margin-left: 0;
        }
    `}
    }
  }

  .company,
  .footer-nav a {
    ${ITCBold};
    font-size: 0.875rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  .company {
    ${media.greaterThan('l')`
      order: 1;
    `}

    ${media.lessThan('s')`
      order: 3;
    `}
  }

  .footer__social {
    ${media.greaterThan('l')`
      order: 3;
    `}

    ${media.lessThan('s')`
      margin-bottom: ${spacing(3)};
    `}
  }
`

export const FooterSVG = styled.img`
  display: none;

  ${media.greaterThan('s')`
    display: block;
    width: 100%;
    max-width: none;
  `}
`
