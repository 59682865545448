import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@partials/Icon'
import Links from '@partials/Links'
import { H3 } from '@styles/Typography'
import * as S from '@styles/components/Cards'
import { hasIcon } from '@utils/components'

const Card = ({ heading, content, links, className, ...card }) => {
  return (
    <S.Card className={className} hasIcon={hasIcon(card)}>
      {hasIcon(card) && (
        <div className="icon">
          <Icon {...card} />
        </div>
      )}
      <div className="content">
        {heading && <H3 className="card__heading">{heading}</H3>}
        {content && (
          <div className="body" dangerouslySetInnerHTML={{ __html: content }} />
        )}
        {links && <Links links={links} />}
      </div>
    </S.Card>
  )
}

Card.propTypes = {
  icon: PropTypes.object,
  heading: PropTypes.string,
  content: PropTypes.string,
  links: PropTypes.array,
}

export default Card
