import React from 'react'
// import ProjectContent from '@partials/ProjectContent'
import FlyoutContent from '@partials/FlyoutContent'
import NavDrawer from '@components/NavDrawer'

const Flyout = ({ postType, sid, toggleFlyout }) => {
  switch (postType) {
    // case 'project':
    //   return <ProjectContent sid={sid} />
    case 'flyout':
      return <FlyoutContent sid={sid} />
    default:
      return <NavDrawer toggleFlyout={toggleFlyout} />
  }
}

export default Flyout
