import ComputerDialSVG from '@svgs/ComputerDialSvg'
import CreditCardCartSvg from '@svgs/CreditCardCartSvg'
import EyeBrowserSvg from '@svgs/EyeBrowserSvg'
import TrophySvg from '@svgs/TrophySvg'
import LightbulbSvg from '@svgs/LightbulbSvg'
import CarComputerSvg from '@svgs/CarComputerSvg'
import ToolsSvg from '@svgs/ToolsSvg'
import BadgeSvg from '@svgs/BadgeSvg'

export const IconList = {
  ComputerDialSvg: ComputerDialSVG,
  CreditCardCartSvg: CreditCardCartSvg,
  EyeBrowserSvg: EyeBrowserSvg,
  TrophySvg: TrophySvg,
  LightbulbSvg: LightbulbSvg,
  CarComputerSvg: CarComputerSvg,
  ToolsSvg: ToolsSvg,
  BadgeSvg: BadgeSvg,
}
