import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TweenMax from 'TweenMax'
import ScrollMagic from 'ScrollMagic'
import 'scrollmagic.gsap'
import * as S from '@styles/components/BoltSvg'

class BoltSvg extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.timelines = {}
    this.props = props
    this.className = `bolt ${this.props.className || ''}`
  }

  componentDidMount() {
    const { direction } = this.props
    this.controller = new ScrollMagic.Controller()
    const xValue = direction === 'right' ? 50 : -50
    const yValue = direction === 'right' ? 10 : -10

    this.boltTimeline = new TimelineMax().fromTo(
      this.ref.current,
      0.4,
      {
        autoAlpha: 0,
        x: xValue,
        y: yValue,
      },
      {
        autoAlpha: 1,
        x: 0,
        y: 0,
        ease: Back.easeOut.config(1.7),
      }
    )

    this.scenes = [
      new ScrollMagic.Scene({
        triggerElement:
          this.ref.current.closest('.bolt-container') ||
          this.ref.current.closest('.component'),
        triggerHook: 0.85,
        duration: 0,
      })
        .setTween(this.boltTimeline)
        .addTo(this.controller),
    ]
  }

  componentWillUnmount() {
    Object.keys(this.timelines).forEach(timeline => {
      this.timelines[timeline].kill()
    })
    this.scenes.forEach(scene => {
      scene.destroy(true)
    })
    this.scenes = null
  }

  render() {
    return (
      <S.BoltSvg
        className={this.className}
        ref={this.ref}
        {...this.props}
        direction={this.props.direction}
        viewBox="0 0 228 37"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.393 1.87L61.9 15.394c.406.096.831-.048 1.098-.372.269-.326.337-.774.179-1.166L58.903 3.194a2.296 2.296 0 0 1 .344-2.39A2.214 2.214 0 0 1 61.51.07l95.02 23.28c.426.103.872-.053 1.142-.4.27-.35.32-.824.125-1.222l-3.18-6.242a2.324 2.324 0 0 1 .27-2.486 2.224 2.224 0 0 1 2.345-.748l70.026 20.409c.546.206.85.794.706 1.363a1.124 1.124 0 0 1-1.257.842l-54.486-12.504a1.12 1.12 0 0 0-1.156.436 1.16 1.16 0 0 0-.07 1.249l5.364 9.435c.5.79.475 1.809-.065 2.572a2.218 2.218 0 0 1-2.378.872L82.374 16.194a1.109 1.109 0 0 0-1.134.413 1.15 1.15 0 0 0-.103 1.222l3.198 6.292c.412.81.314 1.788-.251 2.497a2.23 2.23 0 0 1-2.356.76L.843 4.075a1.136 1.136 0 0 1-.807-1.38c.07-.292.253-.544.508-.698.255-.155.56-.2.85-.126"
          fillRule="nonzero"
        />
      </S.BoltSvg>
    )
  }
}

export default BoltSvg
