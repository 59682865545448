import { useStaticQuery, graphql } from 'gatsby'

// Fetch option pages
export const useCompanyDetails = () => {
  const { wordpressAcfOptions } = useStaticQuery(
    graphql`
      query AcfOptions {
        wordpressAcfOptions(wordpress_id: {eq: "acf"}) {
          wordpress_id
          options {
            email
            company_title
            social {
              instagram
              facebook
            }
          }
        }
      }
    `
  )
  return wordpressAcfOptions.options
}

export default useCompanyDetails
