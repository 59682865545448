import React, { Component } from 'react'
import ScrollMagic from 'ScrollMagic'
import 'scrollmagic.gsap'

class EyeBrowserSvg extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.timelines = {}
    this.props = props
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller()
    this.targets = {
      pupil: this.ref.current.querySelector('.pupil'),
      lid: this.ref.current.querySelector('.lid'),
      browser: this.ref.current.querySelector('.browser'),
      eye: this.ref.current.querySelector('.eye'),
    }

    this.timelines.scroll = new TimelineMax().staggerFromTo(
      [this.targets.browser, this.targets.eye],
      0.6,
      {
        scale: 0,
        transformOrigin: '50% 50%',
      },
      {
        scale: 1,
        ease: Back.easeOut.config(1.7),
      }
    )

    this.timelines.loop = new TimelineMax({
      paused: true,
      repeat: -1,
      yoyo: true,
    })
      .to(this.targets.pupil, 1.2, {
        x: 4,
        y: 2,
        transformOrigin: '50% 50%',
        delay: 0.2,
      })
      .to(this.targets.pupil, 0.5, {
        y: 0,
        transformOrigin: '50% 50%',
        delay: 0.5,
      })
      .to(this.targets.pupil, 1.2, {
        x: -4,
        y: 2,
        transformOrigin: '50% 50%',
        delay: 0.2,
      })

    this.scenes = [
      new ScrollMagic.Scene({
        triggerElement: this.ref.current,
        triggerHook: 0.85,
        duration: 0,
      })
        .setTween(this.timelines.scroll)
        .on('start end', e => {
          const direction = e.target.controller().info('scrollDirection')
          // TODO Add helper function
          if (direction === 'FORWARD' || direction === 'PAUSED') {
            this.timelines.loop.play()
          } else {
            this.timelines.loop.pause()
          }
        })
        .addTo(this.controller),
    ]
  }

  componentWillUnmount() {
    Object.keys(this.timelines).forEach(key => {
      this.timelines[key].kill()
    })
    this.scenes.forEach(scene => {
      scene.destroy(true)
    })
    this.scenes = null
  }

  render() {
    return (
      <svg
        className={this.className}
        ref={this.ref}
        {...this.props}
        viewBox="0 0 110.8 101.3"
        xmlns="http://www.w3.org/2000/svg"
        style={{ overflow: 'visible' }}
      >
        <g className="eye">
          <path d="M56.2 88.7c15.9 0 33.1-10.9 42.6-21.8.5-.7.5-1.6 0-2.3C89.1 53.4 72 42.7 56.2 42.7c-15.9 0-33.1 10.9-42.6 21.8-.5.7-.5 1.6 0 2.3 9.7 11.1 26.8 21.9 42.6 21.9zm39-23c-3 3-11.4 11.1-22.7 15.8 8.3-8.9 8.3-22.7 0-31.6 11 4.7 19.7 12.8 22.7 15.8zm-39-19.4c7.8 0 14.9 4.6 17.9 11.9 3 7.2 1.4 15.5-4.1 21.1-5.5 5.5-13.8 7.2-21.1 4.2S37 73.5 37 65.6c0-5.1 2-10 5.6-13.6s8.5-5.6 13.6-5.7zm-16.3 3.5c-8.3 8.9-8.3 22.7 0 31.6-11.1-4.7-19.7-12.8-22.7-15.8 2.9-2.9 11.4-11 22.7-15.8z" />
          <path
            className="pupil"
            d="M56.2 78.7c7.2 0 13-5.8 13-13s-5.8-13-13-13-13 5.8-13 13 5.8 13 13 13z"
          />
          <g className="lashes">
            <path d="M19.4 52.6c.3.3.7.5 1.1.5s.8-.2 1.1-.5c.6-.7.6-1.7 0-2.3l-5-5.7c-.3-.3-.6-.5-1.1-.5s-.8.2-1.1.5c-.6.7-.6 1.7 0 2.3l5 5.7zM29.6 44.2c.2.4.5.7.9.8s.8.1 1.2-.1c.8-.5 1-1.5.6-2.3l-3-5.6c-.2-.4-.5-.7-.9-.8-.4-.1-.8-.1-1.2.1-.8.5-1 1.5-.6 2.3l3 5.6zM42 39.7c.2.9 1.2 1.5 2.1 1.4 1-.2 1.6-1.1 1.5-2l-1.8-6.7c-.1-.4-.4-.8-.8-1.1-.4-.2-.9-.3-1.3-.1-.4.1-.8.4-1.1.7s-.3.9-.2 1.3l1.6 6.5zM56 40.1c.5 0 1-.2 1.3-.5s.5-.8.5-1.3V31c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8v7.3c0 .5.1 1 .5 1.3s.8.5 1.3.5zM66.7 41.1c.9.2 1.8-.3 2.1-1.2l1.8-6.7c.2-.9-.4-1.7-1.2-2-.9-.2-1.8.3-2.1 1.2L65.5 39c-.3.9.3 1.7 1.2 2.1zM79 44.9c.4.2.9.3 1.3.2.4-.1.8-.4 1-.8l3.1-5.6c.2-.4.3-.8.2-1.3-.1-.4-.4-.8-.8-1-.8-.4-1.8-.1-2.3.7l-3 5.6c-.4.7-.2 1.7.5 2.2zM90.6 52.6l5-5.7c.6-.7.6-1.7 0-2.3-.3-.3-.6-.5-1.1-.5-.4 0-.8.2-1.1.5l-5 5.7c-.6.7-.6 1.7 0 2.3.3.3.7.5 1.1.5s.8-.2 1.1-.5z" />
          </g>
        </g>
        <g className="browser">
          <path d="M48.8 15.1c1.3 0 2.4-1.1 2.4-2.4s-1.1-2.4-2.4-2.4-2.4 1.1-2.4 2.4c.1 1.3 1.1 2.3 2.4 2.4zM32 15.1c1.3 0 2.4-1.1 2.4-2.4 0-1.3-1.1-2.4-2.4-2.4-1.3 0-2.4 1.1-2.4 2.4 0 1.3 1.1 2.3 2.4 2.4z" />
          <path d="M104.6 0H6.4C2.9 0 .1 2.9 0 6.4V95c0 1.7.7 3.3 1.9 4.5s2.8 1.9 4.5 1.9h98c1.7 0 3.3-.7 4.5-1.9 1.2-1.2 1.9-2.8 1.9-4.5V6.4c0-1.7-.6-3.3-1.8-4.5-1.1-1.2-2.7-1.9-4.4-1.9zM48.8 7c3.1 0 5.6 2.5 5.6 5.6 0 3.1-2.5 5.6-5.6 5.6-1.5 0-2.9-.6-4-1.6-1.1-1.1-1.6-2.5-1.6-4 .1-3.1 2.6-5.6 5.6-5.6zM32 7c3.1 0 5.6 2.5 5.6 5.6 0 3.1-2.5 5.6-5.6 5.7-1.5 0-2.9-.6-4-1.6-1.1-1.1-1.6-2.5-1.6-4C26.4 9.6 28.9 7 32 7zM15.2 7c3.1 0 5.6 2.5 5.6 5.6 0 3.1-2.5 5.6-5.6 5.6-1.5.1-3-.5-4-1.6-1.1-1.1-1.6-2.5-1.6-4 0-3.1 2.5-5.6 5.6-5.6zm91.7 87.5c0 1.6-1.3 2.9-2.9 2.9H7c-.8 0-1.5-.3-2.1-.8-.5-.5-.9-1.3-.9-2.1V25.4h102.8v69.1z" />
          <path d="M15.2 15.1c1.3-.1 2.3-1.1 2.3-2.4s-1-2.3-2.3-2.4c-.6 0-1.3.2-1.7.7-.5.5-.7 1.1-.7 1.7 0 1.3 1.1 2.3 2.4 2.4z" />
        </g>
      </svg>
    )
  }
}

export default EyeBrowserSvg
