import React from 'react'
import PropTypes from 'prop-types'
import * as S from '@styles/components/Social'
import { ReactComponent as FacebookIcon } from '@icons/facebook.svg'
import { ReactComponent as InstagramIcon } from '@icons/instagram.svg'
import useCompanyDetails from '@hooks/use-company-details'

const SocialIcon = ({ platform }) => {
  switch (platform) {
    case 'facebook':
      return <FacebookIcon />
    case 'instagram':
      return <InstagramIcon />
    default:
      return false
  }
}

const Social = props => {
  const { social } = useCompanyDetails()

  return (
    <S.Social {...props}>
      {social &&
        Object.keys(social).map(platform => (
          <li key={platform}>
            <a
              href={social[platform]}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`Link to ${platform}`}
            >
              <SocialIcon platform={platform} />
            </a>
          </li>
        ))}
    </S.Social>
  )
}

export default Social
