import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens } from '@tokens'

export const BoltSvg = styled.svg`
  display: block;
  opacity: 0;
  ${styledMap('direction', {
  right: css`
    transform: translate3d(50, 10, 0);
  `,
  'left': css`
    transform: translate3d(-50, -10, 0);
  `,
  })}

  .dark-color {
    fill: ${tokens.brand('dark')};
  }
`
