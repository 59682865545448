import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media } from '@tokens'
import Component from '@styles/components/Component'

export const Slider = styled(Component)`
  .swiper-container {
    ${media.greaterThan('m')`
      max-width: ${tokens.get('maxWidth.base')};
    `}

    ${media.greaterThan('xl')`
      max-width: ${tokens.get('maxWidth.wide')};
    `}
  }

  ${styledMap('maxVisible', {
    auto: css`
      padding-left: 0 !important;
      padding-right: 0 !important;

      .swiper-container {
        ${'' /* TODO: Update height (responsive) */}
        height: 20rem;
        max-width: none;

        ${media.greaterThan('l')`
          height: 30rem
        `}

        ${media.greaterThan('xl')`
          height: 42rem
        `}
      }

      .swiper-slide {
        width: auto !important;

        &:first-child {
          margin-left: 20vw;
        }

        // Matches spaceBetween in swiper config
        &:last-child {
          margin-right: 16px;

          ${media.greaterThan('m')`
            margin-right: 32px;
          `}

          ${media.greaterThan('l')`
            margin-right: 48px;
          `}

          ${media.greaterThan('xl')`
            margin-right: 64px;
          `}
        }
      }
    `,
    default: '',
  })};

  .navigation {
    cursor: pointer;
    fill: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transition: fill 0.4s ease;
    line-height: 0;
    z-index: ${tokens.z('content')};

    .icon {
      height: 4rem;
      width: 4rem;
    }

    &.prev {
      left: 0;

      .icon {
        transform: scaleX(-1);
      }
    }

    &.next {
      right: 0;
    }

    &:hover {
      fill: ${tokens.color('link')};
    }

    &.swiper-button-disabled {
      cursor: default;
      fill: rgba(255, 255, 255, 0.2);

      &:hover {
        fill: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: ${spacing(2)};

    .bullet {
      background-color: transparent;
      cursor: pointer;
      display: block;
      border-radius: 100%;
      border: 0.125rem solid ${tokens.brand('dark')};
      height: 0.8125rem;
      width: 0.8125rem;
      margin: 0 0 0 ${spacing(1.5)};
      transition: background-color 0.3s ease, border-width 0.3s ease;
      outline: none !important;

      &:hover,
      &:focus {
        background-color: ${tokens.brand('darkAlt2')};
      }

      &.is-active {
        border-width: 0.40625rem;
      }
    }
  }

  &.background-color-dark .pagination .bullet {
    border-color: ${tokens.brand('light')};
    background-color: ${tokens.brand('dark')};

    &:hover,
    &:focus,
    &.is-active {
      background-color: ${tokens.brand('light')};
    }
  }
`

export const Slide = styled.div`
  height: ${styledMap('maxVisible', {
    auto: '100%',
    default: 0,
  })};
  padding-bottom: ${styledMap('maxVisible', {
    1: `${(8 / 15) * 100}%`,
    2: `${(3 / 4) * 100}%`,
    3: `${(3 / 4) * 100}%`,
    auto: '0',
    default: '100%',
  })};
  pointer-events: none;
  position: relative;

  picture {
    ${styledMap('maxVisible', {
      auto: css`
        display: inline-block;
        position: relative;
        height: 100%;
        width: auto;
      `,
      default: ''
    })};
  }

  img {
    z-index: ${tokens.z('content')};

    ${styledMap('maxVisible', {
      auto: css`
        position: relative;
        height: 100%;
        width: auto;

        &.trace {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      `,
      default: css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      `
    })};
  }

  .swiper-lazy {
    opacity: 0;
    transition: opacity 500ms ease 0s;
    z-index: ${tokens.z('aboveContent')};

    &.swiper-lazy-loaded {
      opacity: 1;
    }
  }
`
