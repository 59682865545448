import React from 'react'
import * as S from '@styles/components/HeroSvg'
import { tokens } from '@tokens'

const Cloud = React.forwardRef((props, ref) => (
  <S.CloudWrap ref={ref}>
    <S.Cloud
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1256.6 607.7"
      {...props}
    >
      <path
        className="light-color"
        d="M1224.6 351.4c-5.3-1.3-9.8-4.9-12.2-9.8-8.6-17.4-28.3-26.4-47.1-21.4-5.5 1.5-11.3-.5-14.8-5-27.7-37.5-77.5-51.1-120.4-32.8-5.1 2.2-11 1.5-15.6-1.7-10-7.2-22.1-11.1-34.4-11.1h-.2c-5.9 0-11.4-3.3-14.2-8.5-10.2-19.3-30.3-31.4-52.1-31.4h-1.1c-5.3.1-9.9-3.5-11.1-8.6-7.2-30.9-28.7-56.6-57.8-69.3s-62.6-10.8-90.1 5c-4.1 2.3-9 2.8-13.4 1.3-4.5-1.5-8-4.9-9.9-9.2-17.7-42.9-55-74.7-100.1-85.5s-92.7.9-127.9 31.2c-5.7 5-14.2 5.1-20 .1-10.7-9.5-24.5-14.7-38.8-14.7-3.4.1-6.9.6-10.2 1.3-6.5 1.3-13.2-1.8-16.3-7.6-11.6-21.4-45.7-69-126.8-73.5-37.1-2-71 14-96.6 37.1-4.6 4.1-10.9 5.8-16.9 4.4-64.3-14.7-130.2 16-160.5 74.5s-17.1 130.1 32 174c6.2 5.7 8.1 14.7 4.7 22.3-19.6 44.7-15.4 96.2 11.3 137.1s72.2 65.5 121 65.6h2.8c7.1-.1 13.6 3.9 16.7 10.2 33.1 64.8 109.1 95 177.7 70.4 4.6-1.6 9.7-1.3 14.1 1 46.2 23.4 102.5 7.9 130.3-35.7 8.2-13 24-19.1 38.8-15 41.4 11.6 85.6-4.3 110-39.6 5.9-8.5 17.4-10.8 26.1-5.2 25.2 15.9 57.8 13.9 80.9-5.1 10.6-8.7 25.9-9 36.8-.6 26 19.9 59.3 27.8 91.5 21.6s60.2-25.9 76.9-54c4.4-7.2 13.7-9.6 21-5.4 26.8 14.8 60.1 10.4 82.2-10.8 3.6-3.3 9.1-3.3 12.8-.1 12.7 11.5 29.5 17.4 46.6 16.4s33-9 44.2-21.9c3.9-4.5 9.8-6.8 15.8-6.3 2.9.3 5.8.2 8.7-.1 20.4-2.3 36.2-18.9 37.6-39.4 1.4-20.5-12-39.1-31.9-44.1l-.1-.1z"
      />
    </S.Cloud>
  </S.CloudWrap>
))

export default Cloud
