import React from 'react'
import Image from '@partials/Image'
import { IconList } from '@partials/IconList'

const Icon = ({
  media_type: mediaType,
  dynamic_svg: dynamicSvg,
  svg,
  image,
  className,
  ...props
}) => {
  switch (mediaType) {
    case 'image': {
      if (!image) {
        return null
      }

      const {
        alt,
        localFile: { childImageSharp, extension, publicURL, ...other },
      } = image
      if (!childImageSharp || extension === 'svg') {
        return (
          <img
            className={className}
            src={publicURL}
            {...other}
            alt={alt || image.alt_text}
          />
        )
      }

      return <Image source={image} className={className} {...other} />
    }

    case 'svg':
      return <figure dangerouslySetInnerHTML={{ __html: svg }} />
    case 'dynamic_svg': {
      const DynamicSvgComponent = IconList[dynamicSvg]
      if (DynamicSvgComponent) {
        return <DynamicSvgComponent />
      }
    }
    default:
      return null
  }
}

export default Icon
