import styled from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media } from '@tokens'


export const HeroSvg = styled.div`
  --breakpoint: xs;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: ${tokens.z('base')};
  display: block;
  height: 100%;
  width: 100%;

  ${media.greaterThan('s')`
    --breakpoint: s;
  `}

  .dark-color {
    fill: ${tokens.brand('lightDarken')};
  }

  .light-color {
    fill: ${tokens.brand('lightAlt')};
  }

  .scene-frame,
  .scene-anchor {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .scene-anchor {
    max-width: 100rem;
    margin-left: auto;
    margin-right: auto;
  }

  .speed-lines {
    opacity: 0;
  }

  /* intial state for the car animation. */
  .scene-frame {
    ${media.greaterThan('s')`
      transform: ${styledMap('noLoadInParam', {
        true: 'translateX(0%)',
        default: 'translateX(-100%)',
      })}
    `}
  }
`

export const SpeedLines = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${tokens.z('base')};
  display: block;
  width: 100%;
  height: 100%;
  max-width: none;
  overflow: hidden;
`

export const SpeedLine = styled.div`
  position: absolute;
  left: 100%;
  display: block;
  border-radius: ${spacing(0.5)};
  height: ${spacing(0.5)};
  background-color: ${styledMap('color', {
    light: tokens.brand('lightAlt'),
    dark: tokens.brand('lightDarken'),
  })};

  ${media.greaterThan('m')`
    border-radius: ${spacing(1)};
    height: ${spacing(1)};
  `}
`

export const Car = styled.svg`
  position: absolute;
  bottom: ${spacing(13)};
  left: 0;
  width: 75%;
  z-index: ${tokens.z('aboveContent')};
  overflow: visible;

  ${media.greaterThan('s')`
    width: 50%;
  `}

  .car-color {
    fill: ${tokens.brand('dark')};
  }

  .car-line {
    fill:none;
    stroke:#1b1b1b;
    stroke-width:5;
    stroke-linecap:round;
    stroke-miterlimit:10;
  }
`

export const Cloud = styled.svg`
  display: block;
  width: 100%;
  transform: translateY(26%);
`

export const CloudWrap = styled.div`
  position: absolute;
  display: inline-block;
  bottom: ${spacing(13)};
  right: 68%;
  z-index: ${tokens.z('content')};
  width: 100%;
  transform-origin: 94% 57%;

  ${media.greaterThan('s')`
    right: 79%;
    width: 78.5%;
  `}
`
