// TODO: Refactor (it's kinda messy)
// TODO: Add PropTypes

import React from 'react'
import { graphql } from 'gatsby'
import CleverLink from '@partials/CleverLink'
import * as S from '@styles/components/Button'

export const Link = ({ children, style, isVisible = true, ...link }) => {
  if (style === 'button') {
    return (
      <S.Button isVisible={isVisible} {...link}>
        {children}
      </S.Button>
    )
  }

  return (
    <CleverLink isVisible={isVisible} {...link}>
      {children}
    </CleverLink>
  )
}

export const formatLinkField = ({
  children,
  link_type,
  link_style,
  link_to,
  custom_url,
  file_for_download,
  flyout,
  component_id,
}) => {
  let link = {
    children,
    style: link_style,
    type: link_type,
    className: '',
    to: '#0',
  }

  switch (link_type) {
    case 'internal':
      link.to = link_to || link.to
      link.to = component_id ? `${link.to}#${component_id}` : link.to
      break
    case 'external':
      link.to = custom_url || link.to
      break
    case 'download':
      // NOTE: May need to add back for accurate file names
      // link.file = file_for_download ? file_for_download.localFile : null
      link.to = file_for_download
        ? file_for_download.localFile.publicURL
        : link.to
      break
    case 'flyout':
      link.flyout = flyout
      break
    default:
      break
  }
  return link
}

// TODO: Add PropTypes

export const query = graphql`
  fragment LinkFragment on wordpress__link {
    link_type
    link_style
    link_text
    link_to
    custom_url
    flyout
    component_id
    file_for_download {
      localFile {
        publicURL
      }
    }
  }
`

export default Link
