import styled from 'styled-components'
import { tokens, spacing, media } from '@tokens'
import { Slide, Slider } from '@styles/components/Slider'
import { ITCBold } from '@styles/Typography'

export const FeaturedProjectComponent = styled(Slider)`
  > .inner {
    z-index: ${tokens.z('content')};
    max-width: ${tokens.get('maxWidth.vastSmall')};
    margin-right: auto;
    margin-left: auto;

    ${media.greaterThan('xl')`
      max-width: ${tokens.get('maxWidth.vast')};
    `}

    > .content {
      margin-left: 0;
    }
  }

  .intro {
    margin-bottom: ${spacing(4)};
    ${media.greaterThan('m')`
      margin-bottom: 0;
    `}
  }

  .swiper-container,
  .swiper-wrapper,
  .swiper-slide {
    overflow: visible;
    height: auto !important;
  }

  .swiper-container {
    max-width: none;
  }

  .pagination {
    z-index: ${tokens.z('aboveContent')};
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    position: absolute;
    top: auto;
    bottom: 0;
    right: 0;
    left: auto;
    width: auto;
    padding-bottom: 0.125rem;

    ${media.greaterThan('47.5rem')`
      padding-bottom: ${spacing(0.5)};
    `}

    ${media.greaterThan('l')`
      padding-bottom: ${spacing(1)};
    `}
  }

  .icon {
    display: none;

    ${media.greaterThan('m')`
      display: block;
      position: absolute;
      right: 0;
      top: ${spacing(11)};
      z-index: ${tokens.z('aboveContent')};
      min-width: 5rem;
      width: 9.5rem;
    `}

    ${media.lessThan('1400px')`
      width: 12.5%;
    `}
  }
`

export const ProjectSlide = styled(Slide)`
  pointer-events: all;
  padding: 0;
  height: auto;
  z-index: ${tokens.z('content')};

  &.swiper-slide-active {
    z-index: ${tokens.z('aboveContent')};
  }

  .slide__images-wrap {
    position: relative;
  }
  /*
    TODO This is specific to 3 feature images & needs to be revisited.
    Bonus points to have options in the CMS source, to specific layout per slide.
  */
  .slide__images {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1rem 1rem 1rem auto 1rem;

    ${media.greaterThan('s')`
      grid-template-rows: 4rem 2rem 2rem auto 3rem;
    `}

    .slide__image:first-child {
      grid-column: 1 / 13;
      grid-row: 3 / 6;

      ${media.lessThan('s')`
        display:none;
      `}
    }

    .slide__image:nth-child(2) {
      grid-column: 5 / 9;
      grid-row: 2 / 6;

      ${media.lessThan('s')`
        grid-column: 1 / 8;
        grid-row: 2 / 5;
      `}
    }

    .slide__image:nth-child(3) {
      grid-column: 7 / 11;
      grid-row: 1 / 5;

      ${media.lessThan('s')`
        grid-column: 6 / 13;
        grid-row: 1 / 6;
      `}
    }
  }

  .slide__image {
    position: relative;

    picture {
      position: relative;
      display: block;
      width: 100%;

      img {
        box-shadow: 0 1.875rem 5rem rgba(0, 0, 0, 0.1);
        display: block;
      }
    }
  }

  img {
    border-radius: ${spacing(1)};
    overflow: hidden;

    ${media.lessThan('m')`
      object-position: center right;
    `}
  }

  .slide__details {
    position: relative;

    ${media.greaterThan('47.5rem')`
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
    `}
  }

  .detail {
    padding-top: ${spacing(2.5)};
    margin-left: ${spacing(6)};
    max-width: none;

    &:first-child {
      margin-left: 0;
    }

    ${media.greaterThan('m')`
      flex: 0 1 auto;
    `}

    ${media.greaterThan('l')`
      margin-left: ${spacing(8)};
      padding-top: ${spacing(5)};
    `}

    ${media.lessThan('47.5rem')`
      display: none;
    `}

    svg {
      display: block;
      fill: ${tokens.brand('light')};

      .alt-color {
        fill: ${tokens.brand('dark')};
      }
    }
  }

  .detail__label,
  .detail__content,
  .case-study-link {
    ${ITCBold};
    text-transform: uppercase;
  }

  .detail__label,
  .case-study-link {
    letter-spacing: ${tokens.get('type.letterSpacing.large')};
    font-size: 0.625rem;

    ${media.greaterThan('m')`
      font-size: 0.875rem;
    `}
  }

  .detail__label {
    margin-bottom: ${spacing(1)};
  }

  .detail__content {
    font-size: 0.875rem;

    ${media.greaterThan('m')`
      font-size: 1.125rem;
    `}
  }

  .case-study-links {
    position: absolute;
    top: 0;
    right: 0;
    z-index: ${tokens.z('aboveContent') + 1 };
    line-height: 1;
    padding-top: ${spacing(3)};

    ${media.greaterThan('l')`
      padding-top: ${spacing(5.5)};
    `}

    ${media.lessThan('47.5rem')`
      position: relative;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    `}
  }

  .case-study-link {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    .arrow-up {
      display: block;
      margin-left: ${spacing(1)};
      width: ${spacing(3)};
      position: relative;
      transform: translate3d(0, -40%, 0)
    }

    .background-color-dark & {
      color: ${tokens.brand('light')};

      .arrow-up {
        fill: ${tokens.brand('light')};
      }
    }

    .background-color-light &,
    .background-color-standard & {
      color: ${tokens.brand('dark')};

      .arrow-up {
        fill: ${tokens.brand('dark')};
      }
    }
  }
`
