import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, box, spacing } from '@tokens'
import { ListInlineFlex } from '@styles/objects/List'
import { Meta } from '@styles/Typography'
export { Meta } from '@styles/Typography'

export const Hero = styled.div`
  --breakpoint: 'xs';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${tokens.brand('light')};
  overflow: hidden;

  ${media.greaterThan('s')`
    --breakpoint: 's';
  `}

  ${styledMap('type', {
    banner: css`
      height: 0;
      min-height: 0;
      padding-bottom: 100%;
      color: ${tokens.brand('light')};

      ${media.greaterThan('s')`
        padding-bottom: 66.667%;
      `}

      ${media.greaterThan('m')`
        padding-bottom: 50%;
      `}
    `,
    full: css`
      ${'' /* Add extra vertical space for to prevent vertical overflow clipping */}
      height: calc(90vh + ${spacing(10)});
      min-height: 35rem;
      padding-bottom: ${spacing(10)};
      margin-bottom: ${spacing(-10)};

      ${media.greaterThan('s')`
        height: calc(75vh + ${spacing(10)});
        min-height: 41.25rem;
      `}

      ${media.greaterThan('m')`
          height: calc(100vh + ${spacing(10)});
      `}
    `,
    default: '',
  })};

  .image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: ${tokens.z('base')};

    > * {
      height: 100%;
    }

    .gatsby-image-wrapper {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.35);
      }
    }
  }

  image {
    line-height: 0;
  }

  .client-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: ${tokens.z('content')};
    max-width: 20rem;
    transform: translate3d(-50%, -50%, 0);
    padding-top: ${spacing(8)};
    width: 45%;

    ${media.greaterThan('s')`
      width: 35%;
    `}

    ${media.greaterThan('m')`
      width: 25%;
    `}
  }

  .inner {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    will-change: transform;
    z-index: ${tokens.z('content')};

    ${styledMap('type', {
      banner: css`
        ${box({
          spacing: 'horizontal',
        })}
        ${box({
          spacing: 'vertical',
          multiplier: 0.6,
        })}
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
      `,
      full: css`
        ${box({
          spacing: 'horizontal',
        })}
        position: relative;
      `,
      default: css`
        ${box()}
        position: relative;
      `,
    })};

    ${media.greaterThan('m')`
      ${styledMap('type', {
        full: css`
          padding: 0 ${spacing(4)};
        `,
        default: '',
      })};
    `}
  }

  .content {
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    height: 100%;
    width: 100%;
    max-width: ${styledMap('type', {
      banner: tokens.get('maxWidth.vastSmall'),
      full: tokens.get('maxWidth.wide'),
      basic: tokens.get('maxWidth.narrow'),
    })};
    margin: 0 auto;
    align-items: ${styledMap('type', {
      full: 'center',
      default: 'flex-start',
    })};
    justify-content: ${styledMap('type', {
      banner: 'flex-end',
      default: 'center',
    })};
    padding-top: ${styledMap('type', {
      basic: tokens.get('component.hero.offset.s'),
      default: 0,
    })};

    ${media.greaterThan('s')`
      font-size: 1.25rem;

      ${styledMap('type', {
        basic: css`
          max-width: ${tokens.get('maxWidth.base')};
          padding-top: ${tokens.get('component.hero.offset.m')};
        `,
        default: '',
      })};
    `}

    ${media.greaterThan('l')`
      font-size: 1.375rem;

      ${styledMap('type', {
        basic: css`
          padding-top: ${tokens.get('component.hero.offset.l')};
        `,
        default: '',
      })};
    `}

    ${media.greaterThan('xl')`
      font-size: 1.5rem;
      max-width: ${styledMap('type', {
        banner: tokens.get('maxWidth.vast'),
        full: tokens.get('maxWidth.wide'),
        basic: tokens.get('maxWidth.wide'),
      })};
    `}

    > * {
      ${styledMap('type', {
        basic: css`
          max-width: 36rem;
        `,
        full: css`
          max-width: none;

          ${styledMap('noLoadInParam', {
            true: css`
              opacity: 1;
            `,
            default: css`
              ${media.greaterThan('s')`
                opacity: 0;
                transform: translateX(5%);
              `}
            `,
          })}
        `,
        default: '',
      })};
    }
  }

  ${styledMap('hasLogo', {
    true: css`
      .heading {
        display: none;
      }
    `,
    default: '',
  })};

  ${Meta} {
    display: flex;
    line-height: 1;
    margin-bottom: ${spacing(2)};
  }

  .categories {
    ${ListInlineFlex};
    border-left: ${tokens.brand('light')} 1px solid;
    margin-left: ${spacing(2)};
    padding-left: ${spacing()};

    .category {
      margin-left: ${spacing()};
      color: ${tokens.brand('light')};
    }

    li {
      &:not(:first-child) {
        .category::before {
          content: '•';
          margin-right: ${spacing()};
        }
      }
    }
  }

  .home-heading,
  .home-sub-heading {
    margin-bottom: ${spacing(1)};
    text-align: center;
  }

  .home-heading {
    margin-top: auto;
  }

  .label {
    margin-top: 0;
    margin-bottom: auto;
    text-align: center;
  }
`
