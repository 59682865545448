import React, { Component } from 'react'
import ScrollMagic from 'ScrollMagic'
import 'scrollmagic.gsap'

class CarComputerSvg extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.timelines = {}
    this.props = props
    this.classes = `car-computer-svg ${props.className || ''}`
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller()
    this.targets = {
      gears: this.ref.current.querySelectorAll('.gear'),
      computer: this.ref.current.querySelector('.computer'),
      car: this.ref.current.querySelector('.car'),
    }

    this.timelines.scroll = new TimelineMax().staggerFromTo(
      [this.targets.computer, this.targets.gears],
      0.6,
      {
        scale: 0,
        transformOrigin: '50% 50%',
      },
      {
        scale: 1,
        ease: Back.easeOut.config(1.7),
      },
      0.3
    )

    this.timelines.loop = new TimelineMax({
      paused: true,
      repeat: -1,
    })
      .fromTo(
        this.targets.gears[0],
        4,
        {
          transformOrigin: '50% 50%',
          rotation: 0,
        },
        {
          rotation: 360,
          ease: Power0.easeNone,
        },
        'gear'
      )
      .fromTo(
        this.targets.gears[1],
        4,
        {
          transformOrigin: '50% 50%',
          rotation: 0,
        },
        {
          rotation: -360,
          ease: Power0.easeNone,
        },
        'gear'
      )

    this.scenes = [
      new ScrollMagic.Scene({
        triggerElement: this.ref.current,
        triggerHook: 0.85,
        duration: 0,
      })
        .setTween(this.timelines.scroll)
        .on('start end', e => {
          const direction = e.target.controller().info('scrollDirection')
          // TODO Add helper function
          if (direction === 'FORWARD' || direction === 'PAUSED') {
            this.timelines.loop.play()
          } else {
            this.timelines.loop.pause()
          }
        })
        .addTo(this.controller),
    ]
  }

  componentWillUnmount() {
    Object.keys(this.timelines).forEach(key => {
      this.timelines[key].kill()
    })
    this.scenes.forEach(scene => {
      scene.destroy(true)
    })
    this.scenes = null
  }

  render() {
    return (
      <svg
        ref={this.ref}
        {...this.props}
        className={this.classes}
        viewBox="0 0 238.2 201.3"
        style={{ overflow: 'visible' }}
      >
        <g className="gears">
          <path
            className="gear"
            d="M63.7,26.6l-6.2-1.8v-0.1c-0.4-1.1-0.8-2.2-1.3-3.2l-0.1-0.1l3.1-5.7c0.5-1,0.3-2.1-0.4-2.9l-6-6.1 c-0.8-0.8-2-0.9-3-0.4l-5.6,3.2l-0.1-0.1c-1-0.5-2.1-0.9-3.2-1.3L41,8l-1.7-6.2C39,0.7,38,0,36.9,0h-8.4c-1.1,0-2,0.7-2.3,1.8 L24.4,8h-0.1c-1.1,0.4-2.1,0.8-3.1,1.3L21,9.4l-5.6-3.1c-1-0.5-2.1-0.3-2.9,0.4l-6,6.1c-0.7,0.8-0.9,2-0.4,2.9l3.1,5.7l-0.1,0.1 c-0.5,1-0.9,2.1-1.3,3.2v0.1l-6.1,1.8C0.7,26.9,0,27.9,0,29l0.1,8.6c0,1.1,0.7,2,1.7,2.3l6.2,1.8v0.1C8.3,42.9,8.7,44,9.3,45 l0.1,0.1l-3.1,5.7c-0.5,1-0.3,2.1,0.4,2.9l6,6.1c0.8,0.8,2,0.9,2.9,0.4l5.6-3.2l0.1,0.1c1,0.5,2.1,0.9,3.1,1.3h0.1l1.7,6.2 c0.3,1,1.3,1.8,2.4,1.8H37c1.1,0,2-0.7,2.3-1.8l1.8-6.2h0.1c1.1-0.4,2.1-0.8,3.2-1.3l0.1-0.1l5.6,3.1c1,0.6,2.2,0.4,3-0.4l6-6.1 c0.7-0.8,0.9-2,0.4-2.9l-3.2-5.7v-0.1c0.5-1,0.9-2.1,1.3-3.2v-0.1l6.1-1.8c1.1-0.3,1.8-1.3,1.8-2.4v-8.7 C65.4,27.8,64.7,26.9,63.7,26.6z M55,37.4c-0.8,0.3-1.4,0.9-1.7,1.7c-0.5,1.7-1.1,3.4-2,4.9c-0.4,0.7-0.4,1.6,0,2.4l2.8,5.2 l-3.4,3.5l-5.1-2.9c-0.7-0.4-1.6-0.4-2.4,0c-1.5,0.9-3.2,1.5-4.9,2c-0.2,0.1-0.3,0.1-0.5,0.2c-0.6,0.3-1,0.9-1.2,1.5l-1.6,5.7h-4.9 L28.7,56c-0.2-0.8-0.9-1.5-1.7-1.8c-1.7-0.5-3.3-1.1-4.9-2c-0.7-0.4-1.6-0.4-2.4,0l-5.1,2.9l-3.4-3.5l2.8-5.2 c0.4-0.7,0.4-1.6,0-2.4c-0.9-1.6-1.5-3.3-2-5c-0.2-0.8-0.9-1.4-1.6-1.7l-5.6-1.6v-5l5.6-1.6c0.8-0.3,1.4-0.9,1.7-1.7 c0.5-1.7,1.1-3.4,2-4.9c0.4-0.7,0.4-1.6,0-2.4l-2.8-5.2l3.4-3.5l5.1,2.9c0.7,0.4,1.6,0.4,2.4,0c1.5-0.9,3.2-1.5,4.9-2 c0.8-0.3,1.4-0.9,1.7-1.7l1.6-5.7h4.9l1.6,5.7c0.2,0.6,0.6,1.2,1.2,1.5c0.2,0.1,0.3,0.2,0.5,0.2c1.7,0.5,3.3,1.1,4.9,2 c0.7,0.4,1.6,0.4,2.4,0l5.1-2.9l3.4,3.5L51.4,20c-0.4,0.7-0.4,1.6,0,2.4c0.9,1.6,1.5,3.3,2,5c0.2,0.8,0.9,1.4,1.7,1.7l5.5,1.6v5 L55,37.4z M32.7,17.7c-8.6,0-15.6,7-15.6,15.6s7,15.6,15.6,15.6s15.6-7,15.6-15.6l0,0C48.3,24.7,41.3,17.7,32.7,17.7z M32.7,44.1 c-6,0-10.8-4.8-10.8-10.8s4.8-10.8,10.8-10.8s10.8,4.8,10.8,10.8l0,0C43.5,39.2,38.7,44.1,32.7,44.1z"
          />
          <path
            className="gear"
            d="M123.3,23c0-0.9-0.5-1.7-1.4-2l-4.6-1.3c-0.3-0.8-0.6-1.7-1-2.5l2.3-4.2c0.4-0.8,0.3-1.8-0.3-2.4l-4.6-4.6 c-0.6-0.6-1.6-0.8-2.4-0.3l-4.2,2.4c-0.8-0.4-1.6-0.7-2.4-1l-1.3-4.6c-0.3-0.9-1.1-1.5-2-1.5H95c-0.9,0-1.8,0.6-2,1.5l-1.3,4.6 c-0.8,0.3-1.6,0.6-2.4,1l-4.2-2.3c-0.8-0.4-1.8-0.3-2.4,0.3l-4.6,4.6c-0.6,0.7-0.8,1.7-0.3,2.5l2.3,4.3c-0.4,0.8-0.7,1.6-1,2.5 L74.6,21c-0.9,0.3-1.5,1.1-1.5,2l0.1,6.6c0,0.9,0.5,1.7,1.4,2l4.6,1.3c0.3,0.8,0.6,1.7,1,2.5l-2.3,4.2c-0.4,0.8-0.3,1.8,0.3,2.5 l4.6,4.6c0.7,0.6,1.6,0.8,2.4,0.3l4.2-2.4c0.8,0.4,1.6,0.7,2.4,1l1.3,4.7c0.3,0.9,1.1,1.5,2,1.5h6.4c0.9,0,1.7-0.6,1.9-1.5l1.3-4.6 c0.8-0.3,1.6-0.6,2.4-1l4.2,2.3c0.8,0.4,1.8,0.3,2.4-0.3l4.6-4.6c0.6-0.7,0.8-1.6,0.3-2.5l-2.3-4.2c0.4-0.8,0.7-1.6,1-2.5l4.6-1.3 c0.9-0.3,1.5-1.1,1.5-2L123.3,23z M115.2,29.3c-0.7,0.2-1.2,0.8-1.4,1.4c-0.3,1.3-0.9,2.5-1.5,3.7c-0.3,0.6-0.3,1.4,0,2l2.1,3.8 l-2.4,2.4l-3.8-2.1c-0.6-0.4-1.4-0.4-2,0c-1.2,0.7-2.4,1.2-3.7,1.5c-0.1,0-0.3,0.1-0.4,0.2c-0.5,0.3-0.8,0.7-1,1.2l-1.2,4.2h-3.4 l-1.2-4.2c-0.1-0.5-0.5-1-1-1.3c-0.1-0.1-0.3-0.1-0.4-0.2c-1.3-0.3-2.5-0.9-3.7-1.5c-0.6-0.3-1.4-0.3-2,0l-3.7,2.1l-2.4-2.4 l2.1-3.8c0.3-0.6,0.3-1.4,0-2c-0.7-1.2-1.2-2.5-1.5-3.8c-0.2-0.7-0.7-1.2-1.4-1.4l-4.1-1.2v-3.5l4.1-1.2c0.7-0.2,1.2-0.8,1.4-1.4 c0.3-1.3,0.9-2.5,1.5-3.7c0.3-0.6,0.3-1.4,0-2l-2.1-3.8l2.4-2.4l3.8,2.1c0.6,0.4,1.4,0.4,2,0c1.2-0.7,2.4-1.2,3.7-1.5 c0.1,0,0.3-0.1,0.4-0.2c0.5-0.3,0.8-0.7,1-1.2l1.2-4.2h3.4l1.2,4.2c0.2,0.5,0.5,1,1,1.3c0.1,0.1,0.3,0.1,0.4,0.2 c1.3,0.3,2.5,0.8,3.6,1.5c0.6,0.3,1.4,0.3,2,0l3.8-2.1l2.4,2.4l-2.1,3.8c-0.3,0.6-0.3,1.4,0,2c0.7,1.2,1.2,2.4,1.5,3.8 c0.2,0.7,0.7,1.2,1.4,1.4l4.1,1.2v3.5L115.2,29.3z M98.2,38.4c-6.7,0-12.1-5.3-12.1-12c0-6.7,5.3-12.1,12-12.1 c6.7,0,12.1,5.3,12.1,12c0,0,0,0,0,0.1C110.1,32.9,104.8,38.3,98.2,38.4L98.2,38.4z M98.2,18.3c-4.5,0-8.1,3.5-8.1,8s3.5,8.1,8,8.1 c4.5,0,8.1-3.5,8.1-8c0,0,0,0,0-0.1C106.2,21.9,102.6,18.3,98.2,18.3L98.2,18.3z"
          />
        </g>
        <g className="computer">
          <path d="M230 29.2H38.2c-4.3 0-7.9 3.5-8 7.8v125c.1 4.4 3.7 7.9 8.1 7.8H126l-10 21.1-3.4 7.3c-.1.2-.2.5-.2.7-.1 1.2.8 2.3 1.9 2.4h45.6c1.2 0 2.2-1 2.2-2.2v-.2c0-.2-.1-.5-.2-.7l-3.3-7.3-9.5-21.1h81c4.4.1 8-3.4 8.1-7.8V37c-.1-4.4-3.8-7.9-8.2-7.8z" />
          <path
            fill="#fffcf3"
            d="M137.4 158.4c-.7 0-1.5 0-2.2-.2-3.1-.7-5.6-3.2-6.2-6.3-.1-.5-.2-1-.2-1.5-.1-4.6 3.5-8.4 8.1-8.5 4.6-.1 8.4 3.5 8.5 8.1.2 4.5-3.5 8.3-8 8.4z"
          />
          <path fill="#fffcf3" d="M226.6 53v80.4H42.1V39.5h184.5z" />

          <g className="car">
            <path
              d="M217.4 60.2c-.3-.3-.8-.3-1.1-.1l-.9.4c-.5.2-.8.8-.5 1.3 0 .2.1.3.2.4l2.3 2.1 4.3 3.8c1.1.8 2.5 1.2 3.9 1.1h.9v-1.8c-3.3-2.6-9.1-7.2-9.1-7.2z"
              fill="none"
            />
            <path d="M113.6 73.8c-1.4.5-3.2 1-3.7 2-.9 2.3-1.6 4.7-2.1 7.2-.3 1 .3 2 1.3 2.2h.6c.7 0 1.3-.3 1.7-.9.6-1.2 1.1-6.5 3-9.5.2-.3.1-.8-.2-1-.2-.1-.4-.1-.6 0zM227.1 75.9c-3-.5-6.1-.7-9.1-.5h-10.2c-5.3 0-10.6 0-13.8-.1-1.4 0-2.6-.1-3.6-.1-1 .1-1.9.9-2.2 1.9-.7 1.5-1 3.1-.9 4.7 0 .2.1.3.1.5.9 3.1 2.1 6.2 3.4 9.2.6 1.3 1.5 1.7 7.5 1.7l9.7-.1 10.2-.1h9.1l-.2-17.1z" />
            <path d="M227 95.8h-31.7c-6.5 0-6.9-.5-7.7-1.9s-1.7-4-2.8-6.5c-2.1-4.8-1.8-8 0-12.9.3-.7 0-1.5-.7-1.8-.2-.1-.3-.1-.5-.1h-.3c-1.5 0-2.8-.9-3.4-2.3-.3-1-.7-1.8-1.8-1.7h-.1c-13.1.6-47.1 2.9-53.4 3.7-1.4.2-2.5.3-3.5.5-3.4 1-5.1 12.4-5.5 13.2-.5.7-1.3 1.1-2.2 1-.6 0-1.1.4-1.2 1 0 .3 0 .7.1 1 1.7 3.3 3.1 6.8 4.1 10.3.7 1.6 2.2 2.7 4 2.8 2.2.3 5.4.6 10.9.9h.3c5.9 0 .5-8.7 9.4-18.9 7-8 24.4-7.2 31.1 3.2 6.5 10 2.7 16.8 6.3 16.8h.8c9.8.2 19.9.3 28.5.4 3.7 0 7.2.1 10.2.1h9.1v-8.8zM216.8 55.4c-1.5-.2-6.9 3.5-7.4 4l-.1.1c-.5.8-.4 1.8.3 2.4l8.3 7.8 2.3 2.2c.5.5 1.3.8 2 .8 1.4 0 3.3.1 4.7.1h.1v-3.6h-.9c-1.4.1-2.8-.3-3.9-1.1l-4.3-3.8-2.3-2c-.4-.3-.5-1-.1-1.4.1-.1.2-.2.4-.3l.9-.4c.4-.2.8-.1 1.1.1l.1.1s5.8 4.6 9.1 7.2v-4.5c-2.7-2.4-5.5-4.6-8.5-6.7-.5-.6-1.1-.9-1.8-1z" />
            <path d="M154.3 82.1c-9 .6-15.8 8.3-15.3 17.3.6 9 8.3 15.8 17.3 15.3 9-.6 15.8-8.3 15.3-17.3-.5-9.1-8.3-15.9-17.3-15.3zm12 16.5c0 2.8-1.2 5.6-3.2 7.6-1.4 1.3-3.1 2.3-5 2.8-1.5.4-3.1.4-4.7.2-1.4-.2-2.7-.8-3.9-1.5-.6-.4-1.1-.8-1.6-1.3-.9-.9-1.6-1.8-2.1-2.9-1.8-3.4-1.6-7.5.4-10.8 1-1.5 2.2-2.8 3.8-3.7 2.2-1.2 4.7-1.7 7.2-1.3 3.8.6 7 3.1 8.4 6.6.5 1.3.8 2.8.7 4.3z" />
            <path d="M155.4 91.4c-3.9 0-7 3.1-7 7s3.1 7 7 7c3.8 0 7-3.1 7-6.9 0-3.9-3.1-7.1-7-7.1zm0 10c-1.7 0-3.1-1.3-3.1-3s1.3-3.1 3-3.1 3.1 1.3 3.1 3v.1c0 1.6-1.4 3-3 3z" />
            <path d="M96.8 82.9H65.9c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h30.8c.8 0 1.5-.7 1.5-1.5.1-.9-.6-1.5-1.4-1.5zM94 94.9c.9 0 1.6-.6 1.6-1.5 0-.2 0-.3-.1-.5-.2-.6-.8-1-1.5-1H70.2c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5H94zM95 97.9H79.1c-.8 0-1.5.6-1.6 1.4 0 .2 0 .4.1.6.2.6.8 1 1.5 1H95c.6 0 1.2-.4 1.5-1 .1-.2.1-.3.1-.5-.1-.8-.7-1.5-1.6-1.5z" />
          </g>
        </g>
      </svg>
    )
  }
}

export default CarComputerSvg
