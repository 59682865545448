import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import * as S from '@styles/components/LogoGrid'
import ComponentContent from '@partials/ComponentContent'
import Image from '@partials/Image'
import SectionLabel from '@partials/SectionLabel'
import { getComponentClasses } from '@utils/components'
import ScrollMagic from 'ScrollMagic'
import 'scrollmagic.gsap'

const LogoLink = ({ url, className, children }) => {
  if (url) {
    return (
      <a
        href={url}
        target="_blank"
        className={className}
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  }

  return <div className={className}>{children}</div>
}

class LogoGridComponent extends Component {
  constructor(props) {
    super(props)
    const { component, className } = props
    this.ref = React.createRef()
    this.timelines = {}
    this.props = props
    this.component = component
    this.classes = getComponentClasses(component, ['logo-grid', className])
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller()
    this.scenes = []
    this.timelines = {}
    this.targets = {
      grid: this.ref.current.querySelectorAll('.grid'),
      logos: this.ref.current.querySelectorAll('.logo-link'),
    }

    this.timelines.scroll = new TimelineMax().staggerFromTo(
      this.targets.logos,
      0.4,
      {
        autoAlpha: 0,
        x: 50,
      },
      {
        autoAlpha: 1,
        x: 0,
        ease: Power2.easeOut,
      },
      0.05
    )

    this.scenes = [
      new ScrollMagic.Scene({
        triggerElement: this.targets.grid,
        triggerHook: 0.75,
        duration: 0,
      })
        .setTween(this.timelines.scroll)
        .addTo(this.controller),
    ]
  }

  componentWillUnmount() {
    if (this.component.layout !== 'columns') {
      return
    }

    Object.keys(this.timelines).forEach(key => {
      this.timelines[key].kill()
    })
    this.scenes.forEach(scene => {
      scene.destroy(true)
    })
    this.scenes = null
    this.timelines = null
  }

  render() {
    const {
      background_color,
      background_color_fill,
      text_content: content,
      links,
      logos,
      label,
    } = this.component

    return (
      <S.Component
        className={this.classes}
        bg={background_color}
        bgFill={background_color_fill}
        ref={this.ref}
      >
        {label && (
          <SectionLabel
            label={label}
            bg={background_color}
            bgFill={background_color_fill}
          />
        )}
        <div className="inner">
          <div className="content">
            <div className="intro">
              <ComponentContent {...content} links={links} />
            </div>
          </div>
          <S.LogoGrid className="grid">
            {logos.map((logo, index) => {
              return (
                <LogoLink key={index} url={logo.url} className="logo-link">
                  <Image
                    source={logo.image}
                    objectFit="contain"
                    className="logo-image"
                  />
                </LogoLink>
              )
            })}
          </S.LogoGrid>
        </div>
      </S.Component>
    )
  }
}

LogoGridComponent.propTypes = {
  component: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment LogoGridComponentFragment on WordPressAcf_logo_grid {
    text_content {
      ...ComponentContentFragment
    }
    links {
      ...LinkFragment
    }
    label
    logos {
      url
      image {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    background_color
    background_color_fill
  }
`

export default LogoGridComponent
