import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Links from '@partials/Links'
import Link from '@partials/Link'
import Image from '@partials/Image'
import Icon from '@partials/Icon'
import {
  HomeHeroHeading,
  HomeHeroSubHeading,
  H1,
  H3,
  Label,
} from '@styles/Typography'
import * as S from '@styles/components/Hero'
import ScrollMagic from 'scrollmagic'
import 'scrollmagic.gsap'
import HeroSvg from '@svgs/HeroSvg'
import { hasNoLoadInParam } from '@utils/components'

// TODO: Add image cropping
class Hero extends Component {
  static propTypes = {
    image: PropTypes.object,
    heading: PropTypes.string.isRequired,
    sub_heading: PropTypes.string,
    content: PropTypes.string,
    links: PropTypes.array,
    type: PropTypes.string,
    meta: PropTypes.shape({
      date: PropTypes.string,
      categories: PropTypes.array,
    }),
  }

  constructor(props) {
    super(props)

    this.parallax = props.type === 'banner'
    this.props = props
    this.ref = React.createRef()

    if (this.props.meta) {
      this.meta = this.props.meta

      if (this.meta.categories) {
        this.meta.categories = this.meta.categories.filter(
          category => category && category.name !== 'Uncategorized'
        )
      }
    }
  }

  componentDidMount() {
    this.breakpoint = window
      .getComputedStyle(this.ref.current)
      .getPropertyValue('--breakpoint')
    this.hasIntroAnimation = this.breakpoint !== 'xs'
    this.scenes = []
    this.noLoadInParam = hasNoLoadInParam()

    if (
      this.props.slug === 'home' &&
      this.hasIntroAnimation &&
      !this.noLoadInParam
    ) {
      // Home content animation on load
      TweenMax.staggerTo(
        this.ref.current.querySelectorAll('.content > *'),
        0.5,
        {
          opacity: 1,
          x: 0,
          delay: 1,
        },
        0.4
      )
    }

    this.controller = new ScrollMagic.Controller({
      globalSceneOptions: {
        offset: 0,
        duration: '100%',
      },
    })

    this.scenes = [
      new ScrollMagic.Scene({
        triggerElement: this.ref.current,
        offset: 0,
        triggerHook: 0.75,
        duration: 0,
      })
        .on('start end', e => {
          const direction = e.target.controller().info('scrollDirection')
          if (direction === 'FORWARD') {
            document.body.classList.add('sticky-header')
          } else if (direction === 'REVERSE' || direction === 'PAUSE') {
            document.body.classList.remove('sticky-header')
          }
        })
        .addTo(this.controller),
    ]

    if (this.parallax) {
      const imageTarget = this.ref.current.querySelector(
        '.gatsby-image-wrapper'
      )
      const contentTarget = this.ref.current.querySelector('.inner')

      if (imageTarget && contentTarget) {
        this.scenes.push(
          new ScrollMagic.Scene()
            .setTween(imageTarget, { yPercent: 50, ease: Linear.easeNone })
            .addTo(this.controller)
        )

        this.scenes.push(
          new ScrollMagic.Scene()
            .setTween(contentTarget, {
              yPercent: 40,
              opacity: 0.1,
              ease: Linear.easeNone,
            })
            .addTo(this.controller)
        )
      }
    }
  }

  componentDidUpdate() {
    if (this.parallax) {
      if (this.context.locked && this.controller.enabled()) {
        this.controller.enabled(false)
      } else if (!this.context.locked && !this.controller.enabled()) {
        this.controller.enabled(true)
      }
    }
  }

  componentWillUnmount() {
    if (this.parallax) {
      this.scenes.forEach(scene => {
        scene.destroy()
      })
    }
  }

  render() {
    const { image, path, type = 'basic', logo } = this.props
    return (
      <S.Hero
        ref={this.ref}
        className="hero"
        type={type}
        hasLogo={!!logo}
        noLoadInParam={this.noLoadInParam}
      >
        {type === 'full' ? (
          <HeroFullContent {...this.props} meta={this.meta} />
        ) : (
          <>
            {logo && (
              <Icon
                media_type="image"
                image={logo.image}
                className="client-logo"
              />
            )}
            {type === 'banner' && <Image source={image} aspectRatio={16 / 7} />}
            <div className="inner">
              <HeroContent {...this.props} meta={this.meta}></HeroContent>
            </div>
          </>
        )}
      </S.Hero>
    )
  }
}

class HeroContent extends Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    sub_heading: PropTypes.string,
    content: PropTypes.string,
    links: PropTypes.array,
    meta: PropTypes.shape({
      date: PropTypes.string,
      categories: PropTypes.array,
    }),
  }

  render() {
    const { heading, sub_heading, content, links, meta } = this.props

    return (
      <div className="content">
        {meta && (
          <S.Meta>
            <div className="date">{meta.date}</div>
            {meta.categories && meta.categories.length > 0 && (
              <ul className="categories">
                {meta.categories.map((category, key) => {
                  return (
                    <li key={key}>
                      <Link to={category.path} className="category">
                        {category.name}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            )}
          </S.Meta>
        )}

        <H1 className="heading">{heading}</H1>
        {sub_heading && <H3 className="sub-heading">{sub_heading}</H3>}
        {content && (
          <div className="body" dangerouslySetInnerHTML={{ __html: content }} />
        )}
        {links && <Links links={links} />}
      </div>
    )
  }
}

class HeroFullContent extends Component {
  render() {
    const { heading, sub_heading, content, links, label } = this.props

    return (
      <>
        <div className="media">
          <HeroSvg />
        </div>
        <div className="inner">
          <div className="content">
            <HomeHeroHeading
              className="home-heading"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
            {sub_heading && (
              <HomeHeroSubHeading
                className="home-sub-heading"
                dangerouslySetInnerHTML={{ __html: sub_heading }}
              />
            )}
            {links && <Links links={links} />}
            {label && (
              <Label
                className="label"
                dangerouslySetInnerHTML={{ __html: label }}
              />
            )}
          </div>
        </div>
      </>
    )
  }
}

export const query = graphql`
  fragment HeroFragment on wordpress__hero {
    heading
    sub_heading
    content
    label
    links {
      ...LinkFragment
    }
  }
`

export default Hero
