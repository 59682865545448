import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Links from '@partials/Links'
import { H2, H3 } from '@styles/Typography'

const ComponentContent = ({ heading, sub_heading, content, links }) => (
  <React.Fragment>
    {heading && (
      <H2 className="heading" dangerouslySetInnerHTML={{ __html: heading }} />
    )}
    {sub_heading && (
      <H3
        className="sub-heading"
        dangerouslySetInnerHTML={{ __html: sub_heading }}
      />
    )}
    {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
    {links && <Links links={links} />}
  </React.Fragment>
)

ComponentContent.propTypes = {
  heading: PropTypes.string,
  sub_heading: PropTypes.string,
  content: PropTypes.string,
  links: PropTypes.array,
}

export const query = graphql`
  fragment ComponentContentFragment on wordpress__text_content {
    heading
    sub_heading
    content
  }
`

export default ComponentContent
