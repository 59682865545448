import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import * as S from '@styles/components/Cards'
import ComponentContent from '@partials/ComponentContent'
import Card from '@partials/Card'
import BoltSvg from '@svgs/BoltSvg'
import { getComponentClasses } from '@utils/components'
import SectionLabel from '@partials/SectionLabel'
import { hasIcon } from '@utils/components'

const CardsComponent = ({ component, className = '' }) => {
  const classes = getComponentClasses(component, ['cards-block', className])
  const {
    background_color,
    background_color_fill,
    text_content: content,
    links,
    cards,
    has_bolt,
    label,
  } = component

  return (
    <S.CardsComponent
      className={classes}
      bg={background_color}
      bgFill={background_color_fill}
    >
      {label && (
        <SectionLabel
          label={label}
          bg={background_color}
          bgFill={background_color_fill}
        />
      )}
      <div className="inner">
        <div className="content">
          <div className="intro">
            {has_bolt && (
              <div className="bolt-container">
                <BoltSvg />
              </div>
            )}
            <ComponentContent {...content} links={links} />
          </div>
        </div>
      </div>
      <S.Cards>
        {cards.map((card, index) => {
          return <Card key={index} {...card} />
        })}
      </S.Cards>
    </S.CardsComponent>
  )
}

CardsComponent.propTypes = {
  component: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment CardsComponentFragment on WordPressAcf_cards {
    text_content {
      ...ComponentContentFragment
    }
    label
    cards {
      media_type
      dynamic_svg
      svg
      image {
        alt_text
        localFile {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      heading
      content
      links {
        ...LinkFragment
      }
    }
    background_color
    background_color_fill
    has_bolt
  }
`

export default CardsComponent
