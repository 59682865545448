export const buildAnchor = string => {
  return encodeURIComponent(string.toLowerCase()).replace(/%[0-9A-F]{2}/gi, '')
}

export const affixString = (string, { prefix, suffix } = {}) => {
  return (prefix || '') + string + (suffix || '')
}

export const concatRange = (min, max) => {
  return `${min}–${max}`
}
