import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import * as S from '@styles/components/Testimonial'
import BoltSvg from '@svgs/BoltSvg'
import { getComponentClasses } from '@utils/components'
import Swiper from 'react-id-swiper'
import { Pagination } from 'swiper/js/swiper.esm'
import 'swiper/css/swiper.min.css'
import { TweenMax } from 'gsap'
import ScrollMagic from 'ScrollMagic'
import 'scrollmagic.gsap'
import Texture from '@images/gearbox-texture@2x.png'

const Slide = ({ company, contact, content }) => {
  // TODO: Switch to fixed?
  const photo = contact.photo
    ? contact.photo.localFile.childImageSharp.fluid
    : null

  return (
    <S.Testimonial maxVisible={1}>
      <div className="inner">
        <blockquote
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div className="author">
          {photo && (
            <div className="author-image">
              <picture>
                <img
                  data-src={photo.src}
                  data-srcset={photo.srcSet}
                  sizes={photo.sizes}
                  className="lazy-slider-img"
                  alt={contact.name}
                />
                <img src={photo.base64}></img>
              </picture>
            </div>
          )}
          <div className="author-credentials">
            <span className="name">{contact.name}</span>
            <span className="company">{company}</span>
          </div>
        </div>
      </div>
    </S.Testimonial>
  )
}

class TestimonialsComponent extends Component {
  static propTypes = {
    component: PropTypes.object.isRequired,
    index: PropTypes.number,
    className: PropTypes.string,
  }

  constructor(props) {
    super(props)
    const swiper = null
    const { component, className, index } = this.props
    const testimonialInstance = this
    const hasSlides = component.testimonials.length > 1
    this.classes = getComponentClasses(component, ['testimonials', className])
    this.ref = React.createRef()
    this.state = { swiper }
    this.component = component
    this.params = {
      modules: [Pagination],
      loop: hasSlides,
      slidesPerView: 1,
      speed: 600,
      spaceBetween: 40,
      autoHeight: false,
      effect: 'customZoom',

      // Lazy
      preloadImages: false,
      lazy: {
        loadOnTransitionStart: true,
      },
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      shouldSwiperUpdate: false,
      virtualTranslate: true,
      waitForTransition: false,

      getSwiper: swiperInstance => {
        // Accessibility - Set slides aria-hidden and tabindex on instantiation
        if (swiperInstance) {
          Array.from(swiperInstance.slides).forEach(slide => {
            this.updateSlideAccessibility(slide)
          })
        }

        this.setState({
          swiper: swiperInstance,
        })
      },
      on: {
        slideChange: () => {
          if (this.state.swiper) {
            // Accessibility - Set slides aria-hidden and tabindex on slide update
            const swiper = { ...this.state.swiper }
            Array.from(swiper.slides).forEach(slide => {
              this.updateSlideAccessibility(slide)
            })

            this.setState({
              swiper,
            })
          }
        },
      },
    }

    // TODO Refactor to a helper function
    if (hasSlides) {
      this.params.pagination = {
        el: `.component-${index} .pagination`,
        type: 'bullets',
        bulletClass: 'bullet',
        bulletActiveClass: 'is-active',
        clickable: true,
      }

      this.params.on.init = function() {
        testimonialInstance.setTranslate(this)
      }

      this.params.on.setTranslate = translate => {
        this.setTranslate(this.state.swiper, translate)
      }

      this.params.on.setTransition = transitionSpeed => {
        this.setTransitionSpeed(this.state.swiper, transitionSpeed)
      }
    }
  }

  getTransitionSpeed() {
    const transitionSpeed = this.currentTransitionSpeed
    this.currentTransitionSpeed = 0
    return transitionSpeed
  }

  setTransitionSpeed(swiper, transitionSpeed) {
    this.currentTransitionSpeed = transitionSpeed
  }

  setTranslate(swiper, wrapperTranslate) {
    if (!swiper) {
      return
    }

    const durationInSeconds = this.getTransitionSpeed() / 1000
    const slides = Object.values(swiper.slides).slice(0, -1)

    slides.map((slide, index) => {
      // Stack slides
      const offset = slide.swiperSlideOffset
      const y = 0
      let x = -offset
      if (!swiper.params.virtualTranslate) {
        x -= swiper.translate
      }

      TweenMax.set(slide, {
        x,
        y,
      })

      // Animation
      const clip = (val, min, max) => Math.max(min, Math.min(val, max))
      const ZOOM_FACTOR = 0.15
      const opacity = Math.max(1 - Math.abs(slide.progress), 0)
      const clippedProgress = clip(slide.progress, -1, 1)
      const scale = 1 - ZOOM_FACTOR * clippedProgress

      TweenMax.to(slide, durationInSeconds, {
        scale,
        opacity,
      })
    })
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller()
    this.scenes = []
    this.timelines = {}
    this.targets = {
      swiper: this.ref.current.querySelector('.swiper-container'),
      texture: this.ref.current.querySelector('.texture'),
    }

    this.timelines.scroll = new TimelineMax().fromTo(
      this.targets.swiper,
      0.4,
      {
        autoAlpha: 0,
        x: -50,
      },
      {
        autoAlpha: 1,
        x: 0,
        ease: Power2.easeOut,
      }
    )
    this.scenes = [
      new ScrollMagic.Scene({
        triggerElement: this.targets.swiper,
        triggerHook: 0.75,
        duration: 0,
      })
        .setTween(this.timelines.scroll)
        .addTo(this.controller),
      new ScrollMagic.Scene({
        triggerElement: this.targets.swiper,
        triggerHook: 1,
        duration: 0,
        reverse: false,
      })
        .on('enter', e => {
          // TODO Refactor this on slideChange in Loop mode
          const lazyImages = this.targets.swiper.querySelectorAll(
            '.lazy-slider-img'
          )

          if (lazyImages) {
            lazyImages.forEach(image => {
              image.onload = () => {
                TweenMax.to(image.parentElement.children[1], 0.25, {
                  opacity: 0,
                })
              }
              image.src = image.dataset.src
              image.srcset = image.dataset.srcset
            })
          }
          // Lazy laoded texture image
          this.targets.texture.style.backgroundImage = `url('${this.targets.texture.dataset.background}')`
        })
        .addTo(this.controller),
    ]

    if (this.component.testimonials.length > 1) {
      const component = this.ref.current
      const pagination = component.querySelector('.pagination')
      component.querySelector('.background-container').appendChild(pagination)
    }
  }

  componentWillUnmount() {
    Object.keys(this.timelines).forEach(key => {
      this.timelines[key].kill()
    })
    this.scenes.forEach(scene => {
      scene.destroy(true)
    })
    this.scenes = null
    this.timelines = null
  }

  updateSlideAccessibility(slide) {
    // Accessibility - Set slide aria and tabindex, only set tabindex
    // if slide is an a tag.
    const isVisible = slide.classList.contains('swiper-slide-visible')
    const tabIndex = isVisible ? '0' : '-1'
    const ariaHidden = isVisible ? 'false' : 'true'

    if (slide.hasAttribute('href')) {
      slide.setAttribute('tabIndex', tabIndex)
    }

    slide.setAttribute('aria-hidden', ariaHidden)
  }

  render() {
    const { component, className, index } = this.props
    return (
      <S.TestimonialsComponent
        className={this.classes}
        maxVisible={1}
        ref={this.ref}
      >
        <div className="inner">
          <Swiper {...this.params}>
            {component.testimonials.map((testimonial, key) => {
              return (
                <div className="slide" key={key}>
                  <Slide {...testimonial} />
                </div>
              )
            })}
          </Swiper>
        </div>
        <div className="background-container">
          <div className="bolt-container">
            <BoltSvg direction="right" />
          </div>
          <div className="texture" data-background={Texture} />
        </div>
      </S.TestimonialsComponent>
    )
  }
}

export const query = graphql`
  fragment TestimonialsComponentFragment on WordPressAcf_testimonials {
    testimonials {
      company
      contact {
        name
        photo {
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 320) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      content
    }
  }
`

export default TestimonialsComponent
