import styled, { css } from 'styled-components'
import { tokens, media, spacing } from '@tokens'
import { SmallTeaserStyles } from '@styles/components/Teaser'
export { Component } from '@styles/components/Component'

export const TeamList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, [col] 1fr);
  grid-gap: ${spacing(4)};
  align-items: start;
  overflow: hidden;
  max-width: ${tokens.get('maxWidth.narrow')};
  margin-left: auto;
  margin-right: auto;

  ${media.greaterThan('s')`
    grid-gap: ${spacing(6)};
  `}

  ${media.greaterThan('m')`
    grid-template-columns: repeat(2, [col] 1fr);
    grid-gap: ${spacing(8)};
  `}

  ${media.greaterThan('l')`
    grid-gap: ${spacing(10)};
  `}
`

export const TeamMember = styled.div`
  ${SmallTeaserStyles}
`