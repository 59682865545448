import styled from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing } from '@tokens'
import { ITCBold } from '@styles/Typography'

export const NavDrawer = styled.div`
  margin:  0;
  padding: 0;
  position: relative;
  flex: 1 1 auto;
  width: 100%;

  .bolt-container {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: ${tokens.z('under')};
    width: 100%;
    opacity: 0.05;
    transform: translate3d(-50%, -50%, 0);
    width: 100%;

    svg {
      fill: ${tokens.brand('dark')};
    }
  }
`

export const NavDrawerItem = styled.li`
  color: ${tokens.color('text')};
  display: flex;
  flex: row;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  position: relative;
  text-overflow: ellipsis;
  width: 100%;

  &:first-child {
    border-top: none;
  }

  .navdrawer-link {
    ${ITCBold};
    padding: ${spacing(2)} 0;
    color: ${tokens.color('text')};
    font-size: 2.5rem;
    text-transform: uppercase;
  }

  .icon {
    height: ${spacing(3)};
    width: ${spacing(3)};
    flex: 0 0 ${spacing(3)};
    margin-left: ${spacing(0.5)};
  }
`

export const NavDrawerLevel = styled.ul`
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin: 0;
  z-index: ${tokens.z('base')};
  transform: translateY(-50%);

  &.is-active {
    z-index: ${tokens.z('content')};
  }
`
export const Back = styled.button`
  ${ITCBold};
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: ${spacing(-4)};
  left: 0;
  width: auto;
  border: none;
  padding: 0;
  z-index: ${tokens.z('aboveContent')};
  background: none;
  font-size: 0.675rem;
  letter-spacing: ${tokens.get('type.letterSpacing.medium')};
  text-transform: uppercase;

  .icon {
    display: block;
    height: ${spacing(3)};
    width: ${spacing(3)};
    margin-right: ${spacing(0)};
    margin-left: ${spacing(-1)}
  }
`
