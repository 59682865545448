import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Image from '@partials/Image'
import * as S from '@styles/components/Component'
import { getComponentClasses } from '@utils/components'
import SectionLabel from '@partials/SectionLabel'

const ImageComponent = ({ component, className = '' }) => {
  const classes = getComponentClasses(component, ['image-component', className])
  const { background_color, background_color_fill, image, label } = component
  return (
    <S.Component
      className={classes}
      bg={background_color}
      bgFill={background_color_fill}
    >
      {label && <SectionLabel label={label} bg={background_color} bgFill={background_color_fill} />}
      <div className="inner">
        <Image source={image} aspectRatio={15 / 8} />
      </div>
      <div className="site-background-color" />
    </S.Component>
  )
}

ImageComponent.propTypes = {
  component: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment ImageComponentFragment on WordPressAcf_image {
    label
    image {
      alt_text
      localFile {
        publicURL
        childImageSharp {
          # TODO: Alter sizing
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    background_color
    background_color_fill
  }
`

export default ImageComponent
