import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

// TODO: add application/ld+json (view source site & https://www.gatsbyjs.org/docs/seo/#generate-rich-snippets-in-search-engines-using-structured-data)
const SEO = ({
  title = null,
  description = null,
  path = null,
  article = false,
  locale = null,
  facebookTitle = null,
  facebookDescription = null,
  twitterTitle = null,
  twitterDescription = null,
  twitterImage = null,
  social: { twitterUser = null, twitterCard = null, image = null } = {},
} = {}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          siteName,
          defaultDescription,
          siteUrl,
          defaultImage,
          defaultLocale,
          type,
          defaultTwitterCard,
        },
      },
    }) => {
      const imageObject =
        image && image.localFile ? image.localFile.childImageSharp.fixed : null
      const twitterImageUrl =
        twitterImage && twitterImage.localFile
          ? twitterImage.localFile.childImageSharp.fixed.src
          : null
      const meta = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${imageObject ? imageObject.src : defaultImage}`,
        locale: locale || defaultLocale,
        url: `${siteUrl}${path || '/'}`,
        twitterCard: twitterCard || defaultTwitterCard,
      }

      return (
        <>
          <Helmet title={meta.title}>
            <meta name="description" content={meta.description} />
            <meta name="image" content={meta.image} />
            {meta.url && <link rel="canonical" href={meta.url} />}
            {meta.url && <meta property="og:url" content={meta.url} />}
            {meta.locale && <meta property="og:locale" content={meta.locale} />}
            {(article || type) && (
              <meta property="og:type" content={article ? 'article' : type} />
            )}
            {(facebookTitle || meta.title) && (
              <meta property="og:title" content={facebookTitle || meta.title} />
            )}
            {(facebookDescription || meta.description) && (
              <meta
                property="og:description"
                content={facebookDescription || meta.description}
              />
            )}
            {siteName && <meta property="og:site_name" content={siteName} />}
            {meta.image && <meta property="og:image" content={meta.image} />}
            {imageObject && (
              <meta property="og:image:height" content={imageObject.height} />
            )}
            {imageObject && (
              <meta property="og:image:width" content={imageObject.width} />
            )}
            <meta name="twitter:card" content={meta.twitterCard} />
            {twitterUser && (
              <meta name="twitter:creator" content={`@${twitterUser}`} />
            )}
            {twitterUser && (
              <meta name="twitter:site" content={`@${twitterUser}`} />
            )}
            {(twitterTitle || meta.title) && (
              <meta name="twitter:title" content={twitterTitle || meta.title} />
            )}
            {(twitterDescription || meta.description) && (
              <meta
                name="twitter:description"
                content={twitterDescription || meta.description}
              />
            )}
            {meta.image && <meta name="twitter:image" content={meta.image} />}
          </Helmet>
        </>
      )
    }}
  />
)

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        siteName
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        defaultLocale: locale
        type
        defaultTwitterCard: twitterCard
      }
    }
  }
`

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
  article: PropTypes.bool,
  locale: PropTypes.string,
  facebookTitle: PropTypes.string,
  facebookDescription: PropTypes.string,
  twitterTitle: PropTypes.string,
  twitterDescription: PropTypes.string,
  twitterImage: PropTypes.object,
  social: PropTypes.shape({
    twitterUser: PropTypes.string,
    twitterCard: PropTypes.string,
    image: PropTypes.object,
  }),
}

export default SEO
