import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Icon from '@partials/Icon'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'
import { Pagination } from 'swiper/js/swiper.esm'
import 'swiper/css/swiper.min.css'
import { getComponentClasses, hasIcon } from '@utils/components'
import * as S from '@styles/components/FeaturedProjects'
import ComponentContent from '@partials/ComponentContent'
import { Link, formatLinkField } from '@partials/Link'
import { PlatformList } from '@partials/PlatformList'
import SmallArrowUpSvg from '@svgs/SmallArrowUpSvg'
import SectionLabel from '@partials/SectionLabel'
import ScrollMagic from 'ScrollMagic'
import 'scrollmagic.gsap'

const CaseStudyLink = ({ path }) => {
  const link = {
    link_type: 'internal',
    link_style: 'text',
    link_to: path,
  }
  return (
    <Link {...formatLinkField(link)} className="case-study-link">
      View Case Study
      <SmallArrowUpSvg className="arrow-up" />
    </Link>
  )
}

export const ProjectSlide = ({ project }) => {
  const {
    post_name,
    post_type,
    acf: {
      featured_images,
      client: { post_title: clientName },
      role,
      platform: { name: platformName, slug: platformSlug },
      has_case_study: hasCaseStudy,
    },
  } = project
  const PlatformSvg = PlatformList[platformSlug]
  const details = [
    {
      label: 'Client',
      value: clientName,
    },
    {
      label: 'Platform',
      value: platformName,
      component: PlatformSvg,
    },
    {
      label: 'Our Role',
      value: role,
    },
  ]
  const path = `/${post_type}/${post_name}`

  return (
    <S.ProjectSlide className="swiper-slide slide">
      {featured_images && <ProjectImages images={featured_images} />}
      <div key="details" className="slide__details">
        {details.map(detail => {
          return (
            <div key={detail.label} className="detail">
              <div className="detail__label">{detail.label}</div>
              <div className="detail__content">
                {detail.component ? <PlatformSvg /> : detail.value}
              </div>
            </div>
          )
        })}
        {hasCaseStudy && (
          <div className="case-study-links">
            <CaseStudyLink path={path} />
          </div>
        )}
      </div>
    </S.ProjectSlide>
  )
}

export const ProjectImages = ({ images, hasCaseStudy, path }) => {
  return (
    <div className="slide__images">
      {images.map(image => {
        if (!image.localFile) {
          return null
        }

        const img = image.localFile.childImageSharp.fluid
        const paddingBottom = `${100 / img.aspectRatio}%`

        return (
          <div key={image.localFile.publicURL} className="slide__image">
            <picture key={img.src} style={{ paddingBottom }}>
              <img
                data-src={img.src}
                data-srcset={img.srcSet}
                sizes={img.sizes}
                className="lazy-slider-img"
                alt={img.alt_text}
              />
              <img src={img.base64} />
            </picture>
          </div>
        )
      })}
    </div>
  )
}

class FeaturedProjectsComponent extends Component {
  static propTypes = {
    component: PropTypes.object.isRequired,
    index: PropTypes.number,
    className: PropTypes.string,
  }

  constructor(props) {
    super(props)
    const swiper = null
    const { component, className, index } = props
    const FeatureProjectInstance = this
    this.hasSlides = component.featured_projects.length > 1
    this.component = component
    this.ref = React.createRef()
    this.classes = getComponentClasses(component, [
      'featured-projects',
      className,
    ])
    this.state = { swiper }
    this.params = {
      modules: [Pagination],
      loop: this.hasSlides,
      slidesPerView: 1,
      speed: 900,
      spaceBetween: 200,
      // TODO refactor custom animation to work with Autoplay be shared among sliders.
      effect: 'customZoom',
      // Lazy
      preloadImages: false,
      lazy: {
        loadOnTransitionStart: true,
      },
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      shouldSwiperUpdate: false,
      virtualTranslate: true,
      waitForTransition: false,
      // End Lazy
      shouldSwiperUpdate: false,
      getSwiper: swiperInstance => {
        // Accessibility - Set slides aria-hidden and tabindex on instantiation
        if (swiperInstance) {
          Array.from(swiperInstance.slides).forEach(slide => {
            this.updateSlideAccessibility(slide)
          })
        }

        this.setState({
          swiper: swiperInstance,
        })
      },
      on: {
        slideChange: () => {
          if (this.state.swiper) {
            // Accessibility - Set slides aria-hidden and tabindex on slide update
            const swiper = { ...this.state.swiper }
            Array.from(swiper.slides).forEach(slide => {
              this.updateSlideAccessibility(slide)
            })

            this.setState({
              swiper,
            })
          }
        },
      },
    }

    // TODO Refactor to a helper function
    if (this.hasSlides) {
      this.params.pagination = {
        el: `.component-${index} .pagination`,
        type: 'bullets',
        bulletClass: 'bullet',
        bulletActiveClass: 'is-active',
        clickable: true,
      }

      this.params.on.init = function() {
        FeatureProjectInstance.setTranslate(this)
      }

      this.params.on.setTranslate = translate => {
        this.setTranslate(this.state.swiper, translate)
      }

      this.params.on.setTransition = transitionSpeed => {
        this.setTransitionSpeed(this.state.swiper, transitionSpeed)
      }
    }
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller()
    this.scenes = []
    this.timelines = {}
    this.targets = {
      swiper: this.ref.current.querySelector('.swiper-container'),
    }

    this.timelines.scroll = new TimelineMax().fromTo(
      this.targets.swiper,
      0.4,
      {
        autoAlpha: 0,
        y: 50,
      },
      {
        autoAlpha: 1,
        y: 0,
        ease: Power2.easeInOut,
      }
    )

    this.scenes = [
      new ScrollMagic.Scene({
        triggerElement: this.targets.swiper,
        triggerHook: 0.75,
        duration: 0,
      })
        .setTween(this.timelines.scroll)
        .addTo(this.controller),
      new ScrollMagic.Scene({
        triggerElement: this.targets.swiper,
        triggerHook: 1,
        duration: 0,
        reverse: false,
      })
        .on('enter', e => {
          // TODO Refactor this on slideChange in Loop mode
          const lazyImages = this.targets.swiper.querySelectorAll(
            '.lazy-slider-img'
          )
          if (lazyImages) {
            lazyImages.forEach(image => {
              image.onload = () => {
                TweenMax.to(image.parentElement.children[1], 0.25, {
                  opacity: 0,
                })
              }
              image.src = image.dataset.src
              image.srcset = image.dataset.srcset
            })
          }
        })
        .addTo(this.controller),
    ]
  }

  componentWillUnmount() {
    Object.keys(this.timelines).forEach(key => {
      this.timelines[key].kill()
    })
    this.scenes.forEach(scene => {
      scene.destroy(true)
    })
    this.scenes = null
    this.timelines = null
  }

  updateSlideAccessibility(slide) {
    // Accessibility - Set slide aria and tabindex, only set tabindex
    // if slide is an a tag.
    const isVisible = slide.classList.contains('swiper-slide-visible')
    const tabIndex = isVisible ? '0' : '-1'
    const ariaHidden = isVisible ? 'false' : 'true'

    if (slide.hasAttribute('href')) {
      slide.setAttribute('tabIndex', tabIndex)
    }

    slide.setAttribute('aria-hidden', ariaHidden)
  }

  getTransitionSpeed() {
    const transitionSpeed = this.currentTransitionSpeed
    this.currentTransitionSpeed = 0
    return transitionSpeed
  }

  setTransitionSpeed(swiper, transitionSpeed) {
    this.currentTransitionSpeed = transitionSpeed
  }

  setTranslate(swiper, wrapperTranslate) {
    if (!swiper) {
      return
    }

    const durationInSeconds = this.getTransitionSpeed() / 1000
    const slides = Object.values(swiper.slides).slice(0, -1)

    slides.map((slide, index) => {
      // Stack slides
      const offset = slide.swiperSlideOffset
      const y = 0
      let x = -offset
      if (!swiper.params.virtualTranslate) {
        x -= swiper.translate
      }

      TweenMax.set(slide, {
        x,
        y,
      })

      // Animation
      const clip = (val, min, max) => Math.max(min, Math.min(val, max))
      const ZOOM_FACTOR = 0.05
      const opacity = Math.max(1 - Math.abs(slide.progress), 0)
      const clippedProgress = clip(slide.progress, -1, 1)
      const scale = 1 - ZOOM_FACTOR * clippedProgress

      TweenMax.to(slide, durationInSeconds, {
        scale,
        opacity,
      })
    })
  }

  render() {
    const {
      background_color,
      background_color_fill,
      featured_projects,
      text_content,
      links,
      label,
    } = this.component

    return (
      <S.FeaturedProjectComponent
        {...this.component}
        className={this.classes}
        maxVisible={1}
        bg={background_color}
        bgFill={background_color_fill}
        isSlider={this.hasSlides}
        ref={this.ref}
      >
        {label && (
          <SectionLabel
            label={label}
            bg={background_color}
            bgFill={background_color_fill}
          />
        )}
        <div className="inner">
          <div className="content">
            <div className="intro">
              <ComponentContent {...text_content} links={links} />
            </div>
            {hasIcon(this.component) && (
              <div className="icon">
                <Icon className="badge" {...this.component} />
              </div>
            )}
          </div>
          <Swiper {...this.params}>
            {featured_projects.map(project => (
              <ProjectSlide key={project.wordpress_id} project={project} />
            ))}
          </Swiper>
        </div>
      </S.FeaturedProjectComponent>
    )
  }
}

export const query = graphql`
  fragment FeaturedProjectsComponentFragment on WordPressAcf_featured_projects {
    text_content {
      ...ComponentContentFragment
    }
    links {
      ...LinkFragment
    }
    label
    featured_projects {
      post_title
      post_name
      post_type
      wordpress_id
      acf {
        featured_images {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              # TODO: Alter sizing
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        client {
          post_title
        }
        platform {
          name
          slug
        }
        role
        deliverables
        has_case_study
      }
    }
    media_type
    dynamic_svg
    svg
    image {
      alt_text
      localFile {
        extension
        publicURL
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    background_color
    background_color_fill
  }
`

export default FeaturedProjectsComponent
