import React from 'react'
import * as S from '@styles/components/HeroSvg'
import { tokens } from '@tokens'

const SpeedLine = ({ index }) => {
  const speedLineSettings = tokens.get('speedLinesSettings')
  const color = index % 2 == 0 ? 'light' : 'dark'
  const inlineStyles = {
    width:
      Math.floor(Math.random() * Math.floor(speedLineSettings.max)) +
      speedLineSettings.min,
    top: `${Math.floor(Math.random() * Math.floor(85)) + 10}%`,
  }
  return (
    <S.SpeedLine
      className={`speed-line speed-line-${index}`}
      color={color}
      style={inlineStyles}
    />
  )
}

const SpeedLines = React.forwardRef((props, ref) => (
  <S.SpeedLines ref={ref} {...props}>
    {[...Array(15)].map((num, index) => {
      return <SpeedLine key={`speed-line-${index}`} index={index} />
    })}
  </S.SpeedLines>
))

export default SpeedLines
