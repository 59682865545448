import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import * as S from '@styles/components/List'
import ComponentContent from '@partials/ComponentContent'
import List from '@partials/List'
import BoltSvg from '@svgs/BoltSvg'
import { getComponentClasses } from '@utils/components'
import Links from '@partials/Links'
import SectionLabel from '@partials/SectionLabel'
import ScrollMagic from 'ScrollMagic'
import 'scrollmagic.gsap'

const ListContent = ({ layout, text_content: content, list, links }) => {
  const hasBolt = layout === 'fancy' || layout === 'columns'
  return (
    <>
      <div className="content">
        <div className="intro">
          {hasBolt && <BoltSvg />}
          <ComponentContent {...content} links={links} />
        </div>
      </div>
      <List className="list" list={list} layout={layout} />
      {layout === 'inline' && <Links links={links} className="mobile-links" />}
    </>
  )
}
class ListBlockComponent extends Component {
  constructor(props) {
    super(props)
    const { component, className } = props
    this.ref = React.createRef()
    this.timelines = {}
    this.props = props
    this.component = component
    this.classes = getComponentClasses(component, [
      'list-block',
      `list-block--${component.layout}`,
      className,
    ])
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller()
    this.scenes = []
    this.timelines = {}
    this.targets = {
      list: this.ref.current.querySelectorAll('.list'),
      listItems: this.ref.current.querySelectorAll('.list__item'),
    }

    if (this.component.layout === 'columns') {
      this.timelines.scroll = new TimelineMax().staggerFromTo(
        this.targets.listItems,
        0.4,
        {
          autoAlpha: 0,
          x: 50,
        },
        {
          autoAlpha: 1,
          x: 0,
          ease: Power2.easeOut,
        },
        0.05
      )

      this.scenes = [
        new ScrollMagic.Scene({
          triggerElement: this.targets.list,
          triggerHook: 0.85,
          duration: 0,
        })
          .setTween(this.timelines.scroll)
          .addTo(this.controller),
      ]
    } else if (this.component.layout === 'fancy') {
      this.targets.listItems.forEach((listItem, index) => {
        this.timelines[index] = new TimelineMax().fromTo(
          listItem.querySelectorAll('.list__item__label,.list__item__content'),
          0.4,
          {
            autoAlpha: 0,
            y: 50,
          },
          {
            autoAlpha: 1,
            y: 0,
            ease: Power2.easeOut,
          }
        )
        this.scenes.push(
          new ScrollMagic.Scene({
            triggerElement: listItem,
            triggerHook: 0.85,
            duration: 0,
          })
            .setTween(this.timelines[index])
            .addTo(this.controller)
        )
      })
    }
  }

  componentWillUnmount() {
    if (this.timelines) {
      Object.keys(this.timelines).forEach(key => {
        this.timelines[key].kill()
      })
      this.timelines = null
    }

    if (this.scenes) {
      this.scenes.forEach(scene => {
        scene.destroy(true)
      })
      this.scenes = null
    }
  }

  render() {
    const {
      background_color,
      background_color_fill,
      label,
      layout,
      component_attrs: { css_id },
    } = this.component
    return (
      <S.ListBlockComponent
        className={this.classes}
        bg={background_color}
        bgFill={background_color_fill}
        ref={this.ref}
        layout={layout}
      >
        <span id={css_id} className="component-anchor"></span>
        {label && (
          <SectionLabel
            label={label}
            bg={background_color}
            bgFill={background_color_fill}
          />
        )}
        <S.ListInner className="inner">
          <ListContent {...this.component} />
        </S.ListInner>
      </S.ListBlockComponent>
    )
  }
}

ListBlockComponent.propTypes = {
  component: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment ListBlockComponentFragment on WordPressAcf_list_block {
    text_content {
      ...ComponentContentFragment
    }
    links {
      ...LinkFragment
    }
    layout
    label
    list {
      is_link
      content
      label
      media_type
      dynamic_svg
      svg
      image {
        alt_text
        localFile {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      link {
        ...LinkFragment
      }
    }
    background_color
    background_color_fill
    component_attrs {
      css_id
    }
  }
`

export default ListBlockComponent
