import styled from 'styled-components'
import { tokens, media, spacing } from '@tokens'

export const ArrowDown = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${spacing(3)};
  width: ${spacing(2)};

  ${media.greaterThan('s')`
    margin-bottom: ${spacing(4)};
    width: ${spacing(3)};
  `}
`
