import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media } from '@tokens'
import { darken, desaturate } from 'polished'
import { Link } from '@styles/objects/Link'
import { ITCBold } from '@styles/Typography'

export const NavBar = styled.ul`
  ${ITCBold};
  display: none;

  ${media.greaterThan('m')`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    margin: 0 ${spacing(4)} 0 0;

    ${media.greaterThan('m')`
      margin-right: ${spacing(6)};
    `}
  `}
`

// TODO: Add indicator for  '.has-children'
export const NavBarItem = styled.li`
  display: block;
  margin-right: ${spacing(4)};
  line-height: 1.5;
  position: relative;
  text-overflow: ellipsis;

  ${media.greaterThan('l')`
    margin-right: ${spacing(6)};
  `}

  &:last-child {
    margin-right: 0;
  }

  .navbar-link {
    ${ITCBold};
    ${Link};
    display: block;
    padding: ${spacing(0.75)} 0;
    font-size: 1rem;
    color: ${tokens.color('text')};
    letter-spacing: ${tokens.get('type.letterSpacing.large')};
    text-transform: uppercase;


     ${styledMap('invertColors', {
      'true': css`
        color: ${tokens.brand('light')};

        &::after {
          background: ${tokens.brand('light')};

          .sticky-header & {
            background: ${tokens.brand('dark')};
          }
        }
      `,
      'default': '',
      })}
  }

  .navbar-link--button {
    font-size: 1rem;
    margin-bottom: 0;
  }
`

export const SubNav = styled.ul`
  position: absolute;
  top: 85%;
  left: 50%;
  margin: 0;
  list-style: none;
  margin-top: 0.35rem;
  padding-top: 0.75rem;
  transform: translate(-50%, 0);
  transition: transform 0.4s ease, opacity 0.2s ease, visibility 0.5s ease;
  pointer-events: ${styledMap`
    expanded: auto;
    default: none;
  `};
  z-index: 1000;

  /* Nested subnav */
  ${NavBarItem} ${NavBarItem} & {
    top: -${spacing(3)};
    left: auto;
    right: 100%;
    margin-top: 0;
    transform: translate(0%, 0);
  }

  ${NavBarItem} {
    background-color: ${tokens.brand('lightAlt')};
    border-radius: 0.25rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.12);
    position: relative;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    line-height: 1.2;
    margin: 0 0 ${spacing()};
    padding: 0;
    transition: box-shadow 0.3s ease-out, background-color 0.3s ease-out;

    &:hover {
      background-color: ${desaturate(
        0.4,
        darken(0.02, tokens.brand('lightAlt'))
      )};
      box-shadow: 0 0.15rem 0.45rem rgba(0,0,0,0.2);

      a {
        color: ${tokens.color('link')};
      }
    }

    > a {
      color: ${tokens.brand('darkAlt2')};
      font-size: 0.875rem;
      padding: ${spacing(2)} ${spacing(4)};

      &::after {
        display: none;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`
