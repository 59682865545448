import styled from 'styled-components'
import { tokens } from '@tokens'

export const ComputerDialSvg = styled.svg`
  display: block;

  .dark-color {
    fill: ${tokens.brand('dark')};
  }
`
