import React from 'react'
import Header from '@components/Header'
import * as S from '@styles/components/Page'
import FlyoutContainer from '@components/FlyoutContainer'

const Container = ({ children, page, className = '', heroType, ...props }) => {
  const fixedHeader = true
  const classes = `container ${className}`

  return (
    <FlyoutContainer>
      <div className={classes}>
        <Header
          fixed={fixedHeader}
          designations={page.designations}
          heroType={heroType}
        />
        <S.Content>{children}</S.Content>
      </div>
    </FlyoutContainer>
  )
}

export default Container
