import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { LargeTypeSizes } from '@styles/Typography'
import { tokens, spacing, box, media } from '@tokens'

const halfBackground = `
  background: transparent;

  .content {
    color: ${tokens.brand('dark')};
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    z-index: ${tokens.z('base')};
    display: block;
    width: 100%;
    background: ${tokens.brand('dark')};
  }
`

export const bgOverlap = `
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: -0.5rem;
    left: 0;
    height: 1rem;
    z-index: ${tokens.z('base')};
    background: ${tokens.brand('dark')}
  }
`

export const Component = styled.div`
  position: relative;
  ${box()};

  background: ${styledMap('bg', {
    dark: tokens.brand('dark'),
    light: tokens.brand('lightAlt'),
    default: 'transparent',
  })};

  color: ${styledMap('bg', {
    dark: tokens.brand('light'),
    default: tokens.color('text'),
  })};

  ${styledMap('bgFill', {
    half: halfBackground,
    default: '',
  })};

  ${styledMap('bg', {
    dark: css`
      .background-color-dark + &.background-color-dark {
        ${bgOverlap}
      }
    `,
  })};

  > .inner {
    margin-left: auto;
    margin-right: auto;

    ${styledMap('bgFill', {
      half: css`
        position: relative;
        z-index: ${tokens.z('content')};
      `,
      default: '',
    })};

    ${media.greaterThan('s')`
      max-width: ${tokens.get('maxWidth.narrow')};
    `}

    ${media.greaterThan('m')`
      max-width: ${tokens.get('maxWidth.base')};
    `}

    ${media.greaterThan('xl')`
      max-width: ${tokens.get('maxWidth.wide')};
    `}

    > .content {
      max-width: ${tokens.get('maxWidth.narrow')};

      ${styledMap('contentAlignment', {
        left: css`
          margin-left: 0;
          margin-right: auto;
        `,
        right: css`
          margin-left: 0;
          margin-right: auto;
        `,
        default: css`
          margin-left: auto;
          margin-right: auto;
        `,
      })};
    }
  }

  .intro {
    margin-bottom: ${spacing(6)};

    ${media.greaterThan('s')`
      margin-bottom: ${spacing(7)};
    `}

    ${media.greaterThan('m')`
      margin-bottom: ${spacing(8)};
    `}

    ${media.greaterThan('l')`
      margin-bottom: ${spacing(10)};
    `}

    p:first-child {
      ${LargeTypeSizes}
    }
  }

  /*
    There is a tiny gap between the section label &
    the heading. The cause is the space between the text
    and the line-height Targeting the heading to make a
    relative measurement.
  */
  ${media.greaterThan('m')`
    .heading:first-child {
      margin-top: -0.2em;
    }
  `}

  .component-anchor {
    position: absolute;
    top:0;
    left: 0;
    margin-top: ${`-${parseInt(tokens.get('component.header.height.s')) * 0.6}rem `};

    ${media.greaterThan('s')`
      margin-top: ${`-${parseInt(tokens.get('component.header.height.m')) * 0.7}rem `};
    `}

    ${media.greaterThan('m')`
      margin-top: ${`-${parseInt(tokens.get('component.header.height.l')) * 0.7}rem `};
    `}
  }
`

export default Component
