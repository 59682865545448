import React, { Component } from 'react'

class SmallArrowUpSvg extends Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    return (
      <svg
        className={this.className}
        ref={this.ref}
        {...this.props}
        viewBox="0 0 41 25"
      >
        <path
          fillRule="evenodd"
          d="M24,25c6.1,0,11.1-4.9,11.1-10.9V9.4l2.5,3.6c0.3,0.5,0.9,0.6,1.4,0.3l1.7-1.1c0.5-0.3,0.6-0.9,0.3-1.4	L33.9,0.7c-0.3-0.5-0.9-0.6-1.4-0.3c-0.1,0.1-0.2,0.2-0.3,0.3l-6.9,10.1c-0.3,0.5-0.2,1.1,0.3,1.4l1.6,1.1c0.5,0.3,1.1,0.2,1.4-0.3	c0,0,0,0,0,0l2.5-3.6v4.7c0,3.8-3.2,6.9-7.1,6.9H1.7c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1H24z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default SmallArrowUpSvg
