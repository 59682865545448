import styled from 'styled-components'
import { tokens, spacing, media } from '@tokens'
import styledMap from 'styled-map'
import Component from '@styles/components/Component'

export const Tiles = styled(Component)`
  overflow:hidden;

  > .inner {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    ${media.greaterThan('m')`
      flex-flow: row;
      justify-content: space-between;
    `}

    ${media.lessThan('m')`
      max-width: ${tokens.get('maxWidth.tiny')};
      margin-left: auto;
      margin-right: auto;
    `}

    > .content {
      display: flex;
      flex: 0 1 calc(50% - ${spacing(3)});
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;

      ${media.lessThan('s')`
        max-width: 23.75rem;
      `}

      ${media.greaterThan('m')`
        flex: 0 0 26rem;
        margin: 0 0 0 auto;
        padding: ${styledMap('alignment', {
          left: `0 ${spacing(6)} 0 0`,
          default: `0 0 0 ${spacing(6)}`,
        })};

      `}

      ${media.greaterThan('xl')`
        flex: 0 0 30rem;
      `}
    }
  }

  .image {
    position: relative;
    line-height: 0;
    order: 2;
    margin: ${spacing(4)} auto 0;
    max-width: 23.75rem;
    width: 100%;

    ${media.greaterThan('s')`
      max-width: ${tokens.get('maxWidth.tiny')};
    `}

    ${media.greaterThan('m')`
      flex: 0 1 50%;
      height: auto;
      order: ${styledMap('alignment', {
        left: 2,
        default: 0,
      })};
      margin: 0;
      max-width: none;

      img {
        object-fit: contain;
        object-position: center;
        height: 100%;
        width: 100%;
      }
    `}
  }

  .heading {
    ${media.lessThan('s')`
      max-width: 15.5rem;
    `}
  }
`
