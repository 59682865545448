import styled from 'styled-components'
import { tokens, media, spacing } from '@tokens'
import styledMap from 'styled-map'
import Component from '@styles/components/Component'
import { LargeTypeSizes } from '@styles/Typography'
import { PageContentRTE } from '@styles/components/Page'

export const Text = styled(Component)`
  ${PageContentRTE}

  > .inner {
    > .content {
      margin: ${styledMap('contentAlignment', {
        center: '0 auto',
        right: '0 0 0 auto',
        default: 0,
      })};

      max-width: ${styledMap('contentAlignment', {
        center: tokens.get('maxWidth.mid'),
        default: tokens.get('maxWidth.slim'),
      })};

      text-align: ${styledMap('textAlignment', {
        center: 'center',
        right: 'right',
        default: 'left',
      })};

      p:first-child {
        ${LargeTypeSizes}
      }
    }
  }

  .bolt {
    height: ${spacing(5)};
    margin-bottom: ${spacing(4)};

    ${media.lessThan('s')`
      width: 10.5rem;
    `}
  }
`
