import styled from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media } from '@tokens'

export const Social = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  margin: 0;

  li {
    list-style: none;
    margin-left: ${spacing(2)};

    &:first-child {
      margin-left: 0;
    }
  }

  a {
    transform: scale(1);
    transition: transform ${tokens.get('transition.snappy')};

    &:hover {
      transform: scale(1.2);
    }
  }

  a,
  svg {
    display: block;
  }

  svg {
    height: ${spacing(3)};
    width: ${spacing(3)};
    fill: ${styledMap('invertColors', {
      true: tokens.brand('light'),
      default: tokens.brand('dark'),
    })};
  }
`
