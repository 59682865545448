import React, { useContext, useEffect, useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { SnipcartContext } from 'gatsby-plugin-snipcart-advanced/context'
import styledMap from 'styled-map'
import { FiShoppingBag } from 'react-icons/fi'
import { tokens, media, spacing } from '@tokens'

export const $SnipcartCartButton = styled.div`
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: ${spacing(3)};

  svg {
    height: 1.375rem;
    width: 1.375rem;

    stroke: ${styledMap('invertColors', {
      true: tokens.brand('light'),
      default: tokens.brand('dark'),
    })};
  }
`

export const $SnipcartCartButtonInner = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  div {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: ${spacing(2)};
    height: ${spacing(2)};
    background: ${tokens.brand('dark')};
    color: ${tokens.color('white')};
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-size: 0.5625rem;
    border-radius: 100%;
    margin-top: ${spacing(-1)};
    margin-right: ${spacing(-1)};

    span {
      margin-top: -0.0625rem;
    }
  }
`

export default function SnipcartCartButton({ className, invertColors }) {
  const [cartCount, setCartCount] = useState(0)
  const [SnipcartSDK, setSnipcartSDK] = useState(null)
  const { state } = useContext(SnipcartContext)

  const handleClick = () => {
    if (!SnipcartSDK) return
    SnipcartSDK.api.theme.cart.open()
  }

  useEffect(() => {
    if (!state.ready) return
    const { Snipcart } = window
    let currentValue = Snipcart.store.getState().cart.items.count
    setCartCount(currentValue)
    setSnipcartSDK(Snipcart)

    const unsubscribe = Snipcart.store.subscribe(() => {
      const previousValue = currentValue
      currentValue = Snipcart.store.getState().cart.items.count
      if (previousValue !== currentValue) {
        setCartCount(currentValue)
      }
    })

    Snipcart.api.theme.customization.registerPaymentFormCustomization({
      input: {
        backgroundColor: tokens.brand('dark'),
        color: 'white',
        border: `1px solid ${tokens.brand('light')}`,
        fontSize: '14px',
        placeholder: {
          color: 'white',
        },
      },
      label: {
        color: 'white',
        fontSize: '12px',
      },
    })

    return () => {
      unsubscribe()
    }
  }, [state.ready])

  return (
    <$SnipcartCartButton
      onClick={handleClick}
      className={className}
      invertColors={invertColors}
    >
      <$SnipcartCartButtonInner>
        {cartCount > 0 && (
          <div>
            <span>{cartCount}</span>
          </div>
        )}
        <FiShoppingBag className="shopping-bag" />
      </$SnipcartCartButtonInner>
    </$SnipcartCartButton>
  )
}
