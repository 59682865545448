import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, box, media } from '@tokens'
export { Label } from '@styles/Typography'

export const Section = styled.div`
  display: none;

  ${media.greaterThan('l')`
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: ${tokens.z('aboveContent')};
  `}

  .section__label {
    ${box({
      spacing: 'top',
    })}
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    margin-left: ${spacing(1)};
    writing-mode: vertical-rl;
    white-space: nowrap;

    color: ${styledMap('bg', {
      dark: tokens.brand('light'),
      default: tokens.brand('dark'),
    })};

    ${styledMap('bgFill', {
      half: css`
      color: ${tokens.brand('dark')}
      `,
      default: '',
    })};

    ${media.greaterThan('m')`
      margin-left: ${spacing(2)};
    `}

    ${media.greaterThan('xl')`
      margin-left: ${spacing(6)};
    `}

    &::before {
      content: '';
      display: block;
      width: ${spacing(0.5)};
      height: ${spacing(12)};
      margin-bottom: ${spacing(2)};
      background-color: ${styledMap('bg', {
        dark: tokens.brand('light'),
        default: tokens.brand('dark'),
      })};

      ${styledMap('bgFill', {
        half: css`
        background-color: ${tokens.brand('dark')}
        `,
        default: '',
      })};
    }
  }
`
