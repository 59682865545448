import React from 'react'
import PropTypes from 'prop-types'

// Load components
import AccordionBlockComponent from '@page-components/AccordionBlockComponent'
import BannerComponent from '@page-components/BannerComponent'
import CardsComponent from '@page-components/CardsComponent'
import FormBlockComponent from '@page-components/FormBlockComponent'
// import GalleryComponent from '@page-components/GalleryComponent'
import FeaturedProjectsComponent from '@page-components/FeaturedProjectsComponent'
import ImageComponent from '@page-components/ImageComponent'
import ImageBlocksComponent from '@page-components/ImageBlocksComponent'
import ListBlockComponent from '@page-components/ListBlockComponent'
import LogoGridComponent from '@page-components/LogoGridComponent'
import TilesComponent from '@page-components/TilesComponent'
import SliderComponent from '@page-components/SliderComponent'
import TeamComponent from '@page-components/TeamComponent'
import TestimonialsComponent from '@page-components/TestimonialsComponent'
import TextComponent from '@page-components/TextComponent'
import TextWithImagesComponent from '@page-components/TextWithImagesComponent'

// Map components
const componentList = {
  WordPressAcf_accordion_block: AccordionBlockComponent,
  WordPressAcf_banner: BannerComponent,
  WordPressAcf_cards: CardsComponent,
  WordPressAcf_featured_projects: FeaturedProjectsComponent,
  WordPressAcf_form_block: FormBlockComponent,
  // WordPressAcf_gallery: GalleryComponent,
  WordPressAcf_image_blocks: ImageBlocksComponent,
  WordPressAcf_image: ImageComponent,
  WordPressAcf_list_block: ListBlockComponent,
  WordPressAcf_logo_grid: LogoGridComponent,
  WordPressAcf_tiles: TilesComponent,
  WordPressAcf_slider: SliderComponent,
  WordPressAcf_team: TeamComponent,
  WordPressAcf_testimonials: TestimonialsComponent,
  WordPressAcf_text: TextComponent,
  WordPressAcf_text_with_images: TextWithImagesComponent,
}

/**
 * Take component data and output using the correct component template
 */
export const PageComponents = ({ components }) =>
  components &&
  components.map((component, index) => {
    const PageComponent = componentList[component.__typename]

    if (PageComponent === undefined) {
      // TEMP: Remove once complete
      return (
        <div key={index}>No Component Template for {component.__typename}</div>
      )
    }

    return (
      <PageComponent
        component={component}
        key={index}
        index={index}
        className={`component component-${index}`}
      />
    )
  })

PageComponents.propTypes = {
  components: PropTypes.array,
}

export default PageComponents

export const query = graphql`
  fragment ComponentAttrsFragment on wordpress__wp_component_attrs {
    css_id
  }
`
