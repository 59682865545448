import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as S from '@styles/components/Accordion'
import AccordionItem from '@partials/AccordionItem'

class Accordion extends Component {
  static propTypes = {
    accordion: PropTypes.array,
  }

  constructor(props) {
    super(props)

    const activeIndex = null

    this.state = { activeIndex }
  }

  // Set active accordion item on click
  onClick = index => {
    // Accessiblity - if an accordion item is closed without opening another
    // accordion item, set state index to null. This stops users from being
    // able to tab into hidden content after an accordion item is closed.
    const activeIndex = index !== this.state.activeIndex ? index : null
    this.setState({ activeIndex })
  }

  render() {
    const {
      onClick,
      props: { accordion },
      state: { activeIndex },
    } = this

    return (
      <S.Accordion>
        {accordion.map((accordionItem, index) => (
          <AccordionItem
            {...accordionItem}
            onClick={onClick}
            key={index}
            index={index}
            isActive={index === activeIndex}
          />
        ))}
      </S.Accordion>
    )
  }
}

export default Accordion
