import React, { Component } from 'react'

export class FieldWrapper extends Component {
  constructor(props) {
    super(props)

    this.ref = React.createRef()
    this.initiallyVisible = props.isVisible
    this.visibilityTimeline = new TimelineLite({
      paused: true,
    })

    this.state = { visible: this.initiallyVisible }
  }

  componentDidMount() {
    if (this.initiallyVisible) {
      this.visibilityTimeline
        .to(
          this.ref.current,
          0.5,
          {
            className: '+=is-closed',
          },
          0
        )
        .to(
          this.ref.current,
          0.25,
          {
            autoAlpha: 0,
            ease: Power2.easeOut,
          },
          0
        )
    } else {
      this.visibilityTimeline
        .from(
          this.ref.current,
          0.5,
          {
            className: '+=is-closed',
          },
          0
        )
        .from(
          this.ref.current,
          0.25,
          {
            autoAlpha: 0,
            ease: Power2.easeIn,
          },
          0.25
        )
    }
  }

  componentDidUpdate() {
    const { isVisible } = this.props

    if (isVisible && !this.state.visible) {
      if (this.initiallyVisible) {
        this.visibilityTimeline.reverse()
      } else {
        this.visibilityTimeline.play()
      }
      this.setState({ visible: true })
    } else if (!isVisible && this.state.visible) {
      if (this.initiallyVisible) {
        this.visibilityTimeline.play()
      } else {
        this.visibilityTimeline.reverse()
      }
      this.setState({ visible: false })
    }
  }

  render() {
    const { key, type, children, className } = this.props

    return (
      <div key={key} className={className} type={type} ref={this.ref}>
        {children}
      </div>
    )
  }
}

export default FieldWrapper
