import styled from 'styled-components'
import { tokens, spacing, box, media } from '@tokens'
import { ListInlineFlex } from '@styles/objects/List'
import { Link } from '@styles/objects/Link'

export const Filter = styled.nav`
  ${box({
    spacing: 'top',
    multiplier: -1,
    type: 'margin',
  })}
  ${box({
    spacing: 'top',
    multiplier: 0.25,
  })}
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  font-size: 1.25rem;
  line-height: 1.5;

  ${media.lessThan('s')`
    margin-left: -${spacing(3)};
    padding: 0 ${spacing(3)};
    width: calc(100% + ${spacing(6)});
    overflow-x: scroll;
  `}

  .options {
    ${ListInlineFlex}
  }

  .option {
    ${Link};
    display: block;
    color: ${tokens.color('text')};
    padding: 0 0 ${spacing(0.75)};
    margin-right: ${spacing(4)};

    > div {
      margin-bottom: 0;
    }

    ${media.greaterThan('m')`
      margin-right: ${spacing(6)};
    `}
  }
`
