import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import * as S from '@styles/components/Accordion'
import ComponentContent from '@partials/ComponentContent'
import Accordion from '@partials/Accordion'
import { getComponentClasses } from '@utils/components'
import SectionLabel from '@partials/SectionLabel'

const AccordionBlockComponent = ({ component, className = '' }) => {
  const classes = getComponentClasses(component, ['accordion', className])
  const {
    background_color,
    background_color_fill,
    text_content,
    links,
    label,
    accordion,
  } = component
  return (
    <S.AccordionBlockComponent
      className={classes}
      bg={background_color}
      bgFill={background_color_fill}
    >
      {label && <SectionLabel label={label} bg={background_color} bgFill={background_color_fill} />}
      <div className="inner">
        <div className="content">
          <div className="intro">
            <ComponentContent {...text_content} links={links} />
          </div>
        </div>

        <Accordion accordion={accordion} />
      </div>
    </S.AccordionBlockComponent>
  )
}

AccordionBlockComponent.propTypes = {
  component: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment AccordionBlockComponentFragment on WordPressAcf_accordion_block {
    text_content {
      ...ComponentContentFragment
    }
    label
    accordion {
      heading
      content
      links {
        ...LinkFragment
      }
    }
    background_color
    background_color_fill
  }
`

export default AccordionBlockComponent
