import React from 'react'
import useFooter from '@hooks/use-footer'
import FooterSvg from '@svgs/FooterSvg'
import Social from '@components/Social'
import Link from '@partials/Link'
import TextComponent from '@components/page/TextComponent'
import * as S from '@styles/components/Footer'

// Take the site nav and build an object to fit ML Menu
const Footer = () => {
  const component = {
    background_color: 'dark',
    background_color_fill: 'full',
    text_alignment: 'center',
    ...useFooter(),
  }
  const year = new Date().getFullYear()

  return (
    <S.Footer>
      <TextComponent component={component} arrow />
      <div className="footer-container">
        <FooterSvg />
        <div className="footer-container__inner">
          <div className="footer-nav">
            <Link className="footer-nav-link" to="/store">
              Store
            </Link>
            <Link className="footer-nav-link" to="/careers">
              Careers
            </Link>
            <Link className="footer-nav-link" to="/privacy-policy">
              Privacy Policy
            </Link>
            <Link className="footer-nav-link" to="/contact">
              Contact
            </Link>
          </div>
          <span className="company">
            &copy; Gearbox.&nbsp;
            {year}, All&nbsp;rights&nbsp;reserved.
          </span>
          <Social className="footer__social" invertColors={true} />
        </div>
      </div>
    </S.Footer>
  )
}

export default Footer
