import React, { Component } from 'react'
import ScrollMagic from 'ScrollMagic'
import 'scrollmagic.gsap'

class CreditCardCartSvg extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.timelines = {}
    this.props = props
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller()
    this.targets = {
      arrowDown: this.ref.current.querySelector('.arrowDown'),
      card: this.ref.current.querySelector('.card'),
      circle: this.ref.current.querySelector('.circle'),
      cart: this.ref.current.querySelector('.cart'),
      arrowUp: this.ref.current.querySelector('.arrowUp'),
    }

    this.timeline = new TimelineMax()
      .fromTo(
        this.targets.circle,
        0.3,
        {
          transformOrigin: '50% 50%',
          y: -10,
          scale: 0,
        },
        {
          y: 0,
          scale: 1,
        }
      )
      .fromTo(
        this.targets.cart,
        0.3,
        {
          transformOrigin: '50% 50%',
          x: 20,
          scale: 0,
        },
        {
          x: 0,
          scale: 1,
        },
        '-=0.1'
      )
      .fromTo(
        this.targets.arrowUp,
        0.3,
        {
          transformOrigin: '50% 50%',
          y: 5,
          x: 5,
          scale: 0,
        },
        {
          y: 0,
          x: 0,
          scale: 1,
        },
        '-=0.1'
      )
      .fromTo(
        this.targets.card,
        0.3,
        {
          transformOrigin: '50% 50%',
          y: -5,
          x: -5,
          scale: 0,
        },
        {
          y: 0,
          x: 0,
          scale: 1,
        },
        '-=0.1'
      )
      .fromTo(
        this.targets.arrowDown,
        0.3,
        {
          transformOrigin: '50% 50%',
          x: -5,
          y: -20,
          scale: 0,
        },
        {
          y: 0,
          x: 0,
          scale: 1,
        },
        '-=0.1'
      )

    this.scenes = [
      new ScrollMagic.Scene({
        triggerElement: this.ref.current,
        triggerHook: 0.85,
        duration: 0,
      })
        .setTween(this.timeline)
        .addTo(this.controller),
    ]
  }

  componentWillUnmount() {
    this.timeline.kill()
    this.scenes.forEach(scene => {
      scene.destroy(true)
    })
    this.scenes = null
  }

  render() {
    return (
      <svg
        className={this.className}
        ref={this.ref}
        {...this.props}
        viewBox="0 0 118 114"
        xmlns="http://www.w3.org/2000/svg"
        style={{ overflow: 'visible' }}
      >
        <g className="cart">
          <path d="M66.6 104.2c-.8 0-1.4.5-1.7 1.2-.3.7-.1 1.5.4 2s1.3.7 2 .4c.7-.3 1.1-1 1.1-1.7.1-1.1-.8-1.9-1.8-1.9z" />
          <path d="M87.3 104.2c-1 0-1.9.9-1.8 1.9 0 1 .9 1.9 1.9 1.8 1 0 1.9-.9 1.8-1.9-.1-1-.9-1.8-1.9-1.8z" />
          <path d="M106.3 60.3L94 93.2c-.3.7-1 1.2-1.7 1.2h-35c-1 0-1.9.8-1.9 1.9 0 1 .8 1.9 1.9 1.9h7.5v2.8c0 .7-.4 1.3-1 1.7-2.3 1.3-3.3 4.1-2.5 6.7 1.1 2.9 4.3 4.4 7.2 3.4 2.1-.7 3.5-2.6 3.7-4.7.2-2.2-.8-4.3-2.7-5.4-.6-.3-1-1-1-1.7v-2.8h16.9v2.8c0 .7-.4 1.3-1 1.7-2.3 1.3-3.3 4.1-2.4 6.7 1.1 2.9 4.3 4.4 7.2 3.4 1.4-.5 2.6-1.5 3.2-2.8.6-1.3.7-2.9.2-4.3-.4-1.2-1.3-2.3-2.4-2.9-.6-.3-1-1-1-1.7v-2.8h5.6c.8 0 1.5-.5 1.8-1.2L108.9 64c.3-.7 1-1.2 1.8-1.2h4.9c1 0 1.9-.8 1.9-1.9 0-1-.8-1.9-1.9-1.9H108c-.8.1-1.5.6-1.7 1.3z" />
          <path d="M99 62.9c-.3-.5-.9-.9-1.6-.9H50.2c-.6 0-1.2.3-1.6.8-.4.5-.5 1.2-.3 1.8l8.2 24.2c.3.8 1 1.3 1.8 1.3h30.4c.8 0 1.6-.5 1.8-1.3l8.6-24.2c.4-.5.3-1.2-.1-1.7zM80.5 83.4H67.4c-.8.1-1.5-.3-1.9-1-.4-.7-.4-1.5 0-2.1.4-.7 1.1-1 1.9-1h13.1c1.1.1 1.9 1 1.9 2.1s-.8 1.9-1.9 2zM84.1 73H63.7c-.8.1-1.5-.3-1.9-1s-.4-1.5 0-2.1 1.1-1 1.9-1h20.5c.8-.1 1.5.3 1.9 1 .4.7.4 1.5 0 2.1-.5.7-1.2 1.1-2 1z" />
        </g>
        <path
          className="circle"
          d="M75.8 38.3c-3.8 0-7.3 2.3-8.8 5.9s-.7 7.6 2.1 10.4 6.8 3.5 10.4 2.1c3.5-1.5 5.9-4.9 5.9-8.8-.1-5.4-4.3-9.6-9.6-9.6zm0 15.5c-2.4 0-4.6-1.5-5.5-3.7-.9-2.2-.4-4.8 1.3-6.5s4.3-2.2 6.5-1.3c2.2.9 3.7 3.1 3.7 5.5 0 3.3-2.6 6-6 6z"
        />
        <path
          className="arrowDown"
          d="M74.5 27.9l-1.1-1.1c-.4-.4-.8-.6-1.3-.6s-1 .2-1.3.6c-.7.7-.7 1.9 0 2.6l4.3 4.4c.4.4.8.6 1.3.6.2 0 .5 0 .7-.2.2-.1.4-.2.6-.4l4.3-4.4c.7-.7.7-1.9 0-2.6-.4-.4-.8-.6-1.3-.6s-1 .2-1.3.6l-1.1 1.1v-8.7c0-2.1-1.7-3.8-3.8-3.8H63.2c-1 0-1.9.8-1.9 1.9 0 1 .9 1.9 1.9 1.9h10.1c.3 0 .6.1.8.3.2.2.3.5.3.8v7.6z"
        />
        <path
          className="arrowUp"
          d="M13.9 55.2l1.1 1.1c.4.4.8.6 1.3.6s1-.2 1.3-.6c.7-.7.7-1.9 0-2.7l-4.3-4.4c-.3-.2-.8-.4-1.3-.4-.2 0-.5 0-.7.1-.2.1-.4.2-.6.4l-4.4 4.4c-.7.7-.7 1.9 0 2.7.4.4.8.6 1.3.6s1-.2 1.3-.6l1.1-1.1v23.1c0 2.1 1.7 3.8 3.8 3.8h26.5c1 0 1.9-.8 1.9-1.9 0-1-.8-1.9-1.9-1.9H15c-.6 0-1.2-.5-1.2-1.2l.1-22z"
        />
        <g className="card">
          <path d="M47.2 0H5.8C2.7 0 .1 2.5 0 5.7v26.5c0 3.2 2.7 5.7 5.8 5.7h41.3c3.2 0 5.8-2.5 5.8-5.7V5.7c0-3.2-2.6-5.7-5.7-5.7zm1.4 31.8c0 .5-.2 1-.6 1.4-.4.4-.9.6-1.4.6H6.4c-1.1 0-2-.9-2-2V6.1c0-1.1.9-2 2-2h40.2c1.1 0 2 .9 2 2v25.7z" />
          <path d="M31.1 2.9h11.3v31.9H31.1z" />
          <circle cx="12.5" cy="13.6" r="2.8" />
          <circle cx="12.5" cy="24.2" r="2.8" />
        </g>
      </svg>
    )
  }
}

export default CreditCardCartSvg
