import React, { Component } from 'react'
import Link from '@partials/Link'
import { FlyoutContext } from '@context/flyout-context'
import * as S from '@styles/components/NavDrawerTrigger'

class NavDrawerTrigger extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  render() {
    const isOpen = !!(this.context.open && this.context.sid)
    const { invertColors } = this.props
    return (
      <FlyoutContext.Consumer>
        {() => (
          <Link
            type="flyout"
            postType="menu"
            sid="menu"
            className="nav-drawer-trigger"
          >
            <S.NavDrawerTrigger isOpen={isOpen} invertColors={invertColors}>
              <S.Hamburger
                className="hamburger"
                isOpen={isOpen}
                invertColors={invertColors}
              >
                <span key={'ham-box'} className="hamburger__box">
                  <span className="hamburger__box-inner" />
                </span>
                <span key={'ham-text'} className="hamburger__text">
                  <span className="hamburger__text-viewport">
                    <span key={'ham-text-open'}>Menu</span>
                    <span key={'ham-text-close'}>Close</span>
                  </span>
                </span>
              </S.Hamburger>
            </S.NavDrawerTrigger>
          </Link>
        )}
      </FlyoutContext.Consumer>
    )
  }
}

NavDrawerTrigger.contextType = FlyoutContext

export default NavDrawerTrigger
